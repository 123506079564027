"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _storage = require("@/utils/storage.js");
var _util = require("@/utils/util");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'layoutBreadcrumb',
  data: function data() {
    return {
      breadcrumbList: [],
      routeSplit: [],
      routeSplitFirst: '',
      routeSplitIndex: 1
    };
  },
  computed: {
    breadCrumbList: function breadCrumbList() {
      var menuList = this.$store.state.user.menuList;
      var openMenus = (0, _util.getMenuopen)(this.$route, menuList);
      var allMenuList = (0, _util.R)(menuList, []);
      var selectMenu = [];
      if (allMenuList.length > 0) {
        openMenus.forEach(function (i) {
          allMenuList.forEach(function (a) {
            if (i === a.path) {
              selectMenu.push(a);
            }
          });
        });
      }
      return selectMenu;
    },
    crumbPast: function crumbPast() {
      var that = this;
      var menuList = that.$store.state.user.menuList;
      var allMenuList = (0, _util.R)(menuList, []);
      var selectMenu = [];
      if (allMenuList.length > 0) {
        allMenuList.forEach(function (a) {
          if (that.$route.path === a.path) {
            selectMenu.push(a);
          }
        });
      }
      return selectMenu;
    },
    // 获取布局配置信息
    getThemeConfig: function getThemeConfig() {
      return this.$store.state.themeConfig.themeConfig;
    },
    // 动态设置经典、横向布局不显示
    isShowBreadcrumb: function isShowBreadcrumb() {
      var _this$$store$state$th = this.$store.state.themeConfig.themeConfig,
        layout = _this$$store$state$th.layout,
        isBreadcrumb = _this$$store$state$th.isBreadcrumb;
      if (layout === 'transverse' || layout === 'classic') {
        return 'none';
      } else {
        return isBreadcrumb ? '' : 'none';
      }
    },
    isShowcrumb: function isShowcrumb() {
      var layout = this.$store.state.themeConfig.themeConfig.layout;
      if (layout === 'transverse' || layout === 'classic') {
        return false;
      } else {
        return true;
      }
    },
    collapseShow: function collapseShow() {
      return ['defaults', 'columns'].includes(this.$store.state.themeConfig.themeConfig.layout);
    }
  },
  mounted: function mounted() {
    this.initRouteSplit(this.$route.path);
  },
  methods: {
    // breadcrumb 当前项点击时
    onBreadcrumbClick: function onBreadcrumbClick(v) {
      var redirect = v.redirect,
        path = v.path;
      this.$router.push(path);
    },
    // breadcrumb icon 点击菜单展开与收起
    onThemeConfigChange: function onThemeConfigChange() {
      if (this.$store.state.themeConfig.themeConfig.layout == 'columns' && !this.$store.state.user.childMenuList.length && this.$store.state.themeConfig.themeConfig.isCollapse) {
        return;
      }
      this.$store.state.themeConfig.themeConfig.isCollapse = !this.$store.state.themeConfig.themeConfig.isCollapse;
      this.setLocalThemeConfig();
    },
    // 存储布局配置
    setLocalThemeConfig: function setLocalThemeConfig() {
      _storage.Local.remove('themeConfigPrev');
      _storage.Local.set('themeConfigPrev', this.$store.state.themeConfig.themeConfig);
    },
    // 递归设置 breadcrumb
    getBreadcrumbList: function getBreadcrumbList(arr) {
      var _this = this;
      arr.map(function (item) {
        _this.routeSplit.map(function (v, k, arrs) {
          if (_this.routeSplitFirst === item.path) {
            _this.routeSplitFirst += "/".concat(arrs[_this.routeSplitIndex]);
            _this.breadcrumbList.push(item);
            _this.routeSplitIndex++;
            if (item.children) _this.getBreadcrumbList(item.children);
          }
        });
      });
    },
    // 当前路由分割处理
    initRouteSplit: function initRouteSplit(path) {
      this.breadcrumbList = [{
        path: '/',
        meta: {
          title: this.$store.state.user.menuList[0].title,
          icon: this.$store.state.user.menuList[0].icon
        }
      }];
      //   this.routeSplit = path.split('/');
      //   this.routeSplit.shift();
      this.routeSplitFirst = path;
      this.routeSplitIndex = 1;
      this.getBreadcrumbList(this.$store.state.user.menuList);
    }
  },
  // 监听路由的变化
  watch: {
    $route: {
      handler: function handler(newVal) {
        // this.initRouteSplit(newVal.path);
        var menuList = this.$store.state.user.menuList;
        var openMenus = (0, _util.getMenuopen)(newVal, menuList);
        var allMenuList = (0, _util.R)(menuList, []);
        var selectMenu = [];
        if (allMenuList.length > 0) {
          openMenus.forEach(function (i) {
            allMenuList.forEach(function (a) {
              if (i === a.path) {
                selectMenu.push(a);
              }
            });
          });
        }
      },
      deep: true
    }
  }
};