var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        {
          staticClass: "box-card",
          attrs: {
            shadow: "never",
            bordered: false,
            "body-style": { padding: "40px 50px" },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "form-data",
            },
            [
              !_vm.loading &&
              _vm.checkPermi(["platform:finance:merchant:closing:config"])
                ? _c("z-b-parser", {
                    attrs: {
                      "is-create": _vm.isCreate,
                      "form-conf": _vm.formConf,
                      "edit-data": _vm.editData,
                      "form-name": _vm.formName,
                      "key-num": _vm.keyNum,
                    },
                    on: { submit: _vm.handlerSubmit },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }