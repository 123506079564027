var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _c(
        "el-card",
        {
          directives: [
            {
              name: "hasPermi",
              rawName: "v-hasPermi",
              value: ["platform:user:page:list"],
              expression: "['platform:user:page:list']",
            },
          ],
          staticClass: "ivu-mt",
          attrs: {
            bordered: false,
            shadow: "never",
            "body-style": { padding: 0 },
          },
        },
        [
          _c(
            "div",
            { staticClass: "padding-add" },
            [
              _c(
                "el-form",
                {
                  ref: "userFrom",
                  attrs: {
                    model: _vm.userFrom,
                    "label-width": "66px",
                    "label-position": "right",
                    inline: "",
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  !_vm.collapse
                    ? _c(
                        "div",
                        { staticClass: "acea-row search-form" },
                        [
                          _c(
                            "div",
                            { staticClass: "search-form-box" },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "用户昵称：" } },
                                [
                                  _c("el-input", {
                                    staticClass: "selWidth",
                                    attrs: {
                                      placeholder: "请输入用户昵称",
                                      size: "small",
                                      clearable: "",
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.userSearchs($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.keywords,
                                      callback: function ($$v) {
                                        _vm.keywords =
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                      },
                                      expression: "keywords",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "联系电话：" } },
                                [
                                  _c("el-input", {
                                    staticClass: "selWidth",
                                    attrs: {
                                      placeholder: "请输入手机号",
                                      size: "small",
                                      clearable: "",
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.userSearchs($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.phone,
                                      callback: function ($$v) {
                                        _vm.phone =
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                      },
                                      expression: "phone",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { staticClass: "search-form-sub" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "small" },
                                  on: { click: _vm.userSearchs },
                                },
                                [_vm._v("搜索")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "ResetSearch",
                                  attrs: { size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.reset("userFrom")
                                    },
                                  },
                                },
                                [_vm._v("重置")]
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "ivu-ml-8 font12 ml10",
                                  on: {
                                    click: function ($event) {
                                      _vm.collapse = !_vm.collapse
                                    },
                                  },
                                },
                                [
                                  !_vm.collapse
                                    ? [
                                        _vm._v(" 展开 "),
                                        _c("i", {
                                          staticClass: "el-icon-arrow-down",
                                        }),
                                      ]
                                    : [
                                        _vm._v(" 收起 "),
                                        _c("i", {
                                          staticClass: "el-icon-arrow-up",
                                        }),
                                      ],
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.collapse
                    ? _c(
                        "div",
                        { staticClass: "acea-row search-form" },
                        [
                          _c(
                            "div",
                            { staticClass: "search-form-box" },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "用户昵称：" } },
                                [
                                  _c("el-input", {
                                    staticClass: "selWidth",
                                    attrs: {
                                      placeholder: "请输入用户昵称",
                                      size: "small",
                                      clearable: "",
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.userSearchs($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.keywords,
                                      callback: function ($$v) {
                                        _vm.keywords =
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                      },
                                      expression: "keywords",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "联系电话：" } },
                                [
                                  _c("el-input", {
                                    staticClass: "selWidth",
                                    attrs: {
                                      placeholder: "请输入手机号",
                                      size: "small",
                                      clearable: "",
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.userSearchs($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.phone,
                                      callback: function ($$v) {
                                        _vm.phone =
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                      },
                                      expression: "phone",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "用户标签：" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "selWidth",
                                      attrs: {
                                        placeholder: "请选择",
                                        clearable: "",
                                        filterable: "",
                                        multiple: "",
                                        size: "small",
                                      },
                                      model: {
                                        value: _vm.labelData,
                                        callback: function ($$v) {
                                          _vm.labelData =
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                        },
                                        expression: "labelData",
                                      },
                                    },
                                    _vm._l(
                                      _vm.labelLists,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            value: item.id,
                                            label: item.name,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "消费情况：" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "selWidth",
                                      attrs: {
                                        placeholder: "请选择",
                                        size: "small",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.userFrom.payCount,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.userFrom,
                                            "payCount",
                                            $$v
                                          )
                                        },
                                        expression: "userFrom.payCount",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { value: "", label: "全部" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { value: "0", label: "0" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { value: "1", label: "1+" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { value: "2", label: "2+" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { value: "3", label: "3+" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { value: "4", label: "4+" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { value: "5", label: "5+" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "用户性别：" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "selWidth",
                                      attrs: {
                                        placeholder: "请选择",
                                        size: "small",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.userFrom.sex,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.userFrom, "sex", $$v)
                                        },
                                        expression: "userFrom.sex",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { value: "", label: "全部" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { value: "0", label: "未知" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { value: "1", label: "男" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { value: "2", label: "女" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { value: "3", label: "保密" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "用户身份：" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "selWidth",
                                      attrs: {
                                        placeholder: "请选择",
                                        size: "small",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.userFrom.isPromoter,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.userFrom,
                                            "isPromoter",
                                            $$v
                                          )
                                        },
                                        expression: "userFrom.isPromoter",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { value: "", label: "全部" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          value: "0",
                                          label: "普通用户",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { value: "1", label: "推广员" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "时间选择：" } },
                                [
                                  _c("el-date-picker", {
                                    staticClass: "selWidth",
                                    attrs: {
                                      align: "right",
                                      "unlink-panels": "",
                                      "value-format": "yyyy-MM-dd",
                                      format: "yyyy-MM-dd",
                                      size: "small",
                                      type: "daterange",
                                      placement: "bottom-end",
                                      placeholder: "自定义时间",
                                      "picker-options": _vm.pickerOptions,
                                    },
                                    on: { change: _vm.onchangeTime },
                                    model: {
                                      value: _vm.timeVal,
                                      callback: function ($$v) {
                                        _vm.timeVal = $$v
                                      },
                                      expression: "timeVal",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "注册类型：" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "selWidth",
                                      attrs: {
                                        placeholder: "请选择",
                                        size: "small",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.userFrom.registerType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.userFrom,
                                            "registerType",
                                            $$v
                                          )
                                        },
                                        expression: "userFrom.registerType",
                                      },
                                    },
                                    _vm._l(
                                      _vm.registerTypeList,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { staticClass: "search-form-sub" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "small" },
                                  on: { click: _vm.userSearchs },
                                },
                                [_vm._v("搜索")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "ResetSearch",
                                  attrs: { size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.reset("userFrom")
                                    },
                                  },
                                },
                                [_vm._v("重置")]
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "ivu-ml-8 font12 ml10",
                                  on: {
                                    click: function ($event) {
                                      _vm.collapse = !_vm.collapse
                                    },
                                  },
                                },
                                [
                                  !_vm.collapse
                                    ? [
                                        _vm._v(" 展开 "),
                                        _c("i", {
                                          staticClass: "el-icon-arrow-down",
                                        }),
                                      ]
                                    : [
                                        _vm._v(" 收起 "),
                                        _c("i", {
                                          staticClass: "el-icon-arrow-up",
                                        }),
                                      ],
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card mt14",
          attrs: {
            "body-style": { padding: "20px" },
            shadow: "never",
            bordered: false,
          },
        },
        [
          _c(
            "div",
            { staticClass: "acea-row" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["platform:user:tag"],
                      expression: "['platform:user:tag']",
                    },
                  ],
                  attrs: {
                    disabled: !_vm.multipleSelectionAll.length,
                    size: "small",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.setBatch("label")
                    },
                  },
                },
                [_vm._v("批量设置标签")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: [
                        "platform:coupon:can:send:list",
                        "platform:coupon:batch:send",
                      ],
                      expression:
                        "['platform:coupon:can:send:list', 'platform:coupon:batch:send']",
                    },
                  ],
                  attrs: {
                    disabled: !_vm.multipleSelectionAll.length,
                    size: "small",
                  },
                  on: { click: _vm.sendCoupon },
                },
                [_vm._v("发送优惠券")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "table",
              staticClass: "mt20",
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "highlight-current-row": "",
              },
              on: {
                "selection-change": _vm.handleSelectionChange,
                "select-all": _vm.selectAll,
                select: _vm.selectOne,
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "expand", width: "40" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "el-form",
                          {
                            staticClass: "demo-table-expand",
                            attrs: { "label-position": "left", inline: "" },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "用户身份：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterIsPromoter")(
                                        props.row.isPromoter
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "首次访问：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(
                                        props.row.createTime
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "近次访问：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(
                                        props.row.lastLoginTime
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c("el-form-item", { attrs: { label: "标签：" } }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm._f("tagFilter")(props.row.tagId))
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("el-form-item", { attrs: { label: "地址：" } }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("filterEmpty")(
                                      props.row.province + props.row.city
                                    )
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                staticStyle: {
                                  width: "100%",
                                  display: "flex",
                                  "margin-right": "10px",
                                },
                                attrs: { label: "备注：" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(props.row.mark)
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { type: "selection", width: "50" },
              }),
              _vm._v(" "),
              _vm.checkedCities.includes("ID")
                ? _c("el-table-column", {
                    attrs: { prop: "id", label: "ID", width: "50" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("头像")
                ? _c("el-table-column", {
                    attrs: { label: "头像", width: "100" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "demo-image__preview line-heightOne",
                                },
                                [
                                  _c("el-image", {
                                    attrs: {
                                      src: scope.row.avatar,
                                      "preview-src-list": [scope.row.avatar],
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      582077438
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  "show-overflow-tooltip": "",
                  prop: "siteCode",
                  label: "站点编号",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { "show-overflow-tooltip": "", label: "姓名" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { class: scope.row.isLogoff ? "red" : "" }, [
                          _vm._v(
                            _vm._s(_vm._f("filterEmpty")(scope.row.nickname)) +
                              " | " +
                              _vm._s(_vm._f("sexFilter")(scope.row.sex))
                          ),
                        ]),
                        _vm._v(" "),
                        scope.row.isLogoff == true
                          ? _c(
                              "span",
                              { class: scope.row.isLogoff ? "red" : "" },
                              [_vm._v("|")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.isLogoff
                          ? _c("span", { staticClass: "red" }, [
                              _vm._v("(已注销)"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "联系电话" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("filterEmpty")(scope.row.phone))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "orgName",
                  "min-width": "130",
                  "show-overflow-tooltip": "",
                  label: "业务员(一级机构)",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "parentOrgName",
                  "min-width": "130",
                  "show-overflow-tooltip": "",
                  label: "管理机构(二级机构)",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "130",
                  fixed: "right",
                  "scoped-slot": _vm.renderHeader,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["platform:user:update"],
                                expression: "['platform:user:update']",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.editUser(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c("el-divider", { attrs: { direction: "vertical" } }),
                        _vm._v(" "),
                        _c(
                          "el-dropdown",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: [
                                  "platform:user:detail",
                                  "platform:user:operate:integer",
                                  "platform:user:operate:balance",
                                  "platform:user:tag",
                                  "platform:retail:store:update:user:spread",
                                  "platform:retail:store:clean:user:spread",
                                ],
                                expression:
                                  "[\n                'platform:user:detail',\n                'platform:user:operate:integer',\n                'platform:user:operate:balance',\n                'platform:user:tag',\n                'platform:retail:store:update:user:spread',\n                'platform:retail:store:clean:user:spread',\n              ]",
                              },
                            ],
                            attrs: { trigger: "click" },
                          },
                          [
                            _c("span", { staticClass: "el-dropdown-link" }, [
                              _vm._v(" 更多"),
                              _c("i", {
                                staticClass:
                                  "el-icon-arrow-down el-icon--right",
                              }),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _vm.checkPermi(["platform:user:detail"])
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.onDetails(scope.row.id)
                                          },
                                        },
                                      },
                                      [_vm._v("用户详情")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkPermi([
                                  "platform:user:operate:integer",
                                ])
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.editPoint(
                                              scope.row,
                                              "integral"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("修改积分")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkPermi([
                                  "platform:user:operate:balance",
                                ])
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.editPoint(
                                              scope.row,
                                              "balance"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("修改余额")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkPermi(["platform:user:tag"])
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.setBatch(
                                              "label",
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("设置标签")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkPermi([
                                  "platform:retail:store:update:user:spread",
                                ])
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.setExtension(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("修改上级推广人")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.spreadUid &&
                                scope.row.spreadUid > 0 &&
                                _vm.checkPermi([
                                  "platform:retail:store:clean:user:spread",
                                ])
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.clearSpread(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("清除上级推广人")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "page-sizes": [15, 30, 45, 60],
                  "page-size": _vm.userFrom.limit,
                  "current-page": _vm.userFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.card_select_show,
              expression: "card_select_show",
            },
          ],
          staticClass: "card_abs",
          style: { top: _vm.collapse ? 570 + "px" : 270 + "px" },
        },
        [
          [
            _c(
              "div",
              { staticClass: "cell_ht" },
              [
                _c(
                  "el-checkbox",
                  {
                    attrs: { indeterminate: _vm.isIndeterminate },
                    on: { change: _vm.handleCheckAllChange },
                    model: {
                      value: _vm.checkAll,
                      callback: function ($$v) {
                        _vm.checkAll = $$v
                      },
                      expression: "checkAll",
                    },
                  },
                  [_vm._v("全选")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.checkSave()
                      },
                    },
                  },
                  [_vm._v("保存")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-checkbox-group",
              {
                on: { change: _vm.handleCheckedCitiesChange },
                model: {
                  value: _vm.checkedCities,
                  callback: function ($$v) {
                    _vm.checkedCities = $$v
                  },
                  expression: "checkedCities",
                },
              },
              _vm._l(_vm.columnData, function (item) {
                return _c(
                  "el-checkbox",
                  {
                    key: item,
                    staticClass: "check_cell",
                    attrs: { label: item },
                  },
                  [_vm._v(_vm._s(item))]
                )
              }),
              1
            ),
          ],
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改推广人",
            visible: _vm.extensionVisible,
            width: "540px",
            "before-close": _vm.handleCloseExtension,
          },
          on: {
            "update:visible": function ($event) {
              _vm.extensionVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "formExtension",
              staticClass: "formExtension mt20",
              attrs: {
                model: _vm.formExtension,
                rules: _vm.ruleInline,
                "label-width": "70px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "选择用户:", prop: "image" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upLoadPicBox",
                      on: { click: _vm.modalPicTap },
                    },
                    [
                      _vm.selectedUser.image
                        ? _c("div", { staticClass: "acea-row" }, [
                            _c("img", {
                              staticClass: "pictrue",
                              attrs: { src: _vm.selectedUser.image },
                            }),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.selectedUser.nickname)),
                            ]),
                          ])
                        : _c("div", { staticClass: "upLoad" }, [
                            _c("i", {
                              staticClass: "el-icon-camera cameraIconfont",
                            }),
                          ]),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.extensionVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.onSubExtension("formExtension")
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "推广员列表",
            visible: _vm.userVisible,
            width: "900px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.userVisible = $event
            },
          },
        },
        [
          _vm.userVisible
            ? _c("user-list", { on: { getTemplateRow: _vm.getTemplateRow } })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.userVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.userVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "设置",
            visible: _vm.dialogVisible,
            width: "540px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "dynamicValidateForm",
              staticClass: "demo-dynamic",
              attrs: { model: _vm.dynamicValidateForm, "label-width": "75px" },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "groupId",
                    label: "用户标签：",
                    rules: [
                      {
                        required: true,
                        message: "请选择用户标签",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择标签",
                        filterable: "",
                        multiple: "",
                      },
                      model: {
                        value: _vm.dynamicValidateForm.groupId,
                        callback: function ($$v) {
                          _vm.$set(_vm.dynamicValidateForm, "groupId", $$v)
                        },
                        expression: "dynamicValidateForm.groupId",
                      },
                    },
                    _vm._l(_vm.labelLists, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { value: item.id, label: item.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "debounceClick",
                      rawName: "v-debounceClick",
                      value: function () {
                        _vm.submitForm("dynamicValidateForm")
                      },
                      expression:
                        "\n            () => {\n              submitForm('dynamicValidateForm');\n            }\n          ",
                    },
                  ],
                  attrs: { type: "primary" },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "编辑", visible: _vm.visible, width: "900px" },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _vm.visible
            ? _c("edit-from", {
                staticClass: "dialog-bottom",
                attrs: { userInfo: _vm.userInfo },
                on: { resetForm: _vm.resetForm },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.type === "integral" ? "积分" : "余额",
            visible: _vm.VisiblePoint,
            width: "540px",
            "close-on-click-modal": false,
            "before-close": _vm.handlePointClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.VisiblePoint = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingPoint,
                  expression: "loadingPoint",
                },
              ],
              ref: "PointValidateForm",
              staticClass: "demo-dynamic",
              attrs: { model: _vm.PointValidateForm, "label-width": "75px" },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.type === "integral" ? "积分：" : "余额：",
                    required: "",
                  },
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.type === "integral"
                          ? _vm.userInfo.integral
                          : _vm.userInfo.nowMoney
                      )
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.type === "integral"
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { label: "修改积分：", required: "" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.PointValidateForm.operateType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.PointValidateForm,
                                  "operateType",
                                  $$v
                                )
                              },
                              expression: "PointValidateForm.operateType",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "add" } }, [
                              _vm._v("增加"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: "sub" } }, [
                              _vm._v("减少"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "积分：", required: "" } },
                      [
                        _c("el-input-number", {
                          attrs: {
                            type: "text",
                            "step-strictly": "",
                            min: 0,
                            max: 999999,
                          },
                          model: {
                            value: _vm.PointValidateForm.integral,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.PointValidateForm,
                                "integral",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "PointValidateForm.integral",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : [
                    _c(
                      "el-form-item",
                      { attrs: { label: "修改余额：", required: "" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.PointValidateForm.operateType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.PointValidateForm,
                                  "operateType",
                                  $$v
                                )
                              },
                              expression: "PointValidateForm.operateType",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "add" } }, [
                              _vm._v("增加"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: "sub" } }, [
                              _vm._v("减少"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "余额：", required: "" } },
                      [
                        _c("el-input-number", {
                          attrs: {
                            type: "text",
                            precision: 2,
                            step: 0.1,
                            min: 0,
                            max: 999999,
                          },
                          model: {
                            value: _vm.PointValidateForm.money,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.PointValidateForm,
                                "money",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "PointValidateForm.money",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handlePointClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingBtn },
                  on: {
                    click: function ($event) {
                      return _vm.submitPointForm("PointValidateForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("detail-user", { ref: "userDetailFrom" }),
      _vm._v(" "),
      _vm.visibleCoupon
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "优惠券列表",
                visible: _vm.visibleCoupon,
                width: "1000px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.visibleCoupon = $event
                },
              },
            },
            [
              _vm.visibleCoupon
                ? _c("coupon-List", {
                    ref: "couponList",
                    attrs: { checkedIds: _vm.checkedIds },
                    on: { sendSuccess: _vm.sendSuccess },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }