var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    { staticClass: "layout-main" },
    [
      _c(
        "el-scrollbar",
        {
          ref: "layoutScrollbarRef",
          staticClass: "layout-scrollbar",
          style: { minHeight: "calc(100vh - " + _vm.headerHeight },
        },
        [
          _c("LayoutParentView"),
          _vm._v(" "),
          _vm.getThemeConfig.isFooter ? _c("Footers") : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }