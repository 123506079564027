var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("iframe", {
      ref: "iframes",
      staticStyle: { border: "none" },
      attrs: {
        src: "https://api.crmeb.com/",
        width: "100%",
        height: _vm.iframeHeight,
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }