var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        {
          staticClass: "box-card",
          attrs: { shadow: "never", bordered: false },
        },
        [
          _c(
            "el-alert",
            {
              staticClass: "mb20",
              attrs: {
                type: "warning",
                effect: "light",
                closable: false,
                "show-icon": "",
              },
            },
            [
              _vm._t("title", [
                _c(
                  "div",
                  { staticClass: "acea-row" },
                  [
                    _c("div", [
                      _vm._v("关闭会员后，需要在页面设计中隐藏「我的等级」；"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-link",
                      {
                        staticClass: "font12",
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.handlerToLink("/page/design/viewDesign")
                          },
                        },
                      },
                      [_vm._v("立即前往")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-alert",
            {
              staticClass: "mb30",
              attrs: {
                type: "warning",
                effect: "light",
                closable: false,
                "show-icon": "",
              },
            },
            [
              _vm._t("title", [
                _c(
                  "div",
                  { staticClass: "acea-row row-middle" },
                  [
                    _c("div", { staticClass: "line-heightOne" }, [
                      _vm._v("签到可获得经验值，在「签到配置」页面操作；"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-link",
                      {
                        staticClass: "font12 line-heightOne",
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.handlerToLink("/marketing/sign/config")
                          },
                        },
                      },
                      [_vm._v("立即前往")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "form-data",
            },
            [
              !_vm.loading &&
              _vm.checkPermi(["platform:finance:merchant:closing:config"])
                ? _c("z-b-parser", {
                    attrs: {
                      "is-create": 1,
                      "form-conf": _vm.formConf,
                      "edit-data": _vm.editData,
                      "form-name": _vm.formName,
                      "key-num": _vm.keyNum,
                    },
                    on: { submit: _vm.handlerSubmit },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }