var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "layout-navbars-breadcrumb-user",
      style: { flex: _vm.layoutUserFlexNum },
    },
    [
      _c(
        "div",
        {
          staticClass: "layout-navbars-breadcrumb-user-icon",
          on: { click: _vm.refresh },
        },
        [
          _c("i", {
            staticClass: "el-icon-refresh-right",
            attrs: { title: "刷新" },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "layout-navbars-breadcrumb-user-icon",
          on: { click: _vm.onSearchClick },
        },
        [
          _c("i", {
            staticClass: "el-icon-search",
            attrs: { title: "菜单搜索" },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "layout-navbars-breadcrumb-user-icon",
          on: { click: _vm.onScreenfullClick },
        },
        [
          _c("i", {
            class: !_vm.isScreenfull ? "el-icon-full-screen" : "el-icon-crop",
            attrs: { title: _vm.isScreenfull ? "关全屏" : "开全屏" },
          }),
        ]
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "el-dropdown",
        {
          attrs: { "show-timeout": 70, "hide-timeout": 50 },
          on: { command: _vm.onDropdownCommand },
        },
        [
          _c("span", { staticClass: "layout-navbars-breadcrumb-user-link" }, [
            _vm._v("\n      " + _vm._s(_vm.getUserInfos.name) + "\n      "),
            _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
          ]),
          _vm._v(" "),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c("el-dropdown-item", { attrs: { command: "users" } }, [
                _vm._v("个人中心"),
              ]),
              _vm._v(" "),
              _c("el-dropdown-item", { attrs: { command: "password" } }, [
                _vm._v("修改密码"),
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-item",
                { attrs: { divided: "", command: "logOut" } },
                [_vm._v("退出登录")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "layout-navbars-breadcrumb-user-icon",
          on: { click: _vm.onLayoutSetingClick },
        },
        [
          _c("i", {
            staticClass: "el-icon-setting",
            attrs: { title: "布局配置" },
          }),
        ]
      ),
      _vm._v(" "),
      _c("Search", { ref: "searchRef" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "layout-navbars-breadcrumb-user-icon" }, [
      _c("div", { staticClass: "platformLabel" }, [_vm._v("平台")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }