"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _contextmenu = _interopRequireDefault(require("@/layout/navBars/tagsView/contextmenu"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'tagsView',
  components: {
    Contextmenu: _contextmenu.default
  },
  data: function data() {
    return {
      userInfo: {},
      // tagsViewList: [],
      tagsDropdown: {
        x: '',
        y: ''
      },
      tagsRefsIndex: 0,
      tagsRoutePath: this.$route.path,
      // tagsViewRoutesList: [],
      dropdownList: [{
        id: 0,
        txt: '刷新',
        affix: false,
        icon: 'el-icon-refresh-right'
      }, {
        id: 1,
        txt: '关闭',
        affix: false,
        icon: 'el-icon-close'
      }, {
        id: 2,
        txt: '关闭其他',
        affix: false,
        icon: 'el-icon-circle-close'
      }, {
        id: 3,
        txt: '全部关闭',
        affix: false,
        icon: 'el-icon-folder-delete'
      }],
      scrollTagIcon: false
    };
  },
  computed: {
    // 获取布局配置信息
    getThemeConfig: function getThemeConfig() {
      return this.$store.state.themeConfig.themeConfig;
    },
    // 动态设置 tagsView 风格样式
    setTagsStyle: function setTagsStyle() {
      return this.$store.state.themeConfig.themeConfig.tagsStyle;
    },
    tagsViewList: function tagsViewList() {
      return this.$store.state.menu.tagNavList;
    },
    tagsViewRoutesList: function tagsViewRoutesList() {
      return this.$store.state.menu.tagNavList;
    }
  },
  created: function created() {
    var _this = this;
    // 监听非本页面调用 0 刷新当前，1 关闭当前，2 关闭其它，3 关闭全部
    this.bus.$on('onCurrentContextmenuClick', function (data) {
      _this.onCurrentContextmenuClick(data);
    });
  },
  mounted: function mounted() {
    var _this2 = this;
    if (!this.$store.state.menu.tagNavList.length) {
      this.getTagsViewRoutes();
    }
    if (this.$store.state.menu.tagNavList.length) {
      if (this.$refs.tagsView && this.$refs.tagsView.offsetWidth < this.$refs.scrollbarRef.$refs.wrap.scrollWidth) {
        this.scrollTagIcon = true;
      }
      window.addEventListener('resize', function () {
        if (_this2.$refs.tagsView && _this2.$refs.tagsView.offsetWidth < _this2.$refs.scrollbarRef.$refs.wrap.scrollWidth) {
          _this2.scrollTagIcon = true;
        } else {
          _this2.scrollTagIcon = false;
        }
      });
    }
  },
  methods: _objectSpread(_objectSpread({}, (0, _vuex.mapMutations)('menu', ['setBreadCrumb', 'setTagNavList', 'addTag', 'setLocal', 'setHomeRoute', 'closeTag'])), {}, {
    isAffix: function isAffix(tag) {
      return tag.meta && tag.meta.isAffix;
    },
    clickDropdown: function clickDropdown(e) {
      var data = {
        id: e,
        path: this.$route.path
      };
      this.onCurrentContextmenuClick(data);
    },
    // 获取路由信息
    getRoutesList: function getRoutesList() {
      return this.$store.state.user.menuList;
    },
    // 当前的 tagsView 项点击时
    onTagsClick: function onTagsClick(v, k) {
      this.tagsRoutePath = v.path;
      this.tagsRefsIndex = k;
      this.$router.push(v);
    },
    // 获取 tagsView 的下标：用于处理 tagsView 点击时的横向滚动
    getTagsRefsIndex: function getTagsRefsIndex(path) {
      if (this.tagsViewList.length > 0) {
        this.tagsRefsIndex = this.tagsViewList.findIndex(function (item) {
          return item.path === path;
        });
      }
    },
    // 鼠标滚轮滚动
    onHandleScroll: function onHandleScroll(e) {
      this.$refs.scrollbarRef.$refs.wrap.scrollLeft += e.wheelDelta / 4;
    },
    scrollTag: function scrollTag(production) {
      var scrollRefs = this.$refs.scrollbarRef.$refs.wrap.scrollWidth;
      var scrollLeft = this.$refs.scrollbarRef.$refs.wrap.scrollLeft;
      if (production === 'left') {
        this.$refs.scrollbarRef.$refs.wrap.scrollLeft = scrollLeft - 300 <= 0 ? 0 : scrollLeft - 300;
      } else {
        this.$refs.scrollbarRef.$refs.wrap.scrollLeft = scrollLeft + 300 >= scrollRefs ? scrollRefs : scrollLeft + 300;
      }
    },
    // tagsView 横向滚动
    tagsViewmoveToCurrentTag: function tagsViewmoveToCurrentTag() {
      var _this3 = this;
      this.$nextTick(function () {
        var tagsRefs = _this3.$refs.tagsRefs;
        if (!tagsRefs) return;
        if (tagsRefs.length <= 0) return false;
        // 当前 li 元素
        var liDom = tagsRefs[_this3.tagsRefsIndex];
        // 当前 li 元素下标
        var liIndex = _this3.tagsRefsIndex;
        // 当前 ul 下 li 元素总长度
        var liLength = tagsRefs.length;
        // 最前 li
        var liFirst = tagsRefs[0];
        // 最后 li
        var liLast = tagsRefs[tagsRefs.length - 1];
        // 当前滚动条的值
        var scrollRefs = _this3.$refs.scrollbarRef.$refs.wrap;
        // 当前滚动条滚动宽度
        var scrollS = scrollRefs.scrollWidth;
        // 当前滚动条偏移宽度
        var offsetW = scrollRefs.offsetWidth;
        // 当前滚动条偏移距离
        var scrollL = scrollRefs.scrollLeft;
        // 上一个 tags li dom
        var liPrevTag = tagsRefs[_this3.tagsRefsIndex - 1];
        // 下一个 tags li dom
        var liNextTag = tagsRefs[_this3.tagsRefsIndex + 1];
        // 上一个 tags li dom 的偏移距离
        var beforePrevL = '';
        // 下一个 tags li dom 的偏移距离
        var afterNextL = '';
        if (liDom === liFirst) {
          // 头部
          scrollRefs.scrollLeft = 0;
        } else if (liDom === liLast) {
          // 尾部
          scrollRefs.scrollLeft = scrollS - offsetW;
        } else {
          // 非头/尾部
          if (liIndex === 0) beforePrevL = liFirst.offsetLeft - 5;else beforePrevL = liPrevTag.offsetLeft - 5;
          if (liIndex === liLength) afterNextL = liLast.offsetLeft + liLast.offsetWidth + 5;else afterNextL = liNextTag.offsetLeft + liNextTag.offsetWidth + 5;
          if (afterNextL > scrollL + offsetW) {
            scrollRefs.scrollLeft = afterNextL - offsetW;
          } else if (beforePrevL < scrollL) {
            scrollRefs.scrollLeft = beforePrevL;
          }
        }
        // 更新滚动条，防止不出现
        _this3.updateScrollbar();
      });
    },
    // 更新滚动条显示
    updateScrollbar: function updateScrollbar() {
      this.$refs.scrollbarRef.update();
    },
    // 递归查找当前路径下的组件信息
    filterCurrentMenu: function filterCurrentMenu(arr, currentPath, callback) {
      var _this4 = this;
      arr.map(function (item) {
        if (item.path === currentPath) {
          callback(item);
          return false;
        }
        item = Object.assign({}, item);
        if (item.children.length) {
          item.children = _this4.filterCurrentMenu(item.children, currentPath, callback);
        }
      });
    },
    // 数组对象去重
    duplicate: function duplicate(arr) {
      var newobj = {};
      arr = arr.reduce(function (preVal, curVal) {
        newobj[curVal.path] ? '' : newobj[curVal.path] = preVal.push(curVal);
        return preVal;
      }, []);
      return arr;
    },
    // 获取 vuex 中的 tagsViewRoutes 列表
    getTagsViewRoutes: function getTagsViewRoutes() {
      this.tagsRoutePath = this.$route.path;
      this.setTagNavList(this.$store.state.user.oneLvMenus);
      this.initTagsViewList();
    },
    // 存储 tagsViewList 到浏览器临时缓存中，页面刷新时，保留记录
    addBrowserSetSession: function addBrowserSetSession(tagNavList) {
      this.setTagNavList(tagNavList);
    },
    // 初始化设置了 tagsView 数据
    initTagsViewList: function initTagsViewList() {
      // if (Session.get('tagsViewList') && this.$store.state.themeConfig.themeConfig.isCacheTagsView) {
      // } else {
      // this.tagsViewList = Session.get("tagsViewList");

      var arr = [];
      this.tagsViewRoutesList.map(function (v) {
        if (v.meta && v.meta.isAffix) arr.push(_objectSpread({}, v));
      });
      // }
      this.setTagNavList(arr);
      // 初始化当前元素(li)的下标
      this.getTagsRefsIndex(this.$route.path);
      // 添加初始化横向滚动条移动到对应位置
      this.tagsViewmoveToCurrentTag();
    },
    // 添加 tagsView：未设置隐藏（isHide）也添加到在 tagsView 中
    addTagsView: function addTagsView(path, to) {
      if (this.tagsViewList.some(function (v) {
        return v.path === path;
      })) return false;
      var item = this.tagsViewList.find(function (v) {
        return v.path === path;
      });
      if (to) {
        this.tagsViewList.push(_objectSpread({}, to));
        this.addBrowserSetSession(this.tagsViewList);
      }
    },
    // 右键菜单点击时显示菜单列表
    onContextmenu: function onContextmenu(v, e) {
      var clientX = e.clientX,
        clientY = e.clientY;
      this.tagsDropdown.x = clientX;
      this.tagsDropdown.y = clientY;
      this.$refs.tagsContextmenu.openContextmenu(v);
    },
    onContextmenuIcon: function onContextmenuIcon(e) {},
    // 当前项右键菜单点击
    onCurrentContextmenuClick: function onCurrentContextmenuClick(data) {
      var id = data.id,
        path = data.path;
      var currentTag = this.tagsViewList.find(function (v) {
        return v.path === path;
      });
      switch (id) {
        case 0:
          this.refreshCurrentTagsView(path);
          this.$router.push({
            path: path,
            query: currentTag.query
          });
          break;
        case 1:
          this.closeCurrentTagsView(path);
          break;
        case 2:
          this.closeOtherTagsView(path, currentTag.query);
          break;
        case 3:
          this.closeAllTagsView(path);
          break;
      }
    },
    refreshIcon: function refreshIcon() {
      var _this5 = this;
      this.$nextTick(function (e) {
        if (_this5.$refs.tagsView.offsetWidth < _this5.$refs.scrollbarRef.$refs.wrap.scrollWidth) {
          _this5.scrollTagIcon = true;
        } else {
          _this5.scrollTagIcon = false;
        }
      });
    },
    // 1、刷新当前 tagsView：
    refreshCurrentTagsView: function refreshCurrentTagsView(path) {
      this.bus.$emit('onTagsViewRefreshRouterView', path);
    },
    // 2、关闭当前 tagsView：当前项 `tags-view` icon 关闭时点击，如果是设置了固定的（isAffix），不可以关闭
    closeCurrentTagsView: function closeCurrentTagsView(path) {
      var _this6 = this;
      this.tagsViewList.map(function (v, k, arr) {
        if (!v.meta.isAffix) {
          if (v.path === path) {
            _this6.tagsViewList.splice(k, 1);
            setTimeout(function () {
              // 最后一个
              if (_this6.tagsViewList.length === k) _this6.$router.push({
                path: arr[arr.length - 1].path,
                query: arr[arr.length - 1].query
              });
              // 否则，跳转到下一个
              else _this6.$router.push({
                path: arr[k].path,
                query: arr[k].query
              });
            }, 0);
          }
        }
      });
      this.setTagNavList(this.tagsViewList);
      //   this.addBrowserSetSession(this.tagNavList);
    },
    // 3、关闭其它 tagsView：如果是设置了固定的（isAffix），不进行关闭
    closeOtherTagsView: function closeOtherTagsView(path, query) {
      var tagsViewList = [];
      this.tagsViewRoutesList.map(function (v) {
        if (v.meta && v.meta.isAffix || v.path === path) {
          tagsViewList.push(_objectSpread({}, v));
        }
      });
      this.addBrowserSetSession(tagsViewList);
      this.$router.push({
        path: path,
        query: query
      });

      // this.addTagsView(path);
    },
    // 4、关闭全部 tagsView：如果是设置了固定的（isAffix），不进行关闭
    closeAllTagsView: function closeAllTagsView(path) {
      var _this7 = this;
      var tagsViewList = [];
      this.tagsViewRoutesList.map(function (v) {
        if (v.meta.isAffix) {
          tagsViewList.push(_objectSpread({}, v));
          if (tagsViewList.some(function (v) {
            return v.path === path;
          })) _this7.$router.push({
            path: path,
            query: _this7.$route.query
          });else _this7.$router.push({
            path: v.path,
            query: _this7.$route.query
          });
        }
      });
      this.addBrowserSetSession(tagsViewList);
    }
  }),
  watch: {
    // 监听路由变化
    $route: {
      handler: function handler(to) {
        this.tagsRoutePath = to.path;
        this.addTagsView(to.path, to);
        this.getTagsRefsIndex(to.path);
        this.tagsViewmoveToCurrentTag();
        this.refreshIcon();
      },
      deep: true
    }
  },
  destroyed: function destroyed() {
    // 取消非本页面调用监听（fun/tagsView）
    this.bus.$off('onCurrentContextmenuClick');
  }
};