var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-container" },
    [
      _c(
        "el-button",
        {
          attrs: { icon: "el-icon-upload", size: "mini", type: "primary" },
          on: {
            click: function ($event) {
              return _vm.modalPicTap("2")
            },
          },
        },
        [_vm._v(" 上传图片")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }