"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _systemPcConfig = require("@/api/systemPcConfig");
var _permission = require("@/utils/permission");
// 权限判断函数
var _default = exports.default = {
  name: 'basicSettings',
  data: function data() {
    return {
      fullscreenLoading: false,
      ruleValidate: {
        goodStoreImage: [{
          required: true,
          message: '请上传品牌好店广告图',
          trigger: 'change'
        }],
        leftTopLogo: [{
          required: true,
          message: '请上传左上角logo',
          trigger: 'change'
        }],
        goPhoneQrCodeTypeList: [{
          type: 'array',
          required: true,
          message: '请选择手机体验购买二维码类型',
          trigger: 'change'
        }]
      },
      formValidate: {
        goPhoneQrCodeTypeList: [],
        goodStoreImage: '',
        leftTopLogo: '',
        goPhoneQrCodeType: ''
      }
    };
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['platform:pc:shopping:base:config:get'])) this.getBaseConfig();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    //上传图片
    modalPicTap: function modalPicTap(type, num) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (!img) return;
        if (num === 'leftTopLogo') {
          _this.formValidate.leftTopLogo = img[0].sattDir;
        } else {
          _this.formValidate.goodStoreImage = img[0].sattDir;
        }
      }, type, 'imageUrl');
    },
    //保存
    handleBaseConfigSave: function handleBaseConfigSave(formName) {
      var _this2 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this2.loadingBtn = true;
          _this2.formValidate.goPhoneQrCodeType = _this2.formValidate.goPhoneQrCodeTypeList.join(',');
          (0, _systemPcConfig.pcBaseConfigEditApi)(_this2.formValidate).then(function (res) {
            _this2.$message.success('保存成功');
            _this2.loadingBtn = false;
            _this2.getBaseConfig();
          }).catch(function () {
            _this2.loadingBtn = false;
          });
        } else {
          return false;
        }
      });
    },
    getBaseConfig: function getBaseConfig() {
      var _this3 = this;
      (0, _systemPcConfig.pcBaseConfigGetApi)().then(function (res) {
        Object.assign(_this3.formValidate, res);
        _this3.formValidate.goPhoneQrCodeTypeList = res.goPhoneQrCodeType ? res.goPhoneQrCodeType.split(',') : [];
      });
    }
  }
};