var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.configData && _vm.configData.isShow === 1
    ? _c("div", { staticClass: "numbox borderPadding" }, [
        _c("div", { staticClass: "c_row-item" }, [
          _c("div", { staticClass: "label labelwidth" }, [
            _c("span", [_vm._v(_vm._s(_vm.configData.title || "产品数量"))]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "slider-box ml22" },
            [
              _c("el-input-number", {
                staticStyle: { "text-align": "right" },
                attrs: {
                  size: "small",
                  placeholder: "请输入数量",
                  step: 1,
                  max: _vm.configData.max ? _vm.configData.max : 100,
                  min: _vm.configData.min ? _vm.configData.min : 1,
                },
                on: { change: _vm.bindChange },
                model: {
                  value: _vm.configData.val,
                  callback: function ($$v) {
                    _vm.$set(_vm.configData, "val", $$v)
                  },
                  expression: "configData.val",
                },
              }),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }