var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("pages-header", {
        ref: "pageHeader",
        attrs: {
          title: _vm.activityTpye === 1 ? "添加氛围图" : "添加活动边框",
          backUrl:
            _vm.activityTpye === 1
              ? "/marketing/atmosphere/list"
              : "/marketing/border/list",
        },
      }),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card mb20 mt20",
          attrs: {
            "body-style": { padding: "0 20px 20px" },
            bordered: false,
            shadow: "never",
          },
        },
        [
          _vm.tabList.length > 0
            ? _c(
                "el-tabs",
                {
                  staticClass: "list-tabs",
                  on: {
                    "tab-click": function ($event) {
                      return _vm.onChangeType("formValidate")
                    },
                  },
                  model: {
                    value: _vm.currentTab,
                    callback: function ($$v) {
                      _vm.currentTab = $$v
                    },
                    expression: "currentTab",
                  },
                },
                _vm._l(_vm.tabList, function (item, index) {
                  return _c("el-tab-pane", {
                    key: index,
                    attrs: { name: item.value, label: item.title },
                  })
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.fullscreenLoading,
                  expression: "fullscreenLoading",
                },
              ],
              ref: "formValidate",
              staticClass: "formValidate mt25",
              attrs: {
                rules: _vm.ruleValidate,
                model: _vm.formValidate,
                "label-width": "120px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab == 1,
                      expression: "currentTab == 1",
                    },
                  ],
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动名称：", prop: "name" } },
                    [
                      _c("el-input", {
                        staticClass: "selWidth",
                        attrs: { size: "small", placeholder: "请输入活动名称" },
                        model: {
                          value: _vm.formValidate.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "name", $$v)
                          },
                          expression: "formValidate.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动时间：", prop: "timeVal" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "mb10 selWidth",
                        attrs: {
                          "default-time": ["00:00:00", "23:59:59"],
                          type: "datetimerange",
                          "range-separator": "至",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "start-placeholder": "开始日期",
                          "picker-options": _vm.pickerOptions,
                          "end-placeholder": "结束日期",
                        },
                        on: { change: _vm.onchangeTime },
                        model: {
                          value: _vm.formValidate.timeVal,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "timeVal", $$v)
                          },
                          expression: "formValidate.timeVal",
                        },
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "desc" }, [
                        _vm._v("设置活动氛围图在商城展示时间"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label:
                          _vm.activityTpye === 1
                            ? "活动氛围图："
                            : "活动边框：",
                        prop: "style",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "upLoadPicBox mb10",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap("1", "dan")
                            },
                          },
                        },
                        [
                          _vm.formValidate.style
                            ? _c("div", { staticClass: "pictrue" }, [
                                _c("img", {
                                  attrs: { src: _vm.formValidate.style },
                                }),
                              ])
                            : _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "desc" }, [
                        _vm._v(
                          _vm._s(
                            _vm.activityTpye === 1 ? "750*100px" : "750*750px"
                          )
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否开启：" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          width: 56,
                          "active-value": true,
                          "inactive-value": false,
                          "active-text": "开启",
                          "inactive-text": "关闭",
                        },
                        model: {
                          value: _vm.formValidate.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "status", $$v)
                          },
                          expression: "formValidate.status",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab == 2,
                      expression: "currentTab == 2",
                    },
                  ],
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { "label-width": "0" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.formValidate.method,
                            callback: function ($$v) {
                              _vm.$set(_vm.formValidate, "method", $$v)
                            },
                            expression: "formValidate.method",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("全部产品参与"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("指定产品参与"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("指定品牌参与"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 3 } }, [
                            _vm._v("指定分类参与"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 4 } }, [
                            _vm._v("指定商户参与"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.formValidate.method == 1
                    ? _c(
                        "el-form-item",
                        { attrs: { "label-width": "0" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "primary" },
                              on: { click: _vm.addGoods },
                            },
                            [_vm._v("添加产品")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                size: "small",
                                disabled: !_vm.multipleSelection.length,
                              },
                              on: { click: _vm.batchDel },
                            },
                            [_vm._v("批量删除")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.formValidate.method == 1
                    ? _c(
                        "el-form-item",
                        { attrs: { "label-width": "0" } },
                        [
                          _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.listLoading,
                                  expression: "listLoading",
                                },
                              ],
                              ref: "tableList",
                              staticClass: "tableSelection",
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: _vm.tableData.data,
                                size: "small",
                              },
                              on: {
                                "selection-change": _vm.handleSelectionChange,
                                "select-all": _vm.selectAll,
                                select: _vm.selectOne,
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { type: "selection", width: "55" },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { prop: "id", label: "ID", width: "55" },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { label: "产品图", "min-width": "80" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "demo-image__preview line-heightOne",
                                            },
                                            [
                                              _c("el-image", {
                                                attrs: {
                                                  src: scope.row.image,
                                                  "preview-src-list": [
                                                    scope.row.image,
                                                  ],
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1508102206
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  "show-overflow-tooltip": true,
                                  prop: "name",
                                  label: "产品名称",
                                  "min-width": "200",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "price",
                                  label: "售价",
                                  "min-width": "90",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "stock",
                                  label: "库存",
                                  "min-width": "70",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "操作",
                                  width: "140",
                                  fixed: "right",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "text",
                                                size: "small",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleDelete(
                                                    scope.$index,
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("删除")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1945863928
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.formValidate.method == 2
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "选择品牌：",
                            span: 24,
                            "label-width": "100px",
                            prop: "proBrandList",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "selWidth",
                              attrs: {
                                clearable: "",
                                filterable: "",
                                loading: _vm.loading,
                                remote: "",
                                multiple: "",
                                placeholder: "请选择品牌",
                              },
                              model: {
                                value: _vm.formValidate.proBrandList,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate,
                                    "proBrandList",
                                    $$v
                                  )
                                },
                                expression: "formValidate.proBrandList",
                              },
                            },
                            _vm._l(_vm.productBrand, function (v, i) {
                              return _c("el-option", {
                                key: i,
                                attrs: {
                                  label: v.name,
                                  value: v.id,
                                  disabled: !v.isShow,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.formValidate.method == 3
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "选择分类：",
                            span: 24,
                            "label-width": "100px",
                            prop: "proCategorylist",
                          },
                        },
                        [
                          _c("el-cascader", {
                            staticClass: "selWidth",
                            attrs: {
                              props: _vm.props,
                              options: _vm.adminProductClassify,
                              filterable: "",
                              placeholder: "",
                              "show-all-levels": false,
                              clearable: "",
                            },
                            model: {
                              value: _vm.formValidate.proCategorylist,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formValidate,
                                  "proCategorylist",
                                  $$v
                                )
                              },
                              expression: "formValidate.proCategorylist",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.formValidate.method == 4
                    ? _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: ["platform:merchant:page:list"],
                              expression: "['platform:merchant:page:list']",
                            },
                          ],
                          attrs: {
                            label: "选择商户：",
                            span: 24,
                            "label-width": "100px",
                            prop: "merIds",
                          },
                        },
                        [
                          _c("merchant-name", {
                            attrs: {
                              multiple: _vm.multiple,
                              merIdChecked: _vm.formValidate.merIds,
                            },
                            on: { getMerId: _vm.getMerId },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "fixed-card",
          attrs: { bordered: false, shadow: "never" },
        },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentTab == 1,
                  expression: "currentTab == 1",
                },
              ],
              attrs: { size: "small", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("formValidate", 1)
                },
              },
            },
            [_vm._v("下一步")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentTab == 2,
                  expression: "currentTab == 2",
                },
              ],
              staticClass: "priamry_border",
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  _vm.currentTab = "1"
                },
              },
            },
            [_vm._v("上一步")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentTab == 2,
                  expression: "currentTab == 2",
                },
              ],
              attrs: { size: "small", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("formValidate", 2)
                },
              },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }