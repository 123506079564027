var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-navbars-breadcrumb" },
    [
      _vm.collapseShow
        ? _c("i", {
            staticClass: "layout-navbars-breadcrumb-icon",
            class: _vm.getThemeConfig.isCollapse
              ? "el-icon-s-unfold"
              : "el-icon-s-fold",
            on: { click: _vm.onThemeConfigChange },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isShowcrumb
        ? _c(
            "el-breadcrumb",
            {
              staticClass: "layout-navbars-breadcrumb-hide",
              style: { display: _vm.isShowBreadcrumb },
            },
            [
              _c(
                "transition-group",
                { attrs: { name: "breadcrumb", mode: "out-in" } },
                _vm._l(
                  _vm.breadCrumbList.concat(_vm.crumbPast),
                  function (v, k) {
                    return _c("el-breadcrumb-item", { key: v.path }, [
                      k == 1
                        ? _c(
                            "span",
                            { staticClass: "layout-navbars-breadcrumb-span" },
                            [
                              _vm.getThemeConfig.isBreadcrumbIcon
                                ? _c("i", {
                                    staticClass:
                                      "ivu-icon layout-navbars-breadcrumb-iconfont",
                                    class: v.icon,
                                  })
                                : _vm._e(),
                              _vm._v(_vm._s(v.title) + "\n        "),
                            ]
                          )
                        : _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.onBreadcrumbClick(v)
                                },
                              },
                            },
                            [
                              _vm.getThemeConfig.isBreadcrumbIcon
                                ? _c("i", {
                                    staticClass:
                                      "ivu-icon layout-navbars-breadcrumb-iconfont",
                                    class: v.icon,
                                  })
                                : _vm._e(),
                              _vm._v(_vm._s(v.title) + "\n        "),
                            ]
                          ),
                    ])
                  }
                ),
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }