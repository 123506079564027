var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        {
          staticClass: "box-card",
          attrs: { shadow: "never", bordered: false },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "hasPermi",
                  rawName: "v-hasPermi",
                  value: ["platform:category:save"],
                  expression: "['platform:category:save']",
                },
              ],
              attrs: { inline: "" },
            },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handlerOpenAdd({ id: 0, name: "顶层目录" })
                        },
                      },
                    },
                    [_vm._v("添加分类")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-alert", {
            attrs: {
              title: "温馨提示",
              type: "warning",
              effect: "light",
              description:
                "添加一级分类以后，务必添加二级分类并配置表单，否则会出现渲染错误",
            },
          }),
          _vm._v(" "),
          _c(
            "el-table",
            {
              ref: "treeList",
              staticClass: "table mt20",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.treeList,
                "row-key": "id",
                size: "small",
                "highlight-current-row": "",
                "tree-props": { children: "child", hasChildren: "hasChildren" },
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "分类昵称", "min-width": "300" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " + _vm._s(scope.row.name) + "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "英文名称",
                  "show-overflow-tooltip": "",
                  "min-width": "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.url))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "已关联的表单",
                  "show-overflow-tooltip": "",
                  "min-width": "130",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.extra))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "启用状态", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("filterYesOrNo")(scope.row.status))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", width: "250", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.pid === 0 &&
                        _vm.checkPermi(["platform:category:save"])
                          ? [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handlerOpenAdd(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("添加子目录")]
                              ),
                              _vm._v(" "),
                              _c("el-divider", {
                                attrs: { direction: "vertical" },
                              }),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["platform:category:update"],
                                expression: "['platform:category:update']",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.handleEditMenu(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c("el-divider", { attrs: { direction: "vertical" } }),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["platform:category:list"],
                                expression: "['platform:category:list']",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.handlerOpenFormConfig(scope.row)
                              },
                            },
                          },
                          [_vm._v("配置列表")]
                        ),
                        _vm._v(" "),
                        _c("el-divider", { attrs: { direction: "vertical" } }),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["platform:category:delete"],
                                expression: "['platform:category:delete']",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.handleDelMenu(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-bottom",
          attrs: {
            title:
              _vm.editDialogConfig.isCreate === 0 ? "添加分类" : "编辑分类",
            visible: _vm.editDialogConfig.visible,
            "destroy-on-close": "",
            "close-on-click-modal": false,
            width: "700",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.editDialogConfig, "visible", $event)
            },
          },
        },
        [
          _vm.editDialogConfig.visible
            ? _c("edit", {
                attrs: {
                  prent: _vm.editDialogConfig.prent,
                  "is-create": _vm.editDialogConfig.isCreate,
                  "edit-data": _vm.editDialogConfig.data,
                  biztype: _vm.editDialogConfig.biztype,
                  "all-tree-list": _vm.treeList,
                },
                on: { hideEditDialog: _vm.hideEditDialog },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择已配置的表单",
            visible: _vm.configFormSelectedDialog.visible,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.configFormSelectedDialog, "visible", $event)
            },
          },
        },
        [
          _c("span", { staticClass: "color-red" }, [
            _vm._v("注意：表单不能重复关联"),
          ]),
          _vm._v(" "),
          _vm.configFormSelectedDialog.visible
            ? _c("form-config-list", {
                attrs: { "select-model": "" },
                on: { selectedRowData: _vm.handlerSelectedRowData },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form",
            [
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["platform:category:update"],
                      expression: "['platform:category:update']",
                    },
                  ],
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { type: "primary" },
                      on: { click: _vm.handlerAddFormExtra },
                    },
                    [_vm._v("关联")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }