var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _c(
        "el-card",
        {
          directives: [
            {
              name: "hasPermi",
              rawName: "v-hasPermi",
              value: ["platform:seckill:product:list"],
              expression: "['platform:seckill:product:list']",
            },
          ],
          staticClass: "ivu-mt",
          attrs: {
            bordered: false,
            shadow: "never",
            "body-style": { padding: 0 },
          },
        },
        [
          _c(
            "div",
            { staticClass: "padding-add" },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    size: "small",
                    "label-position": "right",
                    inline: "",
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品名称：" } },
                    [
                      _c("el-input", {
                        staticClass: "selWidth",
                        attrs: { placeholder: "请输入产品名称", clearable: "" },
                        model: {
                          value: _vm.proName,
                          callback: function ($$v) {
                            _vm.proName = $$v
                          },
                          expression: "proName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动名称：" } },
                    [
                      _c("el-input", {
                        staticClass: "selWidth",
                        attrs: { placeholder: "请输入活动名称", clearable: "" },
                        model: {
                          value: _vm.activityName,
                          callback: function ($$v) {
                            _vm.activityName = $$v
                          },
                          expression: "activityName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "inline", attrs: { label: "产品状态：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "selWidth",
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.tableFrom.proStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "proStatus", $$v)
                            },
                            expression: "tableFrom.proStatus",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "上架", value: 1 },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "下架", value: 0 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "inline", attrs: { label: "活动状态：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "selWidth",
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.tableFrom.activityStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "activityStatus", $$v)
                            },
                            expression: "tableFrom.activityStatus",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "进行中", value: 1 },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "已结束", value: 2 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商户名称：" } },
                    [
                      _c("merchant-name", {
                        attrs: { merIdChecked: _vm.merIds },
                        on: { getMerId: _vm.getMerId },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "inline", attrs: { label: "商户星级：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "selWidth",
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.tableFrom.merStars,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "merStars", $$v)
                            },
                            expression: "tableFrom.merStars",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "一星以上", value: 1 },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "二星以上", value: 2 },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "三星以上", value: 3 },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "四星以上", value: 4 },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "五星以上", value: 5 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.getList(1)
                            },
                          },
                        },
                        [_vm._v("查询")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.reset("tableFrom")
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card mt14",
          attrs: {
            "body-style": { padding: "0 20px 20px" },
            shadow: "never",
            bordered: false,
          },
        },
        [
          _c(
            "el-tabs",
            {
              staticClass: "list-tabs",
              on: {
                "tab-click": function ($event) {
                  return _vm.getList(1)
                },
              },
              model: {
                value: _vm.tableFrom.auditStatus,
                callback: function ($$v) {
                  _vm.$set(_vm.tableFrom, "auditStatus", $$v)
                },
                expression: "tableFrom.auditStatus",
              },
            },
            _vm._l(_vm.headeNum, function (item, index) {
              return _c("el-tab-pane", {
                key: index,
                attrs: { label: item.name, name: item.type },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "acea-row mt5" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["platform:seckill:product:delete"],
                      expression: "['platform:seckill:product:delete']",
                    },
                  ],
                  attrs: {
                    size: "small",
                    disabled: !_vm.multipleSelection.length,
                  },
                  on: { click: _vm.batchDel },
                },
                [_vm._v("批量删除")]
              ),
              _vm._v(" "),
              _vm.tableFrom.auditStatus === "2" &&
              _vm.checkPermi(["platform:seckill:product:down"])
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        disabled: !_vm.multipleSelection.length,
                      },
                      on: { click: _vm.batchDown },
                    },
                    [_vm._v("批量下架")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "multipleTable",
              staticClass: "mt20 tableSelection",
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "row-key": "id",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  "reserve-selection": true,
                  width: "45",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", "min-width": "45" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "产品图", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview line-heightOne" },
                          [
                            _c("el-image", {
                              attrs: {
                                src: scope.row.image,
                                "preview-src-list": [scope.row.image],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "产品名称",
                  prop: "name",
                  "min-width": "180",
                  "show-overflow-tooltip": true,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "categoryName",
                  label: "产品分类",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "merName",
                  "show-overflow-tooltip": true,
                  label: "商户名称",
                  "min-width": "130",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "activityName",
                  label: "活动名称",
                  "min-width": "150",
                  "show-overflow-tooltip": true,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "price", label: "产品售价", "min-width": "90" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "seckillPrice",
                  label: "活动价",
                  "min-width": "90",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "商户星级", "min-width": "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-rate", {
                          attrs: { disabled: "" },
                          model: {
                            value: scope.row.merStarLevel,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "merStarLevel", $$v)
                            },
                            expression: "scope.row.merStarLevel",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "sort", label: "排序", "min-width": "60" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "产品状态", fixed: "right", "min-width": "70" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.isShow
                          ? _c("el-tag", [_vm._v("上架")])
                          : _c("el-tag", [_vm._v("下架")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "活动状态", fixed: "right", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.activityStatus === 0
                          ? _c(
                              "el-tag",
                              { staticClass: "notStartTag tag-background" },
                              [_vm._v("未开始")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.activityStatus === 1
                          ? _c(
                              "el-tag",
                              { staticClass: "doingTag tag-background" },
                              [_vm._v("进行中")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.activityStatus === 2
                          ? _c(
                              "el-tag",
                              { staticClass: "endTag tag-background" },
                              [_vm._v("已结束")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "审核状态", fixed: "right", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.auditStatus === 1
                          ? _c(
                              "el-tag",
                              { staticClass: "doingTag tag-background" },
                              [_vm._v("待审核")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.auditStatus === 2
                          ? _c(
                              "el-tag",
                              { staticClass: "endTag tag-background" },
                              [_vm._v("审核成功")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.auditStatus === 3
                          ? _c(
                              "el-tag",
                              { staticClass: "notStartTag tag-background" },
                              [_vm._v("审核失败")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _vm.tableFrom.auditStatus === "3"
                ? _c("el-table-column", {
                    attrs: {
                      prop: "reason",
                      label: "失败原因",
                      fixed: "right",
                      "min-width": "120",
                      "show-overflow-tooltip": true,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", width: "190", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.checkPermi(["platform:seckill:product:list"])
                          ? _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.handleAudit(scope.row, 2)
                                  },
                                },
                              },
                              [_vm._v("详情")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("el-divider", { attrs: { direction: "vertical" } }),
                        _vm._v(" "),
                        _vm.tableFrom.auditStatus === "2" &&
                        _vm.checkPermi(["platform:seckill:product:list"])
                          ? [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleAudit(scope.row, 3)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                              _vm._v(" "),
                              _c("el-divider", {
                                attrs: { direction: "vertical" },
                              }),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.tableFrom.auditStatus === "1" &&
                        _vm.checkPermi([
                          "platform:seckill:time:interval:delete",
                        ])
                          ? [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleAudit(scope.row, 1)
                                    },
                                  },
                                },
                                [_vm._v("审核")]
                              ),
                              _vm._v(" "),
                              _c("el-divider", {
                                attrs: { direction: "vertical" },
                              }),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.tableFrom.auditStatus === "2" &&
                        scope.row.isShow &&
                        _vm.checkPermi(["platform:seckill:product:down"])
                          ? [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDown(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("下架")]
                              ),
                              _vm._v(" "),
                              _c("el-divider", {
                                attrs: { direction: "vertical" },
                              }),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.checkPermi(["platform:seckill:product:delete"])
                          ? [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDelete(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            "custom-class": "demo-drawer",
            direction: "rtl",
            visible: _vm.dialogVisible,
            size: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: function ($event) {
              return _vm.close("ruleForm")
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
            },
            [
              _c("div", { staticClass: "detailHead" }, [
                _c("div", { staticClass: "acea-row row-between headerBox" }, [
                  _c("div", { staticClass: "full" }, [
                    _c("img", {
                      staticClass: "order_icon",
                      attrs: { src: _vm.seckillInfo.image, alt: "" },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.seckillInfo.name)),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", { staticClass: "mr20" }, [
                          _vm._v("产品ID：" + _vm._s(_vm.seckillInfo.id)),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.isShow === 1
                    ? _c(
                        "div",
                        { staticClass: "acea-row justify-content" },
                        [
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "hasPermi",
                                  rawName: "v-hasPermi",
                                  value: ["platform:seckill:product:audit"],
                                  expression:
                                    "['platform:seckill:product:audit']",
                                },
                                {
                                  name: "debounceClick",
                                  rawName: "v-debounceClick",
                                  value: function () {
                                    _vm.onSubmit("fail")
                                  },
                                  expression:
                                    "\n                () => {\n                  onSubmit('fail');\n                }\n              ",
                                },
                              ],
                              staticStyle: { "margin-left": "0" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.loadingBtn ? "提交中 ..." : "审核拒绝"
                                )
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "hasPermi",
                                  rawName: "v-hasPermi",
                                  value: ["platform:seckill:product:audit"],
                                  expression:
                                    "['platform:seckill:product:audit']",
                                },
                                {
                                  name: "debounceClick",
                                  rawName: "v-debounceClick",
                                  value: function () {
                                    _vm.onSubmit("success")
                                  },
                                  expression:
                                    "\n                () => {\n                  onSubmit('success');\n                }\n              ",
                                },
                              ],
                              attrs: { type: "primary" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.loadingBtn ? "提交中 ..." : "审核通过"
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "detailSection padBox" }, [
                _c("div", { staticClass: "title" }, [_vm._v("活动信息")]),
                _vm._v(" "),
                _c("ul", { staticClass: "list" }, [
                  _c("li", { staticClass: "item" }, [
                    _c("div", { staticClass: "tips" }, [_vm._v("活动名称：")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.seckillInfo.activityName)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "item" }, [
                    _c("div", { staticClass: "tips" }, [_vm._v("活动状态：")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "value color-warning" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("activityStatusFilter")(
                            _vm.seckillInfo.activityStatus
                          )
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "item" }, [
                    _c("div", { staticClass: "tips" }, [_vm._v("审核状态：")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "value color-warning" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.seckillInfo.auditStatus == 1
                              ? "待审核"
                              : _vm.seckillInfo.auditStatus == 2
                              ? "审核通过"
                              : "审核失败"
                          ) +
                          "\n            "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "item" }, [
                    _c("div", { staticClass: "tips" }, [_vm._v("产品分类：")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.seckillInfo.categoryName)),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "detailSection padBox" }, [
                _c("div", { staticClass: "title" }, [_vm._v("商户信息")]),
                _vm._v(" "),
                _c("ul", { staticClass: "list" }, [
                  _c("li", { staticClass: "item" }, [
                    _c("div", { staticClass: "tips" }, [_vm._v("商户名称：")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.seckillInfo.merName)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "item" }, [
                    _c("div", { staticClass: "tips" }, [_vm._v("商户星级：")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "value" },
                      [
                        _c("el-rate", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.seckillInfo.merStarLevel,
                            callback: function ($$v) {
                              _vm.$set(_vm.seckillInfo, "merStarLevel", $$v)
                            },
                            expression: "seckillInfo.merStarLevel",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "detailSection padBox" }, [
                _c("div", { staticClass: "title" }, [_vm._v("产品信息")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "value w100" },
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.listLoading,
                            expression: "listLoading",
                          },
                        ],
                        ref: "tableList",
                        staticStyle: { width: "100%" },
                        attrs: {
                          "row-key": "id",
                          data: _vm.seckillInfo.attrValue,
                          size: "mini",
                          border: "",
                          "default-expand-all": "",
                          "tree-props": { children: "children" },
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: { "min-width": "140", label: "产品信息" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", { staticClass: "acea-row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "demo-image__preview mr10 line-heightOne",
                                      },
                                      [
                                        _c("el-image", {
                                          attrs: {
                                            src: scope.row.image,
                                            "preview-src-list": [
                                              scope.row.image,
                                            ],
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "row_title line2" },
                                      [_vm._v(_vm._s(scope.row.sku))]
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "otPrice",
                            label: "售价",
                            width: "120",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "quotaShow",
                            label: "限量",
                            width: "80",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "quota",
                            label: "限量剩余",
                            width: "120",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "price",
                            label: "活动价格",
                            "min-width": "120",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input-number", {
                                    staticClass: "input_width",
                                    attrs: {
                                      disabled: _vm.isShow < 3,
                                      type: "number",
                                      precision: 2,
                                      min: 0,
                                      max: 99999,
                                      controls: false,
                                    },
                                    model: {
                                      value: scope.row.price,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "price", $$v)
                                      },
                                      expression: "scope.row.price",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isShow === 3,
                        expression: "isShow === 3",
                      },
                    ],
                    staticClass: "mt20 acea-row justify-content",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "hasPermi",
                            rawName: "v-hasPermi",
                            value: ["platform:seckill:product:price"],
                            expression: "['platform:seckill:product:price']",
                          },
                        ],
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.setPrice(
                              _vm.seckillInfo.id,
                              _vm.seckillInfo.attrValue
                            )
                          },
                        },
                      },
                      [_vm._v("保存活动价")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }