var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        {
          directives: [
            {
              name: "hasPermi",
              rawName: "v-hasPermi",
              value: ["platform:system:form:list"],
              expression: "['platform:system:form:list']",
            },
          ],
          staticClass: "box-card",
          attrs: {
            "body-style": { padding: "0 0 20px" },
            bordered: false,
            shadow: "never",
          },
        },
        [
          _c(
            "div",
            { staticClass: "padding-add" },
            [
              _c(
                "el-form",
                {
                  attrs: { inline: "", size: "small" },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "关键字：" } },
                    [
                      _c("el-input", {
                        staticClass: "selWidth",
                        attrs: {
                          placeholder: "请输入id，名称，描述",
                          clearable: "",
                          size: "small",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handlerSearch($event)
                          },
                        },
                        model: {
                          value: _vm.listPram.keywords,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.listPram,
                              "keywords",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "listPram.keywords",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "search-form-sub" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.handlerSearch },
                        },
                        [_vm._v("搜索")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card mt14",
          attrs: {
            "body-style": { padding: "20px 20px 30px" },
            shadow: "never",
            bordered: false,
          },
        },
        [
          _c(
            "div",
            { staticClass: "mb20" },
            [
              !_vm.selectModel && _vm.checkPermi(["platform:system:form:save"])
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handlerEditData({}, 0)
                        },
                      },
                    },
                    [_vm._v("创建表单")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.selectModel
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        disabled: !_vm.selectedConfigData.id,
                      },
                      on: { click: _vm.handlerConfimSelect },
                    },
                    [_vm._v("确定选择")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticClass: "table",
              attrs: {
                data: _vm.dataList.list,
                "highlight-current-row": "",
                "row-key": "id",
                size: "small",
              },
              on: { "current-change": _vm.handleCurrentRowChange },
            },
            [
              _c("el-table-column", {
                attrs: { label: "ID", prop: "id", width: "80" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "名称", prop: "name", "min-width": "180" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "描述", prop: "info", "min-width": "220" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "更新时间",
                  prop: "updateTime",
                  "min-width": "200",
                },
              }),
              _vm._v(" "),
              !_vm.selectModel &&
              _vm.checkPermi(["platform:system:form:update"])
                ? _c("el-table-column", {
                    attrs: { label: "操作", width: "70", fixed: "right" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handlerEditData(scope.row, 1)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2529158851
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.listPram.page,
              "page-sizes": [10, 20, 30, 40],
              layout: _vm.constants.page.layout,
              total: _vm.dataList.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.editDialogConfig.visible,
            fullscreen: "",
            title:
              _vm.editDialogConfig.isCreate === 0 ? "创建表单" : "编辑表单",
            "destroy-on-close": "",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.editDialogConfig, "visible", $event)
            },
          },
        },
        [
          _vm.editDialogConfig.visible
            ? _c("edit", {
                attrs: {
                  "is-create": _vm.editDialogConfig.isCreate,
                  "edit-data": _vm.editDialogConfig.editData,
                },
                on: { hideDialog: _vm.handlerHide },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }