"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.applyAudit = applyAudit;
exports.applyInfo = applyInfo;
exports.applyList = applyList;
exports.applyMerchantAssign = applyMerchantAssign;
exports.applyUpdate = applyUpdate;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * 审核详情
 * @param prams
 */
function applyInfo(params) {
  return (0, _request.default)({
    url: '/admin/platform/user/register/apply/info',
    method: 'get',
    params: params
  });
}
/**
 * 审核列表
 * @param prams
 */
function applyList(params) {
  return (0, _request.default)({
    url: '/admin/platform/user/register/apply/list',
    method: 'get',
    params: params
  });
}
/**
 * 审核
 * @param prams
 */
function applyAudit(data) {
  return (0, _request.default)({
    url: '/admin/platform/user/register/apply/audit',
    method: 'post',
    data: data
  });
}
/**
 * 分配商户
 * @param prams
 */
function applyMerchantAssign(data) {
  return (0, _request.default)({
    url: '/admin/platform/user/register/apply/merchant/assign',
    method: 'post',
    data: data
  });
}
/**
 * 编辑
 * @param prams
 */
function applyUpdate(data) {
  return (0, _request.default)({
    url: '/admin/platform/user/register/apply/update',
    method: 'post',
    data: data
  });
}