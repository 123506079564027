var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.tagsViewList.length > 0
    ? _c(
        "div",
        { ref: "tagsView", staticClass: "layout-navbars-tagsview" },
        [
          _vm.scrollTagIcon
            ? _c("i", {
                staticClass: "direction el-icon-arrow-left",
                on: {
                  click: function ($event) {
                    return _vm.scrollTag("left")
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-scrollbar",
            {
              ref: "scrollbarRef",
              staticStyle: { "margin-top": "0" },
              nativeOn: {
                wheel: function ($event) {
                  $event.preventDefault()
                  return _vm.onHandleScroll($event)
                },
              },
            },
            [
              _c(
                "ul",
                {
                  ref: "tagsUlRef",
                  staticClass: "layout-navbars-tagsview-ul",
                  class: _vm.setTagsStyle,
                },
                _vm._l(_vm.tagsViewList, function (v, k) {
                  return _c(
                    "li",
                    {
                      key: k,
                      ref: "tagsRefs",
                      refInFor: true,
                      staticClass: "layout-navbars-tagsview-ul-li",
                      class: { "is-active": v.path === _vm.tagsRoutePath },
                      attrs: { "data-name": v.name },
                      on: {
                        contextmenu: function ($event) {
                          $event.preventDefault()
                          return _vm.onContextmenu(v, $event)
                        },
                        click: function ($event) {
                          return _vm.onTagsClick(v, k)
                        },
                      },
                    },
                    [
                      v.path !== _vm.tagsRoutePath &&
                      _vm.getThemeConfig.isTagsviewIcon
                        ? _c("i", {
                            staticClass:
                              "layout-navbars-tagsview-ul-li-iconfont font14 is-tagsview-icon",
                            class: v.icon,
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(v.meta.title))]),
                      _vm._v(" "),
                      !_vm.isAffix(v)
                        ? _c("i", {
                            staticClass:
                              "el-icon-close layout-navbars-tagsview-ul-li-icon ml5",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.closeCurrentTagsView(v.path)
                              },
                            },
                          })
                        : _vm._e(),
                    ]
                  )
                }),
                0
              ),
            ]
          ),
          _vm._v(" "),
          _vm.scrollTagIcon
            ? _c("i", {
                staticClass: "direction el-icon-arrow-right",
                on: {
                  click: function ($event) {
                    return _vm.scrollTag("right")
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.tagsViewList.length
            ? _c(
                "el-dropdown",
                { on: { command: _vm.clickDropdown } },
                [
                  _c("span", { staticClass: "setting-tag el-dropdown-link" }, [
                    _c("i", { staticClass: "el-icon-menu" }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    _vm._l(_vm.dropdownList, function (item) {
                      return _c(
                        "el-dropdown-item",
                        { key: item.id, attrs: { command: item.id } },
                        [
                          _c("i", { class: item.icon }),
                          _vm._v("\n        " + _vm._s(item.txt)),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("Contextmenu", {
            ref: "tagsContextmenu",
            attrs: { dropdown: _vm.tagsDropdown },
            on: { currentContextmenuClick: _vm.onCurrentContextmenuClick },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }