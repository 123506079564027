var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.iframeLoading,
            expression: "iframeLoading",
          },
        ],
        staticClass: "layout-view-bg-white flex h100",
      },
      [
        _c("iframe", {
          attrs: {
            src: _vm.meta.isLink,
            frameborder: "0",
            height: "100%",
            width: "100%",
            id: "iframe",
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }