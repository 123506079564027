var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "components-container" },
    [
      _c("div", { staticClass: "info" }, [
        _vm._v("配置订单产品数量范围，遵循数量越多，审批的机构越多规则。"),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        { attrs: { data: _vm.rules } },
        [
          _c("el-table-column", { attrs: { label: "序号", type: "index" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "数量上限" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.$index === 3
                      ? _c("el-input-number", {
                          attrs: { disabled: true },
                          model: {
                            value: _vm.rules[2].limitUpper,
                            callback: function ($$v) {
                              _vm.$set(_vm.rules[2], "limitUpper", $$v)
                            },
                            expression: "rules[2].limitUpper",
                          },
                        })
                      : _c("el-input-number", {
                          on: {
                            change: function ($event) {
                              return _vm.changeHandle($event, scope.$index)
                            },
                          },
                          model: {
                            value: scope.row.limitUpper,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "limitUpper", $$v)
                            },
                            expression: "scope.row.limitUpper",
                          },
                        }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "审批规则(数量)" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.$index == 0
                      ? [
                          _vm._v(
                            "\n          <= " +
                              _vm._s(scope.row.limitUpper) +
                              "\n        "
                          ),
                        ]
                      : scope.$index == 3
                      ? [
                          _vm._v(
                            "\n          > " +
                              _vm._s(scope.row.limitLower) +
                              "\n        "
                          ),
                        ]
                      : [
                          _vm._v(
                            "\n          > " +
                              _vm._s(scope.row.limitLower) +
                              " 且 <= " +
                              _vm._s(scope.row.limitUpper) +
                              "\n        "
                          ),
                        ],
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "审批级别", prop: "remark" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-button",
        {
          staticStyle: { "margin-top": "10px" },
          attrs: { type: "primary", size: "mini" },
          on: { click: _vm.update },
        },
        [_vm._v("保存")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }