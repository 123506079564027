var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _c(
        "el-card",
        {
          directives: [
            {
              name: "hasPermi",
              rawName: "v-hasPermi",
              value: ["platform:product:page:list"],
              expression: "['platform:product:page:list']",
            },
          ],
          staticClass: "ivu-mt",
          attrs: {
            bordered: false,
            shadow: "never",
            "body-style": { padding: 0 },
          },
        },
        [
          _c(
            "div",
            { staticClass: "padding-add" },
            [
              _c(
                "el-form",
                {
                  attrs: { inline: "", "label-position": "right" },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品分类：" } },
                    [
                      _c("el-cascader", {
                        ref: "cascader",
                        staticClass: "selWidth",
                        attrs: {
                          clearable: "",
                          options: _vm.adminProductClassify,
                          props: _vm.categoryProps,
                          size: "small",
                        },
                        on: { change: _vm.handleSeachList },
                        model: {
                          value: _vm.tableFrom.categoryId,
                          callback: function ($$v) {
                            _vm.$set(_vm.tableFrom, "categoryId", $$v)
                          },
                          expression: "tableFrom.categoryId",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商户类别：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "selWidth",
                          attrs: {
                            clearable: "",
                            size: "small",
                            placeholder: "请选择",
                          },
                          on: { change: _vm.handleSeachList },
                          model: {
                            value: _vm.tableFrom.isSelf,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "isSelf", $$v)
                            },
                            expression: "tableFrom.isSelf",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "自营", value: 1 },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "非自营", value: 0 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商户名称：" } },
                    [
                      _c("merchant-name", {
                        attrs: { merIdChecked: _vm.tableFrom.merId },
                        on: { getMerId: _vm.getMerId },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品搜索：" } },
                    [
                      _c("el-input", {
                        staticClass: "selWidth",
                        attrs: {
                          placeholder: "请输入产品名称，关键字",
                          size: "small",
                          clearable: "",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleSeachList($event)
                          },
                        },
                        model: {
                          value: _vm.keywords,
                          callback: function ($$v) {
                            _vm.keywords = $$v
                          },
                          expression: "keywords",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: ["platform:product:page:list"],
                              expression: "['platform:product:page:list']",
                            },
                          ],
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.handleSeachList },
                        },
                        [_vm._v("查询")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        { attrs: { size: "small" }, on: { click: _vm.reset } },
                        [_vm._v("重置")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card mt14",
          attrs: {
            "body-style": { padding: "0 20px 20px" },
            shadow: "never",
            bordered: false,
          },
        },
        [
          _c(
            "div",
            { ref: "headerBox", staticClass: "clearfix" },
            [
              _c(
                "el-tabs",
                {
                  staticClass: "list-tabs mb5",
                  on: { "tab-click": _vm.handleSeachList },
                  model: {
                    value: _vm.tableFrom.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "type", $$v)
                    },
                    expression: "tableFrom.type",
                  },
                },
                _vm._l(_vm.headeNum, function (item, index) {
                  return _c("el-tab-pane", {
                    key: index,
                    attrs: {
                      label: item.name + "(" + item.count + ")",
                      name: item.type.toString(),
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "acea-row mt5" },
            [
              _vm.tableFrom.type === _vm.ProductTypeEnum.OnSale &&
              _vm.checkPermi(["platform:product:force:down"])
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        disabled: !_vm.multipleSelection.length,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.handlebatchOff()
                        },
                      },
                    },
                    [_vm._v("强制下架")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.tableFrom.type === _vm.ProductTypeEnum.Audit &&
              _vm.checkPermi(["platform:product:batch:audit"])
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        disabled: !_vm.multipleSelection.length,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.handlebatchAudit($event)
                        },
                      },
                    },
                    [_vm._v("批量审核")]
                  )
                : _vm._e(),
              _vm._v(" "),
              (Number(_vm.tableFrom.type) < 4 ||
                Number(_vm.tableFrom.type) === 6) &&
              _vm.checkPermi(["platform:product:batch:set:virtual:sales"])
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        disabled: !_vm.multipleSelection.length,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.handleBatchSales()
                        },
                      },
                    },
                    [_vm._v("增加其他销量")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "mt20 tableSelection",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "highlight-current-row": true,
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              Number(_vm.tableFrom.type) < 3 ||
              _vm.tableFrom.type === _vm.ProductTypeEnum.Audit
                ? _c("el-table-column", {
                    key: "2",
                    attrs: { type: "selection", width: "55" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { type: "expand", width: "40" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "el-form",
                          {
                            staticClass: "demo-table-expand",
                            attrs: { "label-position": "left", inline: "" },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "商户类别：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("selfTypeFilter")(props.row.isSelf)
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "其他销量：" } },
                              [_c("span", [_vm._v(_vm._s(props.row.ficti))])]
                            ),
                            _vm._v(" "),
                            _vm.tableFrom.type == 7
                              ? _c(
                                  "el-form-item",
                                  { attrs: { label: "拒绝原因：" } },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(props.row.reason)),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _vm.checkedCities.includes("ID")
                ? _c("el-table-column", {
                    attrs: { prop: "id", label: "ID", width: "50" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("产品图")
                ? _c("el-table-column", {
                    attrs: { label: "产品图", width: "80" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "demo-image__preview line-heightOne",
                                },
                                [
                                  _c("el-image", {
                                    attrs: {
                                      src: scope.row.image,
                                      "preview-src-list": [scope.row.image],
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1508102206
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("产品名称")
                ? _c("el-table-column", {
                    attrs: {
                      label: "产品名称",
                      prop: "name",
                      "min-width": "180",
                      "show-overflow-tooltip": true,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("产品售价")
                ? _c("el-table-column", {
                    attrs: {
                      label: "产品售价",
                      prop: "price",
                      "min-width": "100",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("商户名称")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "merchantName",
                      label: "商户名称",
                      "min-width": "180",
                      "show-overflow-tooltip": true,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("商户类别")
                ? _c("el-table-column", {
                    attrs: { label: "商户类别", "min-width": "100" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("selfTypeFilter")(scope.row.isSelf)
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      611084893
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("销量")
                ? _c("el-table-column", {
                    attrs: { prop: "sales", label: "销量", "min-width": "100" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("库存")
                ? _c("el-table-column", {
                    attrs: { prop: "stock", label: "库存", "min-width": "70" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("审核状态")
                ? _c("el-table-column", {
                    attrs: {
                      label: "审核状态",
                      "min-width": "80",
                      fixed: "right",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("auditStatusFilter")(
                                      scope.row.auditStatus
                                    )
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4246894879
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "190px",
                  fixed: "right",
                  "scoped-slot": _vm.renderHeader,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["platform:product:info"],
                                expression: "['platform:product:info']",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.handleAudit(scope.row.id, false)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                        _vm._v(" "),
                        _vm.tableFrom.type === "1"
                          ? [
                              _c("el-divider", {
                                attrs: { direction: "vertical" },
                              }),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handlePreview(scope.row.id)
                                    },
                                  },
                                },
                                [_vm._v("预览")]
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.tableFrom.type === "6" &&
                        _vm.checkPermi(["platform:product:audit"])
                          ? [
                              _c("el-divider", {
                                attrs: { direction: "vertical" },
                              }),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleAudit(scope.row.id, true)
                                    },
                                  },
                                },
                                [_vm._v("审核")]
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        Number(_vm.tableFrom.type) < 7 &&
                        _vm.checkPermi(["platform:product:update"])
                          ? [
                              _c("el-divider", {
                                attrs: { direction: "vertical" },
                              }),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleEdit(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        Number(_vm.tableFrom.type) < 2 &&
                        _vm.checkPermi(["platform:product:force:down"])
                          ? [
                              _c("el-divider", {
                                attrs: { direction: "vertical" },
                              }),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleOff(scope.row.id)
                                    },
                                  },
                                },
                                [_vm._v("下架")]
                              ),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.card_select_show,
              expression: "card_select_show",
            },
          ],
          staticClass: "card_abs",
        },
        [
          [
            _c(
              "div",
              { staticClass: "cell_ht" },
              [
                _c(
                  "el-checkbox",
                  {
                    attrs: { indeterminate: _vm.isIndeterminate },
                    on: { change: _vm.handleCheckAllChange },
                    model: {
                      value: _vm.checkAll,
                      callback: function ($$v) {
                        _vm.checkAll = $$v
                      },
                      expression: "checkAll",
                    },
                  },
                  [_vm._v("全选")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.checkSave()
                      },
                    },
                  },
                  [_vm._v("保存")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-checkbox-group",
              {
                on: { change: _vm.handleCheckedCitiesChange },
                model: {
                  value: _vm.checkedCities,
                  callback: function ($$v) {
                    _vm.checkedCities = $$v
                  },
                  expression: "checkedCities",
                },
              },
              _vm._l(_vm.columnData, function (item) {
                return _c(
                  "el-checkbox",
                  {
                    key: item,
                    staticClass: "check_cell",
                    attrs: { label: item },
                  },
                  [_vm._v(_vm._s(item))]
                )
              }),
              1
            ),
          ],
        ],
        2
      ),
      _vm._v(" "),
      _c("info-from", {
        ref: "infoFrom",
        attrs: {
          componentKey: _vm.componentKey,
          "is-atud": _vm.isAtud,
          "is-show": _vm.isShow,
          productId: _vm.productId,
        },
        on: { subSuccess: _vm.subSuccess },
      }),
      _vm._v(" "),
      _vm.previewVisible
        ? _c(
            "div",
            [
              _c("div", {
                staticClass: "bg",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.previewVisible = false
                  },
                },
              }),
              _vm._v(" "),
              _vm.previewVisible
                ? _c("preview-box", {
                    ref: "previewBox",
                    attrs: { frontDomainUrl: _vm.frontDomainUrl },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "增加其他销量",
            visible: _vm.dialogVisible,
            width: "540px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formData",
              staticClass: "demo-ruleForm",
              attrs: { model: _vm.formData, "label-width": "100px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "增加其他销量：", required: "" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 0, max: 99999 },
                    model: {
                      value: _vm.formData.ficti,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formData,
                          "ficti",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formData.ficti",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["platform:express:update"],
                      expression: "['platform:express:update']",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("formData")
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("batch-audit", {
        ref: "refBatchAudit",
        attrs: { idList: _vm.checkedIds },
        on: { subBatchAuditSuccess: _vm.subBatchAuditSuccess },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }