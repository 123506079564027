var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        {
          staticClass: "box-card",
          attrs: {
            "body-style": { padding: "40px 50px" },
            shadow: "never",
            bordered: false,
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.fullscreenLoading,
                  expression: "fullscreenLoading",
                },
              ],
              ref: "formValidate",
              staticClass: "formValidate",
              attrs: {
                rules: _vm.ruleValidate,
                model: _vm.formValidate,
                "label-width": "180px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "PC商城左上角logo(100*36)：",
                    prop: "leftTopLogo",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upLoadPicBox",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap("1", "leftTopLogo")
                        },
                      },
                    },
                    [
                      _vm.formValidate.leftTopLogo
                        ? _c("div", { staticClass: "pictrue" }, [
                            _c("img", {
                              attrs: { src: _vm.formValidate.leftTopLogo },
                            }),
                          ])
                        : _c("div", { staticClass: "upLoad" }, [
                            _c("i", {
                              staticClass: "el-icon-camera cameraIconfont",
                            }),
                          ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "品牌好店广告图(288*422)：",
                    prop: "goodStoreImage",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upLoadPicBox",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap("1", "goodStoreImage")
                        },
                      },
                    },
                    [
                      _vm.formValidate.goodStoreImage
                        ? _c("div", { staticClass: "pictrue" }, [
                            _c("img", {
                              attrs: { src: _vm.formValidate.goodStoreImage },
                            }),
                          ])
                        : _c("div", { staticClass: "upLoad" }, [
                            _c("i", {
                              staticClass: "el-icon-camera cameraIconfont",
                            }),
                          ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "手机体验：" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.formValidate.goPhoneQrCodeTypeList,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formValidate,
                            "goPhoneQrCodeTypeList",
                            $$v
                          )
                        },
                        expression: "formValidate.goPhoneQrCodeTypeList",
                      },
                    },
                    [
                      _c("el-checkbox", { attrs: { label: "1" } }, [
                        _vm._v("小程序"),
                      ]),
                      _vm._v(" "),
                      _c("el-checkbox", { attrs: { label: "2" } }, [
                        _vm._v("公众号/H5"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["platform:pc:shopping:base:config:edit"],
                      expression: "['platform:pc:shopping:base:config:edit']",
                    },
                  ],
                },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "debounceClick",
                          rawName: "v-debounceClick",
                          value: function () {
                            _vm.handleBaseConfigSave("formValidate")
                          },
                          expression:
                            "() => {\n            handleBaseConfigSave('formValidate');\n          }\n          ",
                        },
                      ],
                      attrs: { size: "small", type: "primary" },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }