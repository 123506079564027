var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      [
        _c(
          "div",
          { staticClass: "divBox" },
          [
            _c(
              "el-card",
              {
                staticClass: "box-card",
                attrs: { bordered: false, shadow: "never" },
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "hasPermi",
                        rawName: "v-hasPermi",
                        value: [
                          "platform:product:category:add",
                          "platform:article:category:add",
                        ],
                        expression:
                          "['platform:product:category:add', 'platform:article:category:add']",
                      },
                    ],
                    staticClass: "clearfix acea-row",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.handleAddMenu({
                              id: 0,
                              name: "顶层目录",
                            })
                          },
                        },
                      },
                      [
                        _vm._v(
                          "新增" + _vm._s(_vm.biztype.name) + "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("el-alert", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.biztype.value === 1,
                          expression: "biztype.value === 1",
                        },
                      ],
                      staticClass: "w100 mt20",
                      attrs: {
                        title: "平台分类必须要设置三级分类",
                        type: "warning",
                        effect: "light",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    ref: "treeList",
                    staticClass: "table mt20",
                    attrs: {
                      data: _vm.dataList,
                      size: "mini",
                      "highlight-current-row": "",
                      "row-key": "id",
                      "tree-props": {
                        children: "children",
                        hasChildren: "hasChildren",
                      },
                    },
                  },
                  [
                    _vm.biztype.value === 1
                      ? _c("el-table-column", {
                          attrs: {
                            prop: "name",
                            label: "名称",
                            "min-width": "200",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(scope.row.name) +
                                        " | " +
                                        _vm._s(scope.row.id)
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3778547117
                          ),
                        })
                      : _c("el-table-column", {
                          attrs: {
                            prop: "name",
                            label: "名称",
                            "min-width": "120",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(scope.row.name) +
                                      " | " +
                                      _vm._s(scope.row.id)
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                    _vm._v(" "),
                    !_vm.selectModel
                      ? [
                          _c("el-table-column", {
                            attrs: { label: "分类图标", "min-width": "120" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "demo-image__preview line-heightOne",
                                        },
                                        [
                                          scope.row.icon
                                            ? _c("el-image", {
                                                attrs: {
                                                  src: scope.row.icon,
                                                  "preview-src-list": [
                                                    scope.row.icon,
                                                  ],
                                                },
                                              })
                                            : _c("img", {
                                                attrs: {
                                                  src: _vm.defaultImg,
                                                  alt: "",
                                                },
                                              }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1102933446
                            ),
                          }),
                          _vm._v(" "),
                          _vm.biztype.value === 5
                            ? _c("el-table-column", {
                                key: "2",
                                attrs: { label: "Url", "min-width": "250" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(_vm._s(scope.row.url)),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3700262509
                                ),
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "排序",
                              prop: "sort",
                              "min-width": "120",
                            },
                          }),
                          _vm._v(" "),
                          _vm.biztype.value === 2
                            ? _c("el-table-column", {
                                attrs: {
                                  label: "状态",
                                  "min-width": "80",
                                  fixed: "right",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm.checkPermi([
                                            "platform:article:category:switch",
                                          ])
                                            ? _c("el-switch", {
                                                attrs: {
                                                  "active-value": true,
                                                  "inactive-value": false,
                                                  "active-text": "显示",
                                                  "inactive-text": "隐藏",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.onchangeIsShow(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: scope.row.status,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "status",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.status",
                                                },
                                              })
                                            : _c("div", [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row.status
                                                      ? "显示"
                                                      : "隐藏"
                                                  )
                                                ),
                                              ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3769223080
                                ),
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.biztype.value === 1
                            ? _c("el-table-column", {
                                attrs: {
                                  label: "状态",
                                  "min-width": "120",
                                  fixed: "right",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm.checkPermi([
                                            "platform:product:category:show:status",
                                          ])
                                            ? _c("el-switch", {
                                                attrs: {
                                                  "active-value": true,
                                                  "inactive-value": false,
                                                  "active-text": "显示",
                                                  "inactive-text": "隐藏",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.onchangeIsShow(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: scope.row.isShow,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "isShow",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.isShow",
                                                },
                                              })
                                            : _c("div", [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row.isShow
                                                      ? "显示"
                                                      : "隐藏"
                                                  )
                                                ),
                                              ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3078805368
                                ),
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              width: _vm.biztype.value === 1 ? 180 : 110,
                              fixed: "right",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm.biztype.value === 1 &&
                                      scope.row.level < 3 &&
                                      _vm.checkPermi([
                                        "platform:product:category:add",
                                        "platform:article:category:add",
                                      ])
                                        ? [
                                            _c(
                                              "a",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleAddMenu(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("添加子目录 ")]
                                            ),
                                            _vm._v(" "),
                                            _c("el-divider", {
                                              attrs: { direction: "vertical" },
                                            }),
                                          ]
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          directives: [
                                            {
                                              name: "hasPermi",
                                              rawName: "v-hasPermi",
                                              value: [
                                                "platform:product:category:update",
                                                "platform:article:category:update",
                                              ],
                                              expression:
                                                "['platform:product:category:update', 'platform:article:category:update']",
                                            },
                                          ],
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleEditMenu(
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("编辑\n                ")]
                                      ),
                                      _vm._v(" "),
                                      _c("el-divider", {
                                        attrs: { direction: "vertical" },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          directives: [
                                            {
                                              name: "hasPermi",
                                              rawName: "v-hasPermi",
                                              value: [
                                                "platform:product:category:delete",
                                                "platform:article:category:delete",
                                              ],
                                              expression:
                                                "['platform:product:category:delete', 'platform:article:category:delete']",
                                            },
                                          ],
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDelete(scope.row)
                                            },
                                          },
                                        },
                                        [_vm._v("删除\n                ")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3183036689
                            ),
                          }),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-bottom",
          attrs: {
            title:
              _vm.editDialogConfig.isCreate === 0
                ? "创建" + _vm.biztype.name
                : "编辑" + _vm.biztype.name,
            visible: _vm.editDialogConfig.visible,
            "destroy-on-close": "",
            "close-on-click-modal": false,
            width: "540px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.editDialogConfig, "visible", $event)
            },
          },
        },
        [
          _vm.editDialogConfig.visible
            ? _c("edit", {
                attrs: {
                  prent: _vm.editDialogConfig.prent,
                  "is-create": _vm.editDialogConfig.isCreate,
                  "edit-data": _vm.editDialogConfig.data,
                  biztype: _vm.editDialogConfig.biztype,
                  "all-tree-list": _vm.dataList,
                },
                on: { hideEditDialog: _vm.hideEditDialog },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }