"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'layoutTagsViewContextmenu',
  props: {
    dropdown: {
      type: Object
    }
  },
  data: function data() {
    return {
      isShow: false,
      dropdownList: [{
        id: 0,
        txt: '刷新',
        affix: false,
        icon: 'el-icon-refresh-right'
      }, {
        id: 1,
        txt: '关闭',
        affix: false,
        icon: 'el-icon-close'
      }, {
        id: 2,
        txt: '关闭其他',
        affix: false,
        icon: 'el-icon-circle-close'
      }, {
        id: 3,
        txt: '关闭全部',
        affix: false,
        icon: 'el-icon-folder-delete'
      }],
      path: {},
      arrowLeft: 5
    };
  },
  computed: {
    dropdowns: function dropdowns() {
      // 99 为 `Dropdown 下拉菜单` 的宽度
      if (this.dropdown.x + 99 > document.documentElement.clientWidth) {
        return {
          x: document.documentElement.clientWidth - 99 - 5,
          y: this.dropdown.y
        };
      } else {
        return this.dropdown;
      }
    }
  },
  mounted: function mounted() {
    // 监听页面监听进行右键菜单的关闭
    document.body.addEventListener('click', this.closeContextmenu);
  },
  methods: {
    // 当前项菜单点击
    onCurrentContextmenuClick: function onCurrentContextmenuClick(id) {
      this.$emit('currentContextmenuClick', {
        id: id,
        path: this.path
      });
    },
    // 打开右键菜单：判断是否固定，固定则不显示关闭按钮
    openContextmenu: function openContextmenu(item) {
      var _this = this;
      this.path = item.path;
      item.meta.isAffix ? this.dropdownList[1].affix = true : this.dropdownList[1].affix = false;
      this.closeContextmenu();
      setTimeout(function () {
        _this.isShow = true;
      }, 80);
    },
    // 关闭右键菜单
    closeContextmenu: function closeContextmenu() {
      this.isShow = false;
    }
  },
  destroyed: function destroyed() {
    // 页面卸载时，移除右键菜单监听事件
    document.body.removeEventListener('click', this.closeContextmenu);
  },
  // 监听下拉菜单位置
  watch: {
    dropdown: {
      handler: function handler(_ref) {
        var x = _ref.x;
        if (x + 99 > document.documentElement.clientWidth) this.arrowLeft = 99 - (document.documentElement.clientWidth - x);else this.arrowLeft = 10;
      },
      deep: true
    }
  }
};