var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "layout-container flex-center" },
    [
      _c("Headers"),
      _vm._v(" "),
      _c(
        "el-container",
        { staticClass: "layout-mian-height-50" },
        [
          _c("Asides"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-center layout-backtop" },
            [
              _vm.getThemeConfig.isTagsview ? _c("TagsView") : _vm._e(),
              _vm._v(" "),
              _c("Mains"),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-backtop", {
        attrs: { target: ".layout-backtop .el-main .el-scrollbar__wrap" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }