var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      [
        _c(
          "div",
          { staticClass: "divBox" },
          [
            _c(
              "el-card",
              {
                staticClass: "box-card",
                attrs: { bordered: false, shadow: "never" },
              },
              [
                _c(
                  "div",
                  { staticClass: "clearfix acea-row" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.handleAddMenu({
                              id: 0,
                              name: "顶层机构",
                            })
                          },
                        },
                      },
                      [_vm._v("新增机构\n          ")]
                    ),
                    _vm._v(" "),
                    _c("el-alert", {
                      staticClass: "w100 mt20",
                      attrs: {
                        title: "机构必须要设置四级机构",
                        type: "warning",
                        effect: "light",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    ref: "treeList",
                    staticClass: "table mt20",
                    attrs: {
                      data: _vm.dataList,
                      size: "mini",
                      "highlight-current-row": "",
                      "row-key": "id",
                      "tree-props": {
                        children: "children",
                        hasChildren: "hasChildren",
                      },
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        "show-overflow-tooltip": "",
                        prop: "name",
                        label: "机构名称",
                        "min-width": "240",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [_vm._v(" " + _vm._s(scope.row.name))]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    !_vm.selectModel
                      ? [
                          _c("el-table-column", {
                            attrs: {
                              "show-overflow-tooltip": "",
                              label: "编号",
                              prop: "identNumber",
                              "min-width": "120",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              "show-overflow-tooltip": "",
                              label: "地址",
                              prop: "address",
                              "min-width": "240",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              "show-overflow-tooltip": "",
                              label: "电话",
                              prop: "linkPhone",
                              "min-width": "120",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              "show-overflow-tooltip": "",
                              label: "联系人",
                              prop: "linkName",
                              "min-width": "120",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              "show-overflow-tooltip": "",
                              label: "排序",
                              prop: "sort",
                              "min-width": "120",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              width: "120",
                              fixed: "right",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "a",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleEditMenu(
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("编辑\n                ")]
                                      ),
                                      _vm._v(" "),
                                      _c("el-divider", {
                                        attrs: { direction: "vertical" },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDelete(scope.row)
                                            },
                                          },
                                        },
                                        [_vm._v("删除\n                ")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3201058734
                            ),
                          }),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-bottom",
          attrs: {
            title:
              _vm.editDialogConfig.isCreate === 0 ? "创建机构" : "编辑机构",
            visible: _vm.editDialogConfig.visible,
            "destroy-on-close": "",
            "close-on-click-modal": false,
            width: "540px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.editDialogConfig, "visible", $event)
            },
          },
        },
        [
          _vm.editDialogConfig.visible
            ? _c("edit", {
                attrs: {
                  prent: _vm.editDialogConfig.prent,
                  "is-create": _vm.editDialogConfig.isCreate,
                  "edit-data": _vm.editDialogConfig.data,
                  "all-tree-list": _vm.dataList,
                },
                on: { hideEditDialog: _vm.hideEditDialog },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }