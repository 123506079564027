var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        {
          staticClass: "box-card",
          attrs: { shadow: "never", bordered: false },
        },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "hasPermi",
                  rawName: "v-hasPermi",
                  value: ["platform:system:user:level:save"],
                  expression: "['platform:system:user:level:save']",
                },
              ],
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.add },
            },
            [_vm._v("添加用户等级")]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "mt20",
              attrs: { data: _vm.tableData.data, size: "small" },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", "min-width": "50" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "grade", label: "等级级别", "min-width": "100" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "等级图标", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview line-heightOne" },
                          [
                            _c("el-image", {
                              attrs: {
                                src: scope.row.icon,
                                "preview-src-list": [scope.row.icon],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "name", label: "等级名称", "min-width": "100" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "experience",
                  label: "所需成长值",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态", "min-width": "100", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.checkPermi(["platform:system:user:level:use"])
                          ? _c("el-switch", {
                              attrs: {
                                "active-value": true,
                                "inactive-value": false,
                                "active-text": "开启",
                                "inactive-text": "关闭",
                                disabled: "",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.onchangeIsShow(scope.row)
                                },
                              },
                              model: {
                                value: scope.row.isShow,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "isShow", $$v)
                                },
                                expression: "scope.row.isShow",
                              },
                            })
                          : _c("div", [
                              _vm._v(
                                _vm._s(scope.row.isShow ? "开启" : "关闭")
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", width: "120", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["platform:system:user:level:update"],
                                expression:
                                  "['platform:system:user:level:update']",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c("el-divider", { attrs: { direction: "vertical" } }),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["platform:system:user:level:delete"],
                                expression:
                                  "['platform:system:user:level:delete']",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(
                                  scope.row.id,
                                  scope.$index
                                )
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.userInfo
        ? _c("creat-level", {
            ref: "grades",
            attrs: { user: _vm.userInfo, levelNumData: _vm.levelNumData },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }