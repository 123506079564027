"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _systemConfig = require("@/api/systemConfig.js");
var _permission = require("@/utils/permission");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'onePassConfig',
  data: function data() {
    return {
      isShow: true,
      isCreate: 0,
      editData: {},
      formName: 'onePassConfig' //一号通配置
    };
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['platform:one:pass:app:get'])) this.getPassAppInfo();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    resetForm: function resetForm(formValue) {
      this.isShow = false;
    },
    handlerSubmit: function handlerSubmit(data) {
      var _this = this;
      (0, _systemConfig.passAppSaveApi)(data).then(function (res) {
        _this.getPassAppInfo();
        _this.$message.success('操作成功');
      });
    },
    //获取配置详情
    getPassAppInfo: function getPassAppInfo() {
      var _this2 = this;
      (0, _systemConfig.passAppInfoApi)().then(function (res) {
        _this2.isShow = false;
        _this2.editData = res;
        _this2.isCreate = 1;
        setTimeout(function () {
          // 让表单重复渲染待编辑数据
          _this2.isShow = true;
        }, 80);
      });
    }
  }
};