"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'layoutIfameView',
  props: {
    meta: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      iframeLoading: true
    };
  },
  created: function created() {
    var _this = this;
    this.bus.$on('onTagsViewRefreshRouterView', function (path) {
      if (_this.$route.path !== path) return false;
      _this.$emit('getCurrentRouteMeta');
    });
  },
  mounted: function mounted() {
    this.initIframeLoad();
  },
  methods: {
    // 初始化页面加载 loading
    initIframeLoad: function initIframeLoad() {
      var _this2 = this;
      this.$nextTick(function () {
        _this2.iframeLoading = true;
        var iframe = document.getElementById('iframe');
        if (!iframe) return false;
        iframe.onload = function () {
          _this2.iframeLoading = false;
        };
      });
    }
  }
};