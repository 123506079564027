"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProductTypeEnum = void 0;
/**
 * 请求结果
 */
var ProductTypeEnum = exports.ProductTypeEnum = {
  OnSale: '1',
  //出售中
  InTheWarehouse: '2',
  //仓库中
  SoldOut: '3',
  //已售罄
  AlertInventory: '4',
  //警戒库存
  RecycleBin: '5',
  //回收站

  Audit: '6',
  //待审核
  ReviewFailed: '7',
  //审核失败
  PendingReview: '8' //待提审
};