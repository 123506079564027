var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingFrom,
              expression: "loadingFrom",
            },
          ],
          ref: "dataForm",
          staticClass: "tagForm",
          attrs: {
            model: _vm.dataForm,
            "label-width": "120px",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "标签名称：", prop: "tagName" } },
            [
              _c("el-input", {
                attrs: {
                  type: "text",
                  placeholder: "请输入标签名称",
                  maxLength: "5",
                  disabled: _vm.dataForm.owner === 0,
                },
                model: {
                  value: _vm.dataForm.tagName,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.dataForm,
                      "tagName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "dataForm.tagName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "标签说明：", prop: "tagNote" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  placeholder: "请输入标签说明",
                  disabled: _vm.dataForm.owner === 0,
                },
                model: {
                  value: _vm.dataForm.tagNote,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.dataForm,
                      "tagNote",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "dataForm.tagNote",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "排序：", prop: "sort" } },
            [
              _c("el-input-number", {
                attrs: { min: 1, max: 99999, step: 1 },
                model: {
                  value: _vm.dataForm.sort,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.dataForm,
                      "sort",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "dataForm.sort",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "生效时间：", prop: "timerange" } },
            [
              _c("el-date-picker", {
                staticClass: "selWidth",
                attrs: {
                  size: "small",
                  type: "daterange",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  "default-time": ["00:00:00", "23:59:59"],
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  align: "right",
                  "picker-options": _vm.pickerOptionsForEditCoupon,
                },
                on: { change: _vm.onchangeTime },
                model: {
                  value: _vm.dataForm.timerange,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "timerange", $$v)
                  },
                  expression: "dataForm.timerange",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "状态：", prop: "status" } },
            [
              _c("el-switch", {
                attrs: {
                  "active-value": 1,
                  "inactive-value": 0,
                  "active-text": "显示",
                  "inactive-text": "隐藏",
                },
                on: { change: _vm.handleStatusChange },
                model: {
                  value: _vm.dataForm.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "status", $$v)
                  },
                  expression: "dataForm.status",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "商城中的位置：", prop: "position" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 0 },
                  model: {
                    value: _vm.dataForm.position,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataForm, "position", $$v)
                    },
                    expression: "dataForm.position",
                  },
                },
                [_vm._v("标题下")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.dataForm.position,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataForm, "position", $$v)
                    },
                    expression: "dataForm.position",
                  },
                },
                [_vm._v("标题前")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "from-tips" }, [
                _vm._v(
                  "标题前最多展示1个标签，标题下最多展示3个标签；系统根据标签顺序进行展示"
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.dataForm.owner === 0
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "展示规则:", prop: "playProducts" } },
                    [
                      _vm.dataForm.tagName === "新品"
                        ? _c(
                            "div",
                            [
                              _vm._v("\n          产品创建后\n          "),
                              _c("el-input-number", {
                                attrs: { min: 1, max: 30, step: 1 },
                                model: {
                                  value: _vm.dataForm.playProducts,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataForm,
                                      "playProducts",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "dataForm.playProducts",
                                },
                              }),
                              _vm._v(
                                "\n          天内，展示此标签。\n        "
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.dataForm.tagName === "爆品"
                        ? _c(
                            "div",
                            [
                              _vm._v(
                                "\n          最近30天销量大于\n          "
                              ),
                              _c("el-input-number", {
                                attrs: { min: 1, max: 9999, step: 1 },
                                model: {
                                  value: _vm.dataForm.playProducts,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataForm,
                                      "playProducts",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "dataForm.playProducts",
                                },
                              }),
                              _vm._v("\n          件，展示此标签。\n        "),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.dataForm.tagName === "自营"
                        ? _c("div", [_vm._v("商家有自营标签时，展示此标签。")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.dataForm.tagName === "热卖"
                        ? _c(
                            "div",
                            [
                              _vm._v(
                                "\n          最近30天用户评论数大于\n          "
                              ),
                              _c("el-input-number", {
                                attrs: { min: 1, max: 9999, step: 1 },
                                model: {
                                  value: _vm.dataForm.playProducts,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataForm,
                                      "playProducts",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "dataForm.playProducts",
                                },
                              }),
                              _vm._v("\n          条，展示此标签。\n        "),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.dataForm.tagName === "优选"
                        ? _c(
                            "div",
                            [
                              _vm._v(
                                "\n          最近30天用户5星好评大于\n          "
                              ),
                              _c("el-input-number", {
                                attrs: { min: 1, max: 9999, step: 1 },
                                model: {
                                  value: _vm.dataForm.playProducts,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataForm,
                                      "playProducts",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "dataForm.playProducts",
                                },
                              }),
                              _vm._v("\n          条，展示此标签。\n        "),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.dataForm.tagName === "包邮"
                        ? _c("div", [_vm._v("全国包邮产品，展示此标签。")])
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.dataForm.owner > 0 || !_vm.editData.id
            ? _c("div", [
                _c(
                  "div",
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "产品参与类型：", prop: "playType" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            on: { input: _vm.handlePlayTypeChange },
                            model: {
                              value: _vm.dataForm.playType,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "playType", $$v)
                              },
                              expression: "dataForm.playType",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "product" } }, [
                              _vm._v("指定产品参与"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: "brand" } }, [
                              _vm._v("指定品牌参与"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: "category" } }, [
                              _vm._v("指定分类参与"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: "merchant" } }, [
                              _vm._v("指定商户参与"),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("product-association-form", {
                          staticClass: "mt14",
                          attrs: {
                            productAssociationType: _vm.dataForm.playType,
                            formValidate: _vm.dataForm,
                            multipleBrand: true,
                            multipleCategory: true,
                            isBatchDelete: false,
                          },
                          on: {
                            getProductAssociationData:
                              _vm.getProductAssociationData,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.handleClose("dataForm")
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: [
                        "platform:product:tag:save",
                        "platform:product:tag:update",
                      ],
                      expression:
                        "['platform:product:tag:save', 'platform:product:tag:update']",
                    },
                  ],
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      return _vm.onsubmit("dataForm")
                    },
                  },
                },
                [_vm._v("保存\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }