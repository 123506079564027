var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        {
          staticClass: "box-card",
          attrs: {
            shadow: "never",
            bordered: false,
            "body-style": { padding: "40px 50px" },
          },
        },
        [
          _c("div", { staticClass: "detail-section" }, [
            _c("div", { staticClass: "detail-title" }, [_vm._v("使用说明")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "detail-centent" },
              [
                _vm._v(
                  "\n        根据《商家自营类小程序运营规范》,特定类型的小程序需要在平台完成发货信息录入及确认收货流程后方可进行资金结算。符合以下情形的小程序，需接入微信开放平台订单发货管理功能："
                ),
                _c("br"),
                _vm._v(
                  "\n        1. 小程序内提供珠宝玉石、3C数码、盲盒等产品在线销售及配送服务；"
                ),
                _c("br"),
                _vm._v(
                  "\n\n        2.小程序的账号主体为近一年内新成立的企业或个体户主体；"
                ),
                _c("br"),
                _vm._v(
                  "\n\n        3. 小程序的账号管理员、运营者等角色，与其它高风险小程序存在关联；"
                ),
                _c("br"),
                _vm._v("\n\n        4. 小程序内经营预售产品。"),
                _c("br"),
                _vm._v(" "),
                _c("br"),
                _vm._v("\n        在小程序mp后台菜单出现一个发货信息管理菜单"),
                _c(
                  "el-link",
                  { attrs: { underline: false, type: "primary" } },
                  [
                    _vm._v("（图例）"),
                    _c("el-image", {
                      staticClass: "images",
                      attrs: {
                        src: require("@/assets/imgs/wxtu.png"),
                        "preview-src-list": [require("@/assets/imgs/wxtu.png")],
                      },
                    }),
                  ],
                  1
                ),
                _vm._v("，"),
                _c("span", { staticClass: "textE93323" }, [
                  _vm._v(
                    "只有该类小程序需要开启发货配置开关；需要若没有这个菜单，则无需开启，以免出错；"
                  ),
                ]),
                _vm._v(
                  "之前已产生的订单不会去主动触发，需要微信开放平台手动发货才可提现。"
                ),
                _c("br"),
                _vm._v(
                  "\n        快递物流：资金将于订单发货后的第10天，系统自动确认收货后结算；"
                ),
                _c("br"),
                _vm._v(
                  "\n        自提/同城配送/虚拟发货：资金将于订单发货后的第2天，系统自动确认收货后结算；"
                ),
                _c("br"),
                _vm._v(" "),
                _c("div", { staticClass: "acea-row" }, [
                  _vm._v("\n          详情见"),
                  _c(
                    "a",
                    {
                      attrs: {
                        target: "_blank",
                        href: "https://developers.weixin.qq.com/miniprogram/dev/platform-capabilities/business-capabilities/order-shipping/order-shipping.html#一、发货信息录入接口",
                      },
                    },
                    [_vm._v("《小程序发货信息管理服务》")]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      attrs: {
                        target: "_blank",
                        href: "https://developers.weixin.qq.com/miniprogram/product/jiaoyilei/yunyingguifan.html#_1-2-产品规范",
                      },
                    },
                    [_vm._v("《交易类小程序运营规范》")]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "hasPermi",
                        rawName: "v-hasPermi",
                        value: [
                          "platform:wechat:mini:shipping:switch:update",
                          "platform:wechat:mini:shipping:switch:get",
                        ],
                        expression:
                          "['platform:wechat:mini:shipping:switch:update', 'platform:wechat:mini:shipping:switch:get']",
                      },
                    ],
                    staticClass: "mt20",
                  },
                  [
                    _vm._v("\n          发货配置"),
                    _c("el-switch", {
                      staticClass: "ml10",
                      attrs: {
                        "active-value": "1",
                        "inactive-value": "0",
                        "active-text": "开启",
                        "inactive-text": "关闭",
                      },
                      on: { change: _vm.handleStatusChange },
                      model: {
                        value: _vm.shippingSwitch,
                        callback: function ($$v) {
                          _vm.shippingSwitch = $$v
                        },
                        expression: "shippingSwitch",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }