import { render, staticRenderFns } from "./index.vue?vue&type=template&id=2a6173c0&scoped=true"
import script from "./index.vue?vue&type=script&setup=true&lang=js"
export * from "./index.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a6173c0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\code\\商城\\dggf-order-web\\mer_plat_admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2a6173c0')) {
      api.createRecord('2a6173c0', component.options)
    } else {
      api.reload('2a6173c0', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=2a6173c0&scoped=true", function () {
      api.rerender('2a6173c0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/systemSetting/pcConfig/index.vue"
export default component.exports