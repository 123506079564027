var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "layout-container" },
    [
      _c("ColumnsAside"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "layout-columns-warp" },
        [
          _c("Asides"),
          _vm._v(" "),
          _c(
            "el-container",
            { staticClass: "flex-center layout-backtop" },
            [
              _vm.isFixedHeader ? _c("Headers") : _vm._e(),
              _vm._v(" "),
              _c(
                "el-scrollbar",
                [
                  !_vm.isFixedHeader ? _c("Headers") : _vm._e(),
                  _vm._v(" "),
                  _c("Mains"),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-backtop", {
        attrs: { target: ".layout-backtop .el-scrollbar__wrap" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }