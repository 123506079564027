"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _system = require("@/utils/system.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'layoutBreadcrumbSearch',
  data: function data() {
    return {
      isShowSearch: false,
      menuQuery: '',
      tagsViewList: []
    };
  },
  methods: {
    // 搜索弹窗打开
    openSearch: function openSearch() {
      var _this = this;
      this.menuQuery = '';
      this.isShowSearch = true;
      this.initTageView();
      this.$nextTick(function () {
        _this.$refs.layoutMenuAutocompleteRef.focus();
      });
    },
    // 搜索弹窗关闭
    closeSearch: function closeSearch() {
      var _this2 = this;
      setTimeout(function () {
        _this2.isShowSearch = false;
      }, 150);
    },
    // 菜单搜索数据过滤
    menuSearch: function menuSearch(queryString, cb) {
      var results = queryString ? this.tagsViewList.filter(this.createFilter(queryString)) : this.tagsViewList;
      cb(results);
    },
    // 菜单搜索过滤
    createFilter: function createFilter(queryString) {
      return function (restaurant) {
        return restaurant.path.toLowerCase().indexOf(queryString.toLowerCase()) > -1 || restaurant.title.toLowerCase().indexOf(queryString.toLowerCase()) > -1;
      };
    },
    // 初始化菜单数据
    initTageView: function initTageView() {
      if (this.tagsViewList.length > 0) return false;
      this.tagsViewList = (0, _system.getAllSiderMenu)(this.$store.state.user.menuList);
      // this.$store.state.tagsViewRoutes.tagsViewRoutes.map((v) => {
      // 	if (!v.isHide) this.tagsViewList.push({ ...v });
      // });
    },
    // 当前菜单选中时
    onHandleSelect: function onHandleSelect(item) {
      var path = item.path,
        redirect = item.redirect;
      if (redirect) this.$router.push(redirect);else this.$router.push(path);
      this.closeSearch();
    },
    // input 失去焦点时
    onSearchBlur: function onSearchBlur() {
      this.closeSearch();
    }
  }
};