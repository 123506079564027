var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        {
          staticClass: "box-card",
          attrs: { shadow: "never", bordered: false },
        },
        [
          _c("div", { staticClass: "theme_box" }, [
            _c(
              "div",
              { staticClass: "theme_head" },
              _vm._l(_vm.tabList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "tab_color",
                    class: _vm.active === index ? "active" : "",
                    on: {
                      click: function ($event) {
                        return _vm.selected(index)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "color_cont flex align-center" }, [
                      _c(
                        "div",
                        { staticClass: "main_c mr-2", class: item.class },
                        [
                          _c("span", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.active == index,
                                expression: "active == index",
                              },
                            ],
                            staticClass:
                              "iconfont iconios-checkmark-circle white",
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", [_vm._v(_vm._s(item.tit))]),
                    ]),
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "theme_content" },
              _vm._l(_vm.imgList, function (item, index) {
                return _c("img", { key: index, attrs: { src: item, alt: "" } })
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "save" },
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "hasPermi",
                        rawName: "v-hasPermi",
                        value: ["platform:system:config:change:color:save"],
                        expression:
                          "['platform:system:config:change:color:save']",
                      },
                    ],
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.saveTheme()
                      },
                    },
                  },
                  [_vm._v("保存")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }