var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _c(
        "el-card",
        {
          staticClass: "ivu-mt",
          attrs: {
            bordered: false,
            shadow: "never",
            "body-style": { padding: 0 },
          },
        },
        [
          _c(
            "div",
            { staticClass: "padding-add" },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    size: "small",
                    inline: "",
                    "label-position": "right",
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单类型：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "selWidth",
                          attrs: {
                            clearable: "",
                            size: "small",
                            placeholder: "请选择",
                          },
                          model: {
                            value: _vm.tableFrom.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "type", $$v)
                            },
                            expression: "tableFrom.type",
                          },
                        },
                        _vm._l(_vm.fromType, function (item, i) {
                          return _c("el-option", {
                            key: i,
                            attrs: { label: item.text, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "时间选择：" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "selWidth",
                        attrs: {
                          "value-format": "yyyy-MM-dd",
                          format: "yyyy-MM-dd",
                          size: "small",
                          type: "daterange",
                          placement: "bottom-end",
                          placeholder: "自定义时间",
                        },
                        on: { change: _vm.onchangeTime },
                        model: {
                          value: _vm.timeVal,
                          callback: function ($$v) {
                            _vm.timeVal = $$v
                          },
                          expression: "timeVal",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单编号：", "label-width": "66px" } },
                    [
                      _c("el-input", {
                        staticClass: "selWidth",
                        attrs: {
                          placeholder: "请输入订单编号",
                          size: "small",
                          clearable: "",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleSearchList($event)
                          },
                        },
                        model: {
                          value: _vm.tableFrom.orderNo,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.tableFrom,
                              "orderNo",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "tableFrom.orderNo",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商户名称：" } },
                    [
                      _c("merchant-name", {
                        attrs: { merIdChecked: _vm.tableFrom.merId },
                        on: { getMerId: _vm.getMerId },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.handleSearchList },
                        },
                        [_vm._v("查询")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small" },
                          on: { click: _vm.handleReset },
                        },
                        [_vm._v("重置")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card mt14",
          attrs: {
            "body-style": { padding: "0 20px 20px" },
            shadow: "never",
            bordered: false,
          },
        },
        [
          _c(
            "el-tabs",
            {
              staticClass: "list-tabs",
              on: { "tab-click": _vm.handleSearchList },
              model: {
                value: _vm.tableFrom.status,
                callback: function ($$v) {
                  _vm.$set(_vm.tableFrom, "status", $$v)
                },
                expression: "tableFrom.status",
              },
            },
            [
              _c("el-tab-pane", {
                attrs: {
                  name: "all",
                  label: "全部(" + (_vm.orderChartType.all || 0) + ")",
                },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: {
                  name: "auditing",
                  label: "待审核(" + (_vm.orderChartType.auditing || 0) + ")",
                },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: {
                  name: "not_pass",
                  label:
                    "审核未通过(" + (_vm.orderChartType.notPass || 0) + ")",
                },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: {
                  name: "notShipped",
                  label: "未发货(" + (_vm.orderChartType.notShipped || 0) + ")",
                },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: {
                  name: "spike",
                  label: "待收货(" + (_vm.orderChartType.spike || 0) + ")",
                },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: {
                  name: "receiving",
                  label: "已收货(" + (_vm.orderChartType.receiving || 0) + ")",
                },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: {
                  name: "refunded",
                  label:
                    "售后/退款(" + (_vm.orderChartType.refunded || 0) + ")",
                },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: {
                  name: "deleted",
                  label: "已删除(" + (_vm.orderChartType.deleted || 0) + ")",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt5" },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.exports } },
                [_vm._v("导出")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "mt20",
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "highlight-current-row": "",
                "row-key": function (row) {
                  return row.orderNo
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "订单编号", "min-width": "220" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "acea-row" },
                          [
                            _c(
                              "font",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: scope.row.type === 1,
                                    expression: "scope.row.type === 1",
                                  },
                                ],
                                staticClass: "mr5",
                              },
                              [_vm._v("[视频号]")]
                            ),
                            _vm._v(" "),
                            _c(
                              "font",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: scope.row.type === 2,
                                    expression: "scope.row.type === 2",
                                  },
                                ],
                                staticClass: "mr5",
                              },
                              [_vm._v("[秒杀]")]
                            ),
                            _vm._v(" "),
                            _c(
                              "font",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: scope.row.type === 5,
                                    expression: "scope.row.type === 5",
                                  },
                                ],
                                staticClass: "mr5",
                              },
                              [_vm._v("[云盘]")]
                            ),
                            _vm._v(" "),
                            _c(
                              "font",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: scope.row.type === 6,
                                    expression: "scope.row.type === 6",
                                  },
                                ],
                                staticClass: "mr5",
                              },
                              [_vm._v("[卡密]")]
                            ),
                            _vm._v(" "),
                            _c("span", {
                              class:
                                parseInt(scope.row.refundStatus) > 0
                                  ? "colorPrompt"
                                  : "",
                              staticStyle: { display: "block" },
                              domProps: {
                                textContent: _vm._s(scope.row.orderNo),
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: parseInt(scope.row.refundStatus) > 0,
                                expression:
                                  "parseInt(scope.row.refundStatus) > 0",
                              },
                            ],
                            staticClass: "colorPrompt",
                            staticStyle: { display: "block" },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("orderRefundStatusFilter")(
                                  scope.row.refundStatus
                                )
                              )
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: scope.row.isUserDel,
                                expression: "scope.row.isUserDel",
                              },
                            ],
                            staticClass: "colorPrompt",
                            staticStyle: { display: "block" },
                          },
                          [_vm._v("用户已删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "siteCode", label: "站点编号" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "nickName", label: "订购人" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            class:
                              scope.row.isLogoff == true ? "colorPrompt" : "",
                          },
                          [_vm._v(_vm._s(scope.row.nickName))]
                        ),
                        _vm._v(" "),
                        scope.row.isLogoff == true
                          ? _c(
                              "span",
                              {
                                class:
                                  scope.row.isLogoff == true
                                    ? "colorPrompt"
                                    : "",
                              },
                              [_vm._v("|")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.isLogoff == true
                          ? _c("span", { staticClass: "colorPrompt" }, [
                              _vm._v("(已注销)"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "userPhone", label: "电话" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "totalNum", label: "商品总数量" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "订单状态", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.auditStatus == "auditing"
                          ? _c(
                              "span",
                              { staticClass: "notStartTag tag-background" },
                              [_vm._v("待审核")]
                            )
                          : scope.row.auditStatus === "not_pass"
                          ? _c(
                              "span",
                              { staticClass: "notStartTag tag-background" },
                              [_vm._v("审核不通过")]
                            )
                          : scope.row.refundStatus === 3
                          ? _c(
                              "span",
                              { staticClass: "notStartTag tag-background" },
                              [_vm._v("已退款")]
                            )
                          : _c(
                              "span",
                              {
                                staticClass: "tag-background",
                                class:
                                  scope.row.status < 5 ? "doingTag" : "endTag",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("orderStatusFilter")(
                                      scope.row.status
                                    )
                                  )
                                ),
                              ]
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "下单时间",
                  "min-width": "140",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "100",
                  fixed: "right",
                  "scoped-slot": _vm.renderHeader,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.auditStatus == "auditing" &&
                        scope.row.hasAuditPerm
                          ? _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.orderApprove(scope.row.orderNo)
                                  },
                                },
                              },
                              [_vm._v(" 审核")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.onOrderDetails(scope.row.orderNo)
                              },
                            },
                          },
                          [_vm._v(" 详情")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.card_select_show,
              expression: "card_select_show",
            },
          ],
          staticClass: "card_abs",
        },
        [
          [
            _c(
              "div",
              { staticClass: "cell_ht" },
              [
                _c(
                  "el-checkbox",
                  {
                    attrs: { indeterminate: _vm.isIndeterminate },
                    on: { change: _vm.handleCheckAllChange },
                    model: {
                      value: _vm.checkAll,
                      callback: function ($$v) {
                        _vm.checkAll = $$v
                      },
                      expression: "checkAll",
                    },
                  },
                  [_vm._v("全选\n          ")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.checkSave()
                      },
                    },
                  },
                  [_vm._v("保存")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-checkbox-group",
              {
                on: { change: _vm.handleCheckedCitiesChange },
                model: {
                  value: _vm.checkedCities,
                  callback: function ($$v) {
                    _vm.checkedCities = $$v
                  },
                  expression: "checkedCities",
                },
              },
              _vm._l(_vm.columnData, function (item) {
                return _c(
                  "el-checkbox",
                  {
                    key: item,
                    staticClass: "check_cell",
                    attrs: { label: item },
                  },
                  [_vm._v(_vm._s(item))]
                )
              }),
              1
            ),
          ],
        ],
        2
      ),
      _vm._v(" "),
      _c("approve", {
        ref: "orderApprove",
        attrs: { orderNo: _vm.orderNo },
        on: { refresh: _vm.refreshListAndNumber },
      }),
      _vm._v(" "),
      _c("details-from", {
        ref: "orderDetail",
        attrs: { orderNo: _vm.orderNo },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }