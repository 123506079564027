var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "divBox" }, [
    _c(
      "div",
      { staticClass: "container_box" },
      [
        _c(
          "el-card",
          {
            staticClass: "box-card flex-1",
            attrs: {
              "body-style": { padding: "0 20px 20px" },
              bordered: false,
              shadow: "never",
            },
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                staticClass: "acea-row",
              },
              [
                _c(
                  "div",
                  { staticClass: "flex-1" },
                  [
                    _vm.tabList.length > 0
                      ? _c(
                          "el-tabs",
                          {
                            staticClass: "list-tabs",
                            on: { "tab-click": _vm.tabChange },
                            model: {
                              value: _vm.currentTab,
                              callback: function ($$v) {
                                _vm.currentTab = $$v
                              },
                              expression: "currentTab",
                            },
                          },
                          _vm._l(_vm.tabList, function (item, index) {
                            return _c("el-tab-pane", {
                              key: index,
                              attrs: { name: item.value, label: item.title },
                            })
                          }),
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-form",
                      {
                        ref: "formValidate",
                        staticClass: "formValidate",
                        attrs: {
                          model: _vm.formValidate,
                          "label-width": "73px",
                        },
                        nativeOn: {
                          submit: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      },
                      [
                        _vm.currentTab == 1 &&
                        _vm.checkPermi([
                          "platform:marketing:activity:new:people:present:config",
                        ])
                          ? _c(
                              "div",
                              [
                                _c("h2", { staticClass: "form_label" }, [
                                  _vm._v("规则设置"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "活动状态:" } },
                                  [
                                    _c("el-switch", {
                                      attrs: {
                                        width: 56,
                                        "active-text": "开启",
                                        "inactive-text": "关闭",
                                      },
                                      model: {
                                        value: _vm.formValidate.newPeopleSwitch,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "newPeopleSwitch",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formValidate.newPeopleSwitch",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("p", { staticClass: "desc mt10" }, [
                                      _vm._v(
                                        "\n                  活动开启商城新注册用户可获得下方设置的优惠券福利，活动关闭之后新注册的用户不会赠送新人福利\n                "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "活动对象:" } },
                                  [
                                    _c("span", [
                                      _vm._v("开启活动期间所有注册商城的用户"),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "优惠券:" } },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "mr10",
                                        attrs: { type: "text" },
                                        on: { click: _vm.addCoupon },
                                      },
                                      [_vm._v("添加优惠券")]
                                    ),
                                    _vm._v(" "),
                                    _vm.couponList.length
                                      ? _c(
                                          "div",
                                          { staticClass: "grid_box" },
                                          _vm._l(
                                            _vm.couponList,
                                            function (item, index) {
                                              return _c(
                                                "div",
                                                {
                                                  key: index,
                                                  staticClass:
                                                    "coupon_item acea-row",
                                                  class: item.isDel
                                                    ? "del"
                                                    : "show",
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "close el-icon-error",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.delItem(
                                                          item.id,
                                                          index
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "_left" },
                                                    [
                                                      _c("div", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "_symbol",
                                                          },
                                                          [_vm._v("¥")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "_price",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.money)
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass: "_type",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "满" +
                                                              _vm._s(
                                                                item.minPrice
                                                              ) +
                                                              "可用"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "_right" },
                                                    [
                                                      item.isDel
                                                        ? _c("div", [
                                                            _vm._v(
                                                              "优惠券已删除"
                                                            ),
                                                          ])
                                                        : _c("div", [
                                                            item.isFixedTime
                                                              ? _c("div", [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        _vm.getTime(
                                                                          item.useStartTime
                                                                        ) +
                                                                          " ~ " +
                                                                          _vm.getTime(
                                                                            item.useEndTime
                                                                          ) +
                                                                          "可用"
                                                                      ) +
                                                                      "\n                        "
                                                                  ),
                                                                ])
                                                              : _c("div", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      "领取后" +
                                                                        item.day +
                                                                        "天内可用"
                                                                    )
                                                                  ),
                                                                ]),
                                                          ]),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass: "_sales",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              !item.isLimited
                                                                ? "不限量"
                                                                : "剩余" +
                                                                    item.lastTotal +
                                                                    "张"
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "hasPermi",
                                            rawName: "v-hasPermi",
                                            value: [
                                              "platform:marketing:activity:new:people:present:edit",
                                            ],
                                            expression:
                                              "['platform:marketing:activity:new:people:present:edit']",
                                          },
                                          {
                                            name: "debounceClick",
                                            rawName: "v-debounceClick",
                                            value: _vm.confirmEdit,
                                            expression: "confirmEdit",
                                          },
                                        ],
                                        staticClass: "mr10",
                                        attrs: {
                                          size: "small",
                                          type: "primary",
                                          disabled: !_vm.couponList.length,
                                        },
                                      },
                                      [_vm._v("保存\n                ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.currentTab == 2 &&
                        _vm.checkPermi([
                          "platform:marketing:activity:birthday:present:config",
                        ])
                          ? _c(
                              "div",
                              [
                                _c("h2", { staticClass: "form_label" }, [
                                  _vm._v("规则设置"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "活动状态:" } },
                                  [
                                    _c("el-switch", {
                                      attrs: {
                                        width: 56,
                                        "active-text": "开启",
                                        "inactive-text": "关闭",
                                      },
                                      model: {
                                        value: _vm.formValidate.birthdaySwitch,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "birthdaySwitch",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formValidate.birthdaySwitch",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("p", { staticClass: "desc mt10" }, [
                                      _vm._v(
                                        "活动开启商城用户可获得下方设置的生日福利，活动关闭之后用户生日不会赠送福利。"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "活动对象:" } },
                                  [
                                    _c("span", [
                                      _vm._v("商城已经设置生日的用户"),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "优惠券:" } },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "mr10",
                                        attrs: { type: "text" },
                                        on: { click: _vm.addCoupon },
                                      },
                                      [_vm._v("添加优惠券")]
                                    ),
                                    _vm._v(" "),
                                    _vm.couponList.length
                                      ? _c(
                                          "div",
                                          { staticClass: "grid_box" },
                                          _vm._l(
                                            _vm.couponList,
                                            function (item, index) {
                                              return _c(
                                                "div",
                                                {
                                                  key: index,
                                                  staticClass:
                                                    "coupon_item acea-row",
                                                  class: item.isDel
                                                    ? "del"
                                                    : "show",
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "close el-icon-error",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.delItem(
                                                          item.id,
                                                          index
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "_left" },
                                                    [
                                                      _c("div", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "_symbol",
                                                          },
                                                          [_vm._v("¥")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "_price",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.money)
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass: "_type",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "满" +
                                                              _vm._s(
                                                                item.minPrice
                                                              ) +
                                                              "可用"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "_right" },
                                                    [
                                                      item.isDel
                                                        ? _c("div", [
                                                            _vm._v(
                                                              "优惠券已删除"
                                                            ),
                                                          ])
                                                        : _c("div", [
                                                            item.isFixedTime
                                                              ? _c("div", [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        _vm.getTime(
                                                                          item.useStartTime
                                                                        ) +
                                                                          " ~ " +
                                                                          _vm.getTime(
                                                                            item.useEndTime
                                                                          ) +
                                                                          "可用"
                                                                      ) +
                                                                      "\n                        "
                                                                  ),
                                                                ])
                                                              : _c("div", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      "领取后" +
                                                                        item.day +
                                                                        "天内可用"
                                                                    )
                                                                  ),
                                                                ]),
                                                          ]),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass: "_sales",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              !item.isLimited
                                                                ? "不限量"
                                                                : "剩余" +
                                                                    item.lastTotal +
                                                                    "张"
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "hasPermi",
                                            rawName: "v-hasPermi",
                                            value: [
                                              "platform:marketing:activity:birthday:present:edit",
                                            ],
                                            expression:
                                              "['platform:marketing:activity:birthday:present:edit']",
                                          },
                                          {
                                            name: "debounceClick",
                                            rawName: "v-debounceClick",
                                            value: _vm.confirmEdit,
                                            expression: "confirmEdit",
                                          },
                                        ],
                                        staticClass: "mr10",
                                        attrs: {
                                          size: "small",
                                          type: "primary",
                                          disabled: !_vm.couponList.length,
                                        },
                                      },
                                      [_vm._v("保存\n                ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.currentTab === "1"
                  ? _c(
                      "div",
                      { staticClass: "proview acea-row row-center-wrapper" },
                      [_c("img", { attrs: { src: _vm.proviewImg, alt: "" } })]
                    )
                  : _vm._e(),
              ]
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }