var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _vm.checkPermi(["platform:product:tag:list"])
        ? _c(
            "el-card",
            {
              staticClass: "ivu-mt",
              attrs: {
                bordered: false,
                shadow: "never",
                "body-style": { padding: 0 },
              },
            },
            [
              _c(
                "div",
                { staticClass: "padding-add" },
                [
                  _c(
                    "el-form",
                    {
                      attrs: { inline: "", "label-position": "right" },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "标签名称：" } },
                        [
                          _c("el-input", {
                            staticClass: "selWidth",
                            attrs: {
                              size: "small",
                              clearable: "",
                              placeholder: "待搜索的标签名称",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.handleSearch($event)
                              },
                            },
                            model: {
                              value: _vm.keywords,
                              callback: function ($$v) {
                                _vm.keywords =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "keywords",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "primary" },
                              on: { click: _vm.handleSearch },
                            },
                            [_vm._v("搜索")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card mt14",
          attrs: { shadow: "never", bordered: false },
        },
        [
          _c(
            "div",
            { staticClass: "container" },
            [
              _c(
                "router-link",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["platform:product:tag:save"],
                      expression: "['platform:product:tag:save']",
                    },
                  ],
                  attrs: { to: { path: "/product/tag/creatTag" } },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "mr10",
                      attrs: { size: "small", type: "primary" },
                    },
                    [_vm._v("添加标签")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableConfig.listLoading,
                  expression: "tableConfig.listLoading",
                },
              ],
              staticClass: "mt20",
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "row-key": "tag_id",
                "default-expand-all": false,
                "tree-props": {
                  children: "children",
                  hasChildren: "hasChildren",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", "min-width": "60" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "标签名称",
                  prop: "tagName",
                  "min-width": "100",
                  "show-overflow-tooltip": true,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "标签说明",
                  prop: "tagNote",
                  "min-width": "200",
                  "show-overflow-tooltip": true,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "startTime", label: "生效期间", width: "350" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(scope.row.startTime) +
                            " - " +
                            _vm._s(scope.row.endTime) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "status",
                  label: "是否显示",
                  "min-width": "100",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.checkPermi(["platform:product:tag:status"])
                          ? _c("el-switch", {
                              attrs: {
                                "active-value": 1,
                                "inactive-value": 0,
                                "active-text": "显示",
                                "inactive-text": "隐藏",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.onchangeIsShow(scope.row)
                                },
                              },
                              model: {
                                value: scope.row.status,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "status", $$v)
                                },
                                expression: "scope.row.status",
                              },
                            })
                          : _c("div", [
                              _vm._v(
                                _vm._s(scope.row.status ? "显示" : "隐藏")
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "创建时间",
                  "min-width": "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "position",
                  label: "商城标题位置",
                  "min-width": "90",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.position === 0 ? "标题下" : "标题前"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "sort", label: "排序", "min-width": "50" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", width: "100", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "router-link",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["platform:product:tag:update"],
                                expression: "['platform:product:tag:update']",
                              },
                            ],
                            attrs: {
                              to: {
                                path: "/product/tag/creatTag/" + scope.row.id,
                              },
                            },
                          },
                          [_c("a", [_vm._v("编辑")])]
                        ),
                        _vm._v(" "),
                        scope.row.owner > 0 &&
                        _vm.checkPermi(["platform:product:tag:delet"])
                          ? [
                              _c("el-divider", {
                                attrs: { direction: "vertical" },
                              }),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDelete(
                                        scope.row.id,
                                        scope.$index
                                      )
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          ref: "drawer",
          staticClass: "infoBox",
          attrs: {
            visible: _vm.editDataDialogConfig.visible,
            title: _vm.editData.id ? "产品标签编辑" : "产品标签新增",
            direction: "rtl",
            "custom-class": "demo-drawer",
            size: "900px",
            wrapperClosable: _vm.editData.id ? false : true,
            "modal-append-to-body": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.editDataDialogConfig, "visible", $event)
            },
            close: function ($event) {
              _vm.editDataDialogConfig.visible = false
            },
          },
        },
        [
          _vm.editDataDialogConfig.visible
            ? _c("edit-tag", {
                attrs: { "edit-data": _vm.editData },
                on: { onCloseHandle: _vm.handleOnEditDiaClosed },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }