"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'SmsConfig',
  data: function data() {
    return {
      iframeHeight: 0
    };
  },
  created: function created() {
    window.addEventListener('resize', this.handleResize);
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.iframeHeight = _this.$selfUtil.getTableHeight(0);
    });
  },
  methods: {
    handleResize: function handleResize(event) {
      this.iframeHeight = this.$selfUtil.getTableHeight(0);
    }
  }
};