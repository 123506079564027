var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      staticClass: "showHeader",
      attrs: { visible: _vm.drawer, direction: "rtl", "show-close": false },
      on: {
        "update:visible": function ($event) {
          _vm.drawer = $event
        },
        "before-close": _vm.closed,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "header-apply",
          attrs: { slot: "title" },
          slot: "title",
        },
        [
          _c("div", [_vm._v("用户注册信息")]),
          _vm._v(" "),
          _vm.applyInfo.auditStatus == "wait_audit"
            ? _c(
                "div",
                [
                  _vm.detailType == "detail"
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: {
                            click: function ($event) {
                              _vm.detailType = "edit"
                            },
                          },
                        },
                        [_vm._v("编辑")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.detailType == "edit"
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: {
                            click: function ($event) {
                              _vm.drawer = false
                            },
                          },
                        },
                        [_vm._v("取消")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.detailType == "edit"
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.update },
                        },
                        [_vm._v("保存")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.detailType == "apply"
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.apply(false)
                            },
                          },
                        },
                        [_vm._v("审核拒绝")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.detailType == "apply"
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.apply(true)
                            },
                          },
                        },
                        [_vm._v("审核通过")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.applyInfo,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "用户昵称", prop: "nickname" } },
            [_c("div", [_vm._v(_vm._s(_vm.applyInfo.nickname))])]
          ),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "手机号", prop: "phone" } }, [
            _c("div", [_vm._v(_vm._s(_vm.applyInfo.phone))]),
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "代销证编号", prop: "siteCode" } },
            [_c("div", [_vm._v(_vm._s(_vm.applyInfo.siteCode))])]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "机构名称", prop: "orgName" } },
            [
              _vm.type == "edit"
                ? _c("OrgSelect", {
                    on: { selected: _vm.orgSelected },
                    model: {
                      value: _vm.applyInfo.orgName,
                      callback: function ($$v) {
                        _vm.$set(_vm.applyInfo, "orgName", $$v)
                      },
                      expression: "applyInfo.orgName",
                    },
                  })
                : _c("div", [_vm._v(_vm._s(_vm.applyInfo.orgName))]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.detailType != "edit"
            ? _c(
                "el-form-item",
                { attrs: { label: "机构编号", prop: "identNumber" } },
                [_c("div", [_vm._v(_vm._s(_vm.applyInfo.identNumber))])]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.applyInfo.auditStatus === "passed"
            ? _c(
                "el-form-item",
                { attrs: { label: "商户", prop: "merchantName" } },
                [_c("div", [_vm._v(_vm._s(_vm.applyInfo.merchantName))])]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }