var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _c(
        "el-card",
        {
          staticClass: "ivu-mt",
          attrs: {
            bordered: false,
            shadow: "never",
            "body-style": { padding: 0 },
          },
        },
        [
          _c(
            "div",
            { staticClass: "padding-add" },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    size: "small",
                    inline: "",
                    "label-position": "right",
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "时间选择：" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "selWidth",
                        attrs: {
                          "value-format": "yyyy-MM-dd",
                          format: "yyyy-MM-dd",
                          size: "small",
                          type: "daterange",
                          placement: "bottom-end",
                          placeholder: "自定义时间",
                        },
                        on: { change: _vm.onchangeTime },
                        model: {
                          value: _vm.timeVal,
                          callback: function ($$v) {
                            _vm.timeVal = $$v
                          },
                          expression: "timeVal",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属机构：" } },
                    [
                      _c("el-cascader", {
                        ref: "cascader",
                        staticStyle: { width: "100%" },
                        attrs: { clearable: "", props: _vm.props },
                        model: {
                          value: _vm.querys.orgIds,
                          callback: function ($$v) {
                            _vm.$set(_vm.querys, "orgIds", $$v)
                          },
                          expression: "querys.orgIds",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.handleSearchList },
                        },
                        [_vm._v("查询")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small" },
                          on: { click: _vm.handleReset },
                        },
                        [_vm._v("重置")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      !_vm.showDetails
        ? _c(
            "el-card",
            {
              staticClass: "box-card mt14",
              attrs: {
                "body-style": { padding: "0 20px 20px" },
                shadow: "never",
                bordered: false,
              },
            },
            [
              _c(
                "div",
                { staticClass: "mt20" },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "small" }, on: { click: _vm.exports } },
                    [_vm._v("导出")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  staticClass: "mt20",
                  attrs: {
                    data: _vm.tableData.data,
                    size: "small",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "序号",
                      width: "60px",
                      align: "center",
                      type: "index",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "uid", label: "用户编号" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "nickname", label: "用户名称" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "siteCode", label: "站点编号" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "orgName", label: "所属机构名称" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "identNumber", label: "所属机构编号" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "totalNum", label: "数量" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "70", fixed: "right" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.showDetail(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(" 详情")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1186772988
                    ),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "block" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "page-sizes": [20, 40, 60, 80],
                      "page-size": _vm.pager.limit,
                      "current-page": _vm.pager.page,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.tableData.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.pageChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showDetails
        ? _c("detail", {
            ref: "detail",
            attrs: { "query-params": _vm.querys, uid: _vm.selected.uid },
            on: {
              "update:queryParams": function ($event) {
                _vm.querys = $event
              },
              "update:query-params": function ($event) {
                _vm.querys = $event
              },
              back: _vm.back,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }