var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("transition", { attrs: { name: "el-zoom-in-center" } }, [
        _c(
          "ul",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isShow,
                expression: "isShow",
              },
            ],
            staticClass:
              "el-dropdown-menu el-popper el-dropdown-menu--medium custom-contextmenu",
            style:
              "top: " + _vm.dropdowns.y + "px;left: " + _vm.dropdowns.x + "px;",
            attrs: { "x-placement": "bottom-end", id: "contextmenu" },
          },
          [
            _vm._l(_vm.dropdownList, function (v, k) {
              return _c(
                "li",
                {
                  key: k,
                  staticClass: "el-dropdown-menu__item",
                  on: {
                    click: function ($event) {
                      return _vm.onCurrentContextmenuClick(v.id)
                    },
                  },
                },
                [
                  !v.affix
                    ? [
                        _c("i", { class: v.icon }),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(v.txt))]),
                      ]
                    : _vm._e(),
                ],
                2
              )
            }),
            _vm._v(" "),
            _c("div", {
              staticClass: "popper__arrow",
              style: { left: _vm.arrowLeft + "px" },
              attrs: { "x-arrow": "" },
            }),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }