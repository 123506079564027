var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "editPram",
          attrs: { model: _vm.editPram, "label-width": "80px" },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "机构名称：",
                prop: "name",
                rules: [
                  {
                    required: true,
                    message: "请输入机构名称",
                    trigger: ["blur", "change"],
                  },
                ],
              },
            },
            [
              _c("el-input", {
                attrs: { maxlength: 20, placeholder: "机构名称" },
                model: {
                  value: _vm.editPram.name,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.editPram,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "editPram.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "编号：" } },
            [
              _c("el-input", {
                attrs: { maxlength: 20, placeholder: "编号" },
                model: {
                  value: _vm.editPram.identNumber,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.editPram,
                      "identNumber",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "editPram.identNumber",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "地址：" } },
            [
              _c("el-input", {
                attrs: { maxlength: 255, placeholder: "地址" },
                model: {
                  value: _vm.editPram.address,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.editPram,
                      "address",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "editPram.address",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "电话：",
                rules: [
                  {
                    required: true,
                    message: "请输入电话",
                    trigger: ["blur", "change"],
                  },
                ],
              },
            },
            [
              _c("el-input", {
                attrs: { maxlength: 20, placeholder: "电话" },
                model: {
                  value: _vm.editPram.linkPhone,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.editPram,
                      "linkPhone",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "editPram.linkPhone",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "联系人：",
                rules: [
                  {
                    required: true,
                    message: "请输入联系人",
                    trigger: ["blur", "change"],
                  },
                ],
              },
            },
            [
              _c("el-input", {
                attrs: { maxlength: 20, placeholder: "电话" },
                model: {
                  value: _vm.editPram.linkName,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.editPram,
                      "linkName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "editPram.linkName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "所属上级：" } },
            [
              _vm.isCreate === 0
                ? _c("el-cascader", {
                    ref: "cascader",
                    staticStyle: { width: "100%" },
                    attrs: { props: _vm.props, disabled: _vm.isCreate === 1 },
                    on: { change: _vm.handleChange },
                    model: {
                      value: _vm.editPram.pid,
                      callback: function ($$v) {
                        _vm.$set(_vm.editPram, "pid", $$v)
                      },
                      expression: "editPram.pid",
                    },
                  })
                : _c("div", [_vm._v(_vm._s(_vm.editPram.parentName))]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "mb30", attrs: { label: "排序：" } },
            [
              _c("el-input-number", {
                attrs: { min: 0 },
                model: {
                  value: _vm.editPram.sort,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.editPram,
                      "sort",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "editPram.sort",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer-inner",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取消")]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "debounceClick",
                  rawName: "v-debounceClick",
                  value: function () {
                    _vm.handlerSubmit("editPram")
                  },
                  expression:
                    "\n        () => {\n          handlerSubmit('editPram');\n        }\n      ",
                },
              ],
              attrs: { type: "primary", loading: _vm.loadingBtn },
            },
            [_vm._v("确定\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }