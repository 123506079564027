var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox", staticStyle: { "padding-top": "0" } },
    [
      _c(
        "el-row",
        { attrs: { gutter: 14 } },
        [
          _vm.checkPermi(["platform:statistics:home:operating:data"])
            ? _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 24, lg: 12 } },
                [
                  _c(
                    "el-card",
                    {
                      staticClass: "box-card",
                      attrs: { shadow: "never", bordered: false },
                    },
                    [
                      _c("div", { staticClass: "header_title" }, [
                        _vm._v("经营数据"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "nav_grid m-t-20" },
                        _vm._l(_vm.businessList, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "nav_grid_item",
                              on: {
                                click: function ($event) {
                                  return _vm.navigatorTo(item.path)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "pic_badge",
                                  style: { background: item.bgColor },
                                },
                                [
                                  _c("span", {
                                    staticClass: "iconfont",
                                    class: item.icon,
                                    style: { color: item.color },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", [
                                _c("p", { staticClass: "label" }, [
                                  _vm._v(_vm._s(item.title || 0)),
                                ]),
                                _vm._v(" "),
                                _c("p", { staticClass: "num_data" }, [
                                  _vm._v(_vm._s(item.num || 0)),
                                ]),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.checkPermi(["platform:statistics:home:user:channel"])
            ? _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 24, lg: 12 } },
                [
                  _c(
                    "el-card",
                    {
                      staticClass: "box-card",
                      attrs: { shadow: "never", bordered: false },
                    },
                    [
                      _c("div", { staticClass: "header_title" }, [
                        _vm._v("用户渠道比例"),
                      ]),
                      _vm._v(" "),
                      _vm.optionData
                        ? _c("echarts-new", {
                            attrs: {
                              "option-data": _vm.optionData,
                              styles: _vm.style,
                              height: "100%",
                              width: "100%",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }