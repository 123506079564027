var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        {
          staticClass: "box-card",
          attrs: { bordered: false, shadow: "never" },
        },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [_vm._v("接入微信视频号步骤")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "mb20" }, [
            _vm.toRegister.errcode === 1040002
              ? _c("h1", [_vm._v("已经接入自定义交易组件")])
              : _c("h1", [_vm._v("接入中" + _vm._s(_vm.toRegister))]),
          ]),
          _vm._v(" "),
          _c(
            "el-timeline",
            [
              _c(
                "el-timeline-item",
                { attrs: { timestamp: "创建视频号", placement: "top" } },
                [
                  _c(
                    "el-card",
                    { attrs: { shadow: "never", bordered: false } },
                    [
                      _c(
                        "el-form",
                        { attrs: { inline: "" } },
                        [
                          _c("el-form-item", [
                            _c("p", [
                              _vm._v(
                                "在微信平台中设置, 申请自定义交易组件，如果平台已有自定义交易组件跳过此步"
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  nativeOn: { click: function ($event) {} },
                                },
                                [_vm._v("去申请(跳转文档链接)")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-timeline-item",
                {
                  attrs: {
                    timestamp: "申请开通自定义交易组件",
                    placement: "top",
                  },
                },
                [
                  _c(
                    "el-card",
                    { attrs: { bordered: false, shadow: "never" } },
                    [
                      _c(
                        "el-form",
                        { attrs: { inline: "" } },
                        [
                          _c("el-form-item", [
                            _c("p", [
                              _vm._v(
                                "\n                完成自定义版交易组件接入后，小程序即可在视频号中实现产品展示和带货等功能，进一步提升经营能力。若您已开通标准化交易组件，则暂不支持切换\n              "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              directives: [
                                {
                                  name: "hasPermi",
                                  rawName: "v-hasPermi",
                                  value: [
                                    "platform:pay:component:shop:register:finish",
                                  ],
                                  expression:
                                    "['platform:pay:component:shop:register:finish']",
                                },
                              ],
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.handleRegisterCheck()
                                    },
                                  },
                                },
                                [_vm._v("完成")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-timeline-item",
                {
                  attrs: {
                    timestamp: "自定义版交易组件申请通过，接口调用场景检测",
                    placement: "top",
                  },
                },
                [
                  _c(
                    "el-card",
                    { attrs: { bordered: false, shadow: "never" } },
                    [
                      _c(
                        "el-form",
                        [
                          _c(
                            "el-form-item",
                            [
                              _c("p", [
                                _vm._v(
                                  "\n                自定义交易组件开通后，小程序版本必须大于等于4.1.5，如果不是 需要更新小程序\n                发布新的小程序(跳转小程序下载页面)\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: "/marketing/videoChannel/draftList",
                                  },
                                },
                                [
                                  _c("el-tag", { attrs: { type: "warning" } }, [
                                    _vm._v(
                                      "初次需要去审核商家提审的视频号产品"
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-form",
                                [
                                  _c("el-form-item", [
                                    _c("span", [
                                      _vm._v(
                                        "接入场景\n                    " +
                                          _vm._s(
                                            _vm.registerCheckData.data
                                              .scene_group_list[0].group_id ===
                                              1
                                              ? "视频号"
                                              : "公众号场景"
                                          )
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-form-item", [
                                    _c("span", [
                                      _vm._v(
                                        "场景名称 " +
                                          _vm._s(
                                            _vm.registerCheckData.data
                                              .scene_group_list[0].name
                                          )
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-form-item", [
                                    _c("span", [
                                      _vm._v("审核状态\n                    "),
                                      _vm.registerCheckData.data
                                        .scene_group_list[0].status === 0
                                        ? _c("span", [_vm._v("审核中")])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.registerCheckData.data
                                        .scene_group_list[0].status === 1
                                        ? _c("span", [_vm._v("审核完成")])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.registerCheckData.data
                                        .scene_group_list[0].status === 2
                                        ? _c("span", [_vm._v("审核失败")])
                                        : _vm._e(),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-form-item", [
                                    _c(
                                      "span",
                                      [
                                        _vm._v(
                                          "场景审核结果\n                    "
                                        ),
                                        _vm._l(
                                          _vm.registerCheckData.data
                                            .scene_group_list[0]
                                            .scene_group_ext_list,
                                          function (item, key) {
                                            return _c(
                                              "span",
                                              { key: key },
                                              [
                                                item.ext_id === 1
                                                  ? _c("span", [
                                                      _vm._v("客服售后 -》"),
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                item.ext_id === 2
                                                  ? _c("span", [
                                                      _vm._v("电商平台 -》"),
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c("el-tag", [
                                                  item.status === 0
                                                    ? _c("span", [
                                                        _vm._v("审核中"),
                                                      ])
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  item.status === 1
                                                    ? _c("span", [
                                                        _vm._v("审核成功"),
                                                      ])
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  item.status === 2
                                                    ? _c("span", [
                                                        _vm._v("审核失败"),
                                                      ])
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  item.status === 3
                                                    ? _c("span", [
                                                        _vm._v("未审核"),
                                                      ])
                                                    : _vm._e(),
                                                ]),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-form-item", [
                                    _c("span", [
                                      _vm._v(
                                        "审核理由 " +
                                          _vm._s(
                                            _vm.registerCheckData.data
                                              .scene_group_list[0].reason
                                          )
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    [
                                      _c("span", [
                                        _vm._v("上传产品并审核成功 "),
                                      ]),
                                      _vm._v(" "),
                                      _c("el-tag", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.registerCheckData.data
                                              .access_info.spu_audit_success ===
                                              0
                                              ? "未成功"
                                              : "成功"
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    [
                                      _c("span", [_vm._v("产品接口调试完成")]),
                                      _vm._v(" "),
                                      _c("el-tag", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.registerCheckData.data
                                              .access_info
                                              .spu_audit_finished === 0
                                              ? "未成功"
                                              : "成功"
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    [
                                      _c("span", [
                                        _vm._v("发起一笔订单并支付成功"),
                                      ]),
                                      _vm._v(" "),
                                      _c("el-tag", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.registerCheckData.data
                                              .access_info.ec_order_success ===
                                              0
                                              ? "未成功"
                                              : "成功"
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    [
                                      _c("span", [_vm._v("订单接口调试完成")]),
                                      _vm._v(" "),
                                      _c("el-tag", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.registerCheckData.data
                                              .access_info.ec_order_finished ===
                                              0
                                              ? "未成功"
                                              : "成功"
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    [
                                      _c("span", [_vm._v("物流接口调用成功")]),
                                      _vm._v(" "),
                                      _c("el-tag", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.registerCheckData.data
                                              .access_info
                                              .send_delivery_success === 0
                                              ? "未成功"
                                              : "成功"
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    [
                                      _c("span", [_vm._v("物流接口调试完成")]),
                                      _vm._v(" "),
                                      _c("el-tag", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.registerCheckData.data
                                              .access_info
                                              .send_delivery_finished === 0
                                              ? "未成功"
                                              : "成功"
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    [
                                      _c("span", [_vm._v("售后接口调用成功")]),
                                      _vm._v(" "),
                                      _c("el-tag", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.registerCheckData.data
                                              .access_info
                                              .ec_after_sale_success === 0
                                              ? "未成功"
                                              : "成功"
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    [
                                      _c("span", [_vm._v("售后接口调试完成")]),
                                      _vm._v(" "),
                                      _c("el-tag", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.registerCheckData.data
                                              .access_info
                                              .ec_after_sale_finished === 0
                                              ? "未成功"
                                              : "成功"
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    [
                                      _c("span", [_vm._v("测试完成")]),
                                      _vm._v(" "),
                                      _c("el-tag", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.registerCheckData.data
                                              .access_info.test_api_finished ===
                                              0
                                              ? "未成功"
                                              : "成功"
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    [
                                      _c("span", [_vm._v("发版完成")]),
                                      _vm._v(" "),
                                      _c("el-tag", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.registerCheckData.data
                                              .access_info
                                              .deploy_wxa_finished === 0
                                              ? "未成功"
                                              : "成功"
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-timeline-item", {
                attrs: {
                  timestamp: "自定义版交易组件开通成功",
                  placement: "top",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }