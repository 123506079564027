var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        {
          staticClass: "box-card mb20",
          attrs: {
            "body-style": { padding: "0 20px 20px" },
            shadow: "never",
            bordered: false,
          },
        },
        [
          _c(
            "el-tabs",
            {
              staticClass: "list-tabs",
              model: {
                value: _vm.currentTab,
                callback: function ($$v) {
                  _vm.currentTab = $$v
                },
                expression: "currentTab",
              },
            },
            _vm._l(_vm.tabList, function (item, index) {
              return _c("el-tab-pane", {
                key: index,
                attrs: { name: item.value, label: item.title },
              })
            }),
            1
          ),
          _vm._v(" "),
          _vm.currentTab === "0"
            ? [
                _c("FromList", {
                  attrs: { configObj: _vm.advertisementlistConfig },
                }),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "hasPermi",
                        rawName: "v-hasPermi",
                        value: ["platform:pc:shopping:home:advertisement:edit"],
                        expression:
                          "['platform:pc:shopping:home:advertisement:edit']",
                      },
                    ],
                    attrs: { type: "primary" },
                    on: { click: _vm.handleAdvertisementSave },
                  },
                  [_vm._v(_vm._s(_vm.loadingBtn ? "提交中 ..." : "保存"))]
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.currentTab === "1"
            ? [
                _c("FromList", { attrs: { configObj: _vm.bannerListConfig } }),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "hasPermi",
                        rawName: "v-hasPermi",
                        value: ["platform:pc:shopping:home:banner:save"],
                        expression: "['platform:pc:shopping:home:banner:save']",
                      },
                    ],
                    attrs: { type: "primary" },
                    on: { click: _vm.handleBannerSave },
                  },
                  [_vm._v(_vm._s(_vm.loadingBtn ? "提交中 ..." : "保存"))]
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.currentTab === "2"
            ? [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "hasPermi",
                        rawName: "v-hasPermi",
                        value: ["platform:pc:shopping:home:recommended:add"],
                        expression:
                          "['platform:pc:shopping:home:recommended:add']",
                      },
                    ],
                    staticClass: "mb20",
                    attrs: {
                      disabled: _vm.tableData.data.length >= 10,
                      type: "primary",
                      size: "small",
                    },
                    on: { click: _vm.handleAdd },
                  },
                  [_vm._v("添加板块")]
                ),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.listLoading,
                        expression: "listLoading",
                      },
                    ],
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.tableData.data,
                      size: "small",
                      "row-key": "brand_id",
                      "default-expand-all": false,
                      "tree-props": {
                        children: "children",
                        hasChildren: "hasChildren",
                      },
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "id", label: "ID", "min-width": "60" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "板块名称",
                        prop: "name",
                        "min-width": "150",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "sort", label: "排序", "min-width": "50" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "status",
                        label: "是否显示",
                        "min-width": "100",
                        fixed: "right",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm.checkPermi([
                                  "platform:pc:shopping:home:recommended:switch",
                                ])
                                  ? _c("el-switch", {
                                      attrs: {
                                        "active-value": true,
                                        "inactive-value": false,
                                        "active-text": "显示",
                                        "inactive-text": "隐藏",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.onchangeIsShow(scope.row)
                                        },
                                      },
                                      model: {
                                        value: scope.row.status,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "status", $$v)
                                        },
                                        expression: "scope.row.status",
                                      },
                                    })
                                  : _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.status ? "显示" : "隐藏"
                                        )
                                      ),
                                    ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3268356833
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "操作", width: "120", fixed: "right" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "a",
                                  {
                                    directives: [
                                      {
                                        name: "hasPermi",
                                        rawName: "v-hasPermi",
                                        value: [
                                          "platform:pc:shopping:home:recommended:edit",
                                        ],
                                        expression:
                                          "['platform:pc:shopping:home:recommended:edit']",
                                      },
                                    ],
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleEdit(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                ),
                                _vm._v(" "),
                                _c("el-divider", {
                                  attrs: { direction: "vertical" },
                                }),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    directives: [
                                      {
                                        name: "hasPermi",
                                        rawName: "v-hasPermi",
                                        value: [
                                          "platform:pc:shopping:home:recommended:delete",
                                        ],
                                        expression:
                                          "['platform:pc:shopping:home:recommended:delete']",
                                      },
                                    ],
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDelete(
                                          scope.row.id,
                                          scope.$index
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3579045868
                      ),
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.currentTab === "3"
            ? [
                _c("FromList", { attrs: { configObj: _vm.menuListConfig } }),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "hasPermi",
                        rawName: "v-hasPermi",
                        value: ["platform:pc:shopping:home:navigation:save"],
                        expression:
                          "['platform:pc:shopping:home:navigation:save']",
                      },
                    ],
                    attrs: { type: "primary" },
                    on: { click: _vm.handleMenuSave },
                  },
                  [_vm._v(_vm._s(_vm.loadingBtn ? "提交中 ..." : "保存"))]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          staticClass: "showHeader",
          attrs: {
            size: "1000px",
            visible: _vm.drawer,
            direction: "rtl",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "demo-drawer_title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [_vm._v(_vm._s(_vm.dataForm.id ? "编辑推荐板块" : "添加推荐板块"))]
          ),
          _vm._v(" "),
          _vm.drawer
            ? _c(
                "div",
                { staticClass: "demo-drawer__content detailSection" },
                [
                  _c(
                    "el-form",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.fullscreenLoading,
                          expression: "fullscreenLoading",
                        },
                      ],
                      ref: "dataForm",
                      staticClass: "dataForm mt20",
                      attrs: {
                        rules: _vm.ruleValidate,
                        model: _vm.dataForm,
                        "label-width": "120px",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "板块名称：", prop: "name" } },
                        [
                          _c("el-input", {
                            staticClass: "from-ipt-width",
                            attrs: {
                              maxlength: "6",
                              size: "small",
                              placeholder: "请输入板块名称",
                            },
                            model: {
                              value: _vm.dataForm.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "name", $$v)
                              },
                              expression: "dataForm.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "广告图链接：" } },
                        [
                          _c("el-input", {
                            staticClass: "from-ipt-width",
                            attrs: {
                              size: "small",
                              placeholder: "请输入广告图链接",
                            },
                            model: {
                              value: _vm.dataForm.linkUrl,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "linkUrl", $$v)
                              },
                              expression: "dataForm.linkUrl",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "广告图(471*350)：",
                            prop: "imageUrl",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "upLoadPicBox",
                              on: {
                                click: function ($event) {
                                  return _vm.modalPicTap("1", "dan")
                                },
                              },
                            },
                            [
                              _vm.dataForm.imageUrl
                                ? _c("div", { staticClass: "pictrue" }, [
                                    _c("img", {
                                      attrs: { src: _vm.dataForm.imageUrl },
                                    }),
                                  ])
                                : _c("div", { staticClass: "upLoad" }, [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-camera cameraIconfont",
                                    }),
                                  ]),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "排序：" } },
                        [
                          _c("el-input-number", {
                            attrs: {
                              min: 0,
                              max: 99,
                              step: 1,
                              "step-strictly": "",
                              label: "排序",
                            },
                            model: {
                              value: _vm.dataForm.sort,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.dataForm,
                                  "sort",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "dataForm.sort",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否开启：" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              width: 56,
                              "active-value": true,
                              "inactive-value": false,
                              "active-text": "开启",
                              "inactive-text": "关闭",
                            },
                            model: {
                              value: _vm.dataForm.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "status", $$v)
                              },
                              expression: "dataForm.status",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "选择产品：", prop: "playType" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              on: { input: _vm.handlePlayTypeChange },
                              model: {
                                value: _vm.dataForm.playType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "playType", $$v)
                                },
                                expression: "dataForm.playType",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "product" } }, [
                                _vm._v("指定产品参与"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: "brand" } }, [
                                _vm._v("指定品牌参与"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: "category" } }, [
                                _vm._v("指定分类参与"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: "merchant" } }, [
                                _vm._v("指定商户参与"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("product-association-form", {
                            staticClass: "mt20",
                            attrs: {
                              productAssociationType: _vm.dataForm.playType,
                              formValidate: _vm.dataForm,
                              multipleBrand: false,
                              multipleCategory: false,
                              isBatchDelete: false,
                            },
                            on: {
                              getProductAssociationData:
                                _vm.getProductAssociationData,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-form-item", { staticClass: "btn" }, [
                        _c("div", [
                          _c(
                            "div",
                            { staticClass: "acea-row justify-content" },
                            [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "debounceClick",
                                      rawName: "v-debounceClick",
                                      value: function () {
                                        _vm.handleRecommendedSave("dataForm")
                                      },
                                      expression:
                                        "\n                  () => {\n                    handleRecommendedSave('dataForm');\n                  }\n                ",
                                    },
                                  ],
                                  attrs: { type: "primary" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.loadingBtn ? "提交中 ..." : "保存"
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }