var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "layout-scrollbar layout-link-container" }, [
    _c("div", { staticClass: "layout-view-bg-white flex layout-view-link" }, [
      _c(
        "div",
        { staticClass: "layout-link-warp" },
        [
          _c("i", { staticClass: "layout-link-icon iconfont icon-xingqiu" }),
          _vm._v(" "),
          _c("div", { staticClass: "layout-link-msg" }, [
            _vm._v('页面 "' + _vm._s(_vm.meta.title) + '" 已在新窗口中打开'),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "mt30",
              attrs: { round: "", size: "small" },
              on: { click: _vm.onGotoFullPage },
            },
            [
              _c("i", { staticClass: "iconfont icon-lianjie" }),
              _vm._v(" "),
              _c("span", [_vm._v("立即前往")]),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }