var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "layout-navbars-breadcrumb-user-news" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content-box" },
      [
        _vm.newsList.length > 0
          ? [
              _vm._l(_vm.newsList, function (v, k) {
                return _c(
                  "div",
                  {
                    key: k,
                    staticClass: "content-box-item",
                    on: {
                      click: function ($event) {
                        return _vm.jumpUrl(v.url)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "content-box-right" }, [
                      _c("div", { staticClass: "content-box-type" }, [
                        _vm._v(_vm._s(v.title)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content-box-msg" }, [
                        _vm._v(
                          "\n            " + _vm._s(v.message) + "\n          "
                        ),
                      ]),
                    ]),
                  ]
                )
              }),
              _vm._v(" "),
              _vm.list.length > 3 && _vm.list.length != _vm.newsList.length
                ? _c(
                    "div",
                    {
                      staticClass: "moreBtn",
                      on: {
                        click: function ($event) {
                          _vm.newsList = _vm.list
                        },
                      },
                    },
                    [
                      _vm._v("\n        展开全部"),
                      _c("span", { staticClass: "el-icon-arrow-down" }),
                    ]
                  )
                : _vm._e(),
            ]
          : _c("div", { staticClass: "content-box-empty" }, [_vm._m(1)]),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "head-box" }, [
      _c("div", { staticClass: "head-box-title" }, [_vm._v("系统通知")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content-box-empty-margin" }, [
      _c("img", {
        staticClass: "no-msg",
        attrs: { src: require("@/assets/images/no-message.png"), alt: "" },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "mt15" }, [_vm._v("暂无系统通知")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }