"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/components/productAssociationForm/index.vue"));
var _index2 = _interopRequireDefault(require("@/components/merUseCategory/index.vue"));
var _vuex = require("vuex");
var _product = _interopRequireWildcard(require("@/api/product"));
var storeApi = _product;
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'editProductCateTag',
  components: {
    productAssociationForm: _index.default,
    merchantName: _index2.default
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['adminProductClassify'])),
  data: function data() {
    var _this2 = this;
    // 自定义组件校验规则
    var validatePlayTypeAndPlayProducts = function validatePlayTypeAndPlayProducts(rule, value, callback) {
      if (value === '' || _this2.dataForm.playProducts.length === 0) {
        callback(new Error('请选择参与类型和对应规则'));
      } else {
        callback();
      }
    };
    return {
      activeName: 'first',
      categoryProps: {
        value: 'id',
        label: 'name',
        children: 'childList',
        expandTrigger: 'hover',
        checkStrictly: false,
        emitPath: false,
        multiple: true
      },
      treeList: [],
      loading: false,
      loadingFrom: false,
      rules: {
        // 表单验证参数
        tagName: [{
          required: true,
          message: '请输入标签名称',
          trigger: 'blur'
        }],
        timerange: [{
          required: true,
          message: '请选择生效时间区间',
          trigger: 'change'
        }],
        sort: [{
          required: true,
          message: '请输入排序',
          trigger: 'blur'
        }],
        playType: [{
          required: true,
          message: '请选择参与类型和对应规则',
          trigger: 'blur',
          validator: validatePlayTypeAndPlayProducts
        }]
      },
      // 初始化表单数据
      dataForm: {
        tagName: '',
        timerange: [],
        sort: 0,
        playType: 'product',
        playProducts: '',
        position: 0,
        proBrandList: []
      },
      pickerOptionsForEditCoupon: {
        // 时间有效校验
        disabledDate: function disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      formValidate: {
        products: null,
        proBrandList: [],
        proCategorylist: [],
        merIds: []
      },
      playValues: null // 待添加的产品参与类型
    };
  },
  created: function created() {
    if (this.$route.params.id) {
      this.initEditData();
    }
  },
  methods: {
    initEditData: function initEditData() {
      var _this3 = this;
      this.loading = true;
      (0, _product.productTagInfoApi)(this.$route.params.id).then(function (res) {
        _this3.dataForm = _objectSpread(_objectSpread({}, res), {}, {
          timerange: []
        });
        if (res.startTime && res.endTime) {
          _this3.dataForm.timerange = [new Date(res.startTime), new Date(res.endTime)];
        }
        // 初始化根据条件加载对应产品的条件
        _this3.getRecommendedInfo();
        _this3.loading = false;
      }).catch(function (res) {
        _this3.loading = false;
      });
    },
    // 点击产品图
    modalPicTap: function modalPicTap(tit) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (!img) return;
        _this.dataForm.icon = img[0].sattDir;
      }, tit, 'product');
    },
    handleClose: function handleClose() {
      this.$refs['dataForm'].resetFields();
      this.$emit('onCloseHandle');
    },
    onClose: function onClose() {
      this.$emit('onCloseHandle');
    },
    handleStatusChange: function handleStatusChange(op) {},
    onchangeTime: function onchangeTime(e) {
      this.dataForm.startTime = e ? e[0] : '';
      this.dataForm.endTime = e ? e[1] : '';
    },
    onsubmit: function onsubmit(formName) {
      var _this4 = this;
      // 组装当前根据类型参数的业务id
      if (!this.dataForm.owner || this.dataForm.owner > 0) {
        if (this.playValues) {
          // 如果编辑过数据那么再转换格式
          if (this.dataForm.playType === 'product') {
            this.dataForm.playProducts = this.playValues.map(function (item) {
              return item.id;
            }).join(',');
          } else {
            this.dataForm.playProducts = this.playValues.join(',');
          }
        }
      }
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this4.loading = true;
          if (_this4.dataForm.id) {
            storeApi.productTagUpdateApi(_this4.dataForm).then(function () {
              _this4.$message.success('编辑成功');
              _this4.$router.push({
                path: "/product/tag"
              });
            }).catch(function (err) {}).finally(function () {
              _this4.loading = false;
            });
          } else {
            storeApi.productTagAddApi(_this4.dataForm).then(function () {
              _this4.$message.success('新增成功');
              _this4.$router.push({
                path: "/product/tag"
              });
            }).catch(function (err) {}).finally(function () {
              _this4.loading = false;
            });
          }
        } else {
          return false;
        }
      });
    },
    //模板数据
    getRecommendedInfo: function getRecommendedInfo() {
      switch (this.dataForm.playType) {
        case 'product':
          this.dataForm.data = this.dataForm.playProducts;
          break;
        case 'brand':
          this.dataForm.proBrandList = this.dataForm.playProducts.includes(',') ? this.dataForm.playProducts.split(',').map(function (item) {
            return Number(item);
          }) : [Number(this.dataForm.playProducts)];
          break;
        case 'category':
          this.dataForm.proCategorylist = this.dataForm.playProducts.split(',').map(function (item) {
            return Number(item);
          });
          break;
        case 'merchant':
          if (this.dataForm.playProducts) {
            this.dataForm.merIds = this.dataForm.playProducts.split(',').map(function (item) {
              return Number(item);
            });
          }
          break;
      }
    },
    getProductAssociationData: function getProductAssociationData(res) {
      this.playValues = res;
      this.dataForm.playProducts = this.playValues.join(',');
      this.dataForm.proBrandList = JSON.parse(JSON.stringify(this.playValues));
    },
    handlePlayTypeChange: function handlePlayTypeChange() {
      this.playValues = '';
      this.dataForm.playProducts = '';
      this.dataForm.data = '';
      this.dataForm.proBrandList = [];
      this.dataForm.proCategorylist = [];
      this.dataForm.merIds = [];
    }
  }
};