var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "el-menu-horizontal-warp" },
    [
      _c(
        "el-scrollbar",
        {
          ref: "elMenuHorizontalScrollRef",
          nativeOn: {
            wheel: function ($event) {
              $event.preventDefault()
              return _vm.onElMenuHorizontalScroll($event)
            },
          },
        },
        [
          _c(
            "el-menu",
            {
              attrs: {
                router: "",
                "default-active": _vm.activePath || _vm.defaultActive,
                "background-color": "transparent",
                mode: "horizontal",
              },
              on: { select: _vm.onHorizontalSelect },
            },
            [
              _vm._l(_vm.menuList, function (val) {
                return [
                  val.children && val.children.length > 0
                    ? _c(
                        "el-submenu",
                        { key: val.path, attrs: { index: val.path } },
                        [
                          _c("template", { slot: "title" }, [
                            _c("span", [_vm._v(_vm._s(val.title))]),
                          ]),
                          _vm._v(" "),
                          _c("SubItem", { attrs: { chil: val.children } }),
                        ],
                        2
                      )
                    : [
                        _c(
                          "el-menu-item",
                          { key: val.path, attrs: { index: val.path } },
                          [
                            !val.isLink || (val.isLink && val.isIframe)
                              ? _c("template", { slot: "title" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(val.title) +
                                      "\n            "
                                  ),
                                ])
                              : _c("template", { slot: "title" }, [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: val.isLink,
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _c("Icon", {
                                        attrs: {
                                          type: val.icon ? val.icon : "",
                                        },
                                      }),
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(val.title) +
                                          "\n              "
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                          ],
                          2
                        ),
                      ],
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }