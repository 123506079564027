"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _debounce = _interopRequireDefault(require("@/libs/debounce"));
var _product = require("@/api/product");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'batchAudit',
  props: {
    idList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      formData: {
        reason: '',
        auditStatus: 'success',
        idList: []
      },
      dialogVisible: false,
      rules: {
        auditStatus: [{
          required: true,
          message: '请选择审核状态',
          trigger: 'change'
        }],
        reason: [{
          required: true,
          message: '请填写拒绝原因',
          trigger: 'blur'
        }]
      },
      isAppend: true
    };
  },
  methods: {
    handleClose: function handleClose() {
      this.dialogVisible = false;
      this.formData = {
        reason: '',
        auditStatus: 'success',
        idList: []
      };
    },
    /** 提交按钮 */
    submitForm: (0, _debounce.default)(function (formData) {
      var _this = this;
      this.$refs[formData].validate(function (valid) {
        if (valid) {
          _this.formData.idList = _this.idList;
          (0, _product.productBatchAuditApi)(_this.formData).then(function (response) {
            _this.$modal.msgSuccess('操作成功');
            _this.handleClose();
            _this.$emit('subBatchAuditSuccess');
          });
        }
      });
    })
  }
};