var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-input",
        _vm._b({ attrs: { readonly: "" } }, "el-input", _vm.$attrs, false),
        [
          _c("i", {
            staticClass: "el-input__icon el-icon-user color-primary",
            attrs: { slot: "suffix" },
            on: {
              click: function ($event) {
                _vm.show = true
              },
            },
            slot: "suffix",
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.show, title: "机构选择", "append-to-body": "" },
          on: {
            "update:visible": function ($event) {
              _vm.show = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              attrs: {
                "highlight-current-row": "",
                "row-key": "id",
                data: _vm.dataList,
              },
              on: { "current-change": _vm.handleCurrentChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", label: "序号", width: "60px" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "name", label: "机构名称" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            attrs: {
              total: _vm.pager.total,
              "current-page": _vm.pager.page,
              "page-size": _vm.pager.limit,
              layout: "prev, pager, next",
            },
            on: {
              "size-change": _vm.getOrgList,
              "current-change": _vm.getOrgList,
              "update:currentPage": function ($event) {
                return _vm.$set(_vm.pager, "page", $event)
              },
              "update:current-page": function ($event) {
                return _vm.$set(_vm.pager, "page", $event)
              },
              "update:pageSize": function ($event) {
                return _vm.$set(_vm.pager, "limit", $event)
              },
              "update:page-size": function ($event) {
                return _vm.$set(_vm.pager, "limit", $event)
              },
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.show = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }