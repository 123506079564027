var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-columns-aside" },
    [
      _c(
        "el-scrollbar",
        [
          _c("Logo"),
          _vm._v(" "),
          _c(
            "ul",
            [
              _vm._l(_vm.columnsAsideList, function (v, k) {
                return _c(
                  "li",
                  {
                    key: k,
                    ref: "columnsAsideOffsetTopRefs",
                    refInFor: true,
                    staticClass: "layout-columns",
                    class: { "layout-columns-active": v.k === _vm.liIndex },
                    attrs: { title: v.title },
                    on: {
                      click: function ($event) {
                        return _vm.onColumnsAsideMenuClick(v)
                      },
                    },
                  },
                  [
                    _c("div", { class: _vm.setColumnsAsidelayout }, [
                      _c("i", { class: "el-icon-" + v.icon }),
                      _vm._v(" "),
                      _c("div", { staticClass: "font13" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              v.title && v.title.length >= 4
                                ? v.title.substr(
                                    0,
                                    _vm.setColumnsAsidelayout ===
                                      "columns-vertical"
                                      ? 4
                                      : 3
                                  )
                                : v.title
                            ) +
                            "\n          "
                        ),
                      ]),
                    ]),
                  ]
                )
              }),
              _vm._v(" "),
              _c("div", {
                ref: "columnsAsideActiveRef",
                class: _vm.setColumnsAsideStyle,
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }