var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "box-card",
          attrs: {
            bordered: false,
            shadow: "never",
            "body-style": { padding: "40px 50px" },
          },
        },
        [
          _vm.isShow &&
          _vm.checkPermi([
            "platform:integral:get:config",
            "platform:integral:update:config",
          ])
            ? _c("z-b-parser", {
                attrs: {
                  "form-name": _vm.formName,
                  "is-create": _vm.isCreate,
                  "edit-data": _vm.editData,
                },
                on: { submit: _vm.handlerSubmit },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }