var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.configObj["tabConfig"]["tabVal"] === 0
    ? _c("div", { staticClass: "acea-row row-between borderPadding mb20" }, [
        _vm.configData.title
          ? _c("div", { staticClass: "title-tips labelwidth hot-title" }, [
              _c("span", [_vm._v(_vm._s(_vm.configData.title))]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "radio-box labelml" },
          [
            _vm._l(_vm.configData.list, function (item, index) {
              return [
                _c(
                  "el-radio",
                  {
                    attrs: { label: item.label },
                    model: {
                      value: _vm.configData.checkoutVal,
                      callback: function ($$v) {
                        _vm.$set(_vm.configData, "checkoutVal", $$v)
                      },
                      expression: "configData.checkoutVal",
                    },
                  },
                  [_vm._v(_vm._s(item.value))]
                ),
              ]
            }),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }