"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _storage = require("@/utils/storage.js");
var _vuex = require("vuex");
var _util = require("@/utils/util");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; } //
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'layout',
  components: {
    Defaults: function Defaults() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/main/defaults.vue'));
      });
    },
    Classic: function Classic() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/main/classic.vue'));
      });
    },
    Transverse: function Transverse() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/main/transverse.vue'));
      });
    },
    Columns: function Columns() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/main/columns.vue'));
      });
    },
    Mains: function Mains() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/component/main.vue'));
      });
    }
  },
  data: function data() {
    return {
      headMenuNoShow: false
    };
  },
  computed: {
    // 获取布局配置信息
    getThemeConfig: function getThemeConfig() {
      return this.$store.state.themeConfig.themeConfig;
    },
    tagNavList: function tagNavList() {
      return this.$store.state.menu.tagNavList;
    },
    routesList: function routesList() {
      return this.$store.state.user.menuList;
    }
  },
  watch: {
    $route: function $route(newRoute) {
      this.headMenuNoShow = this.$route.meta.fullScreen;
      var name = newRoute.name,
        query = newRoute.query,
        params = newRoute.params,
        meta = newRoute.meta,
        path = newRoute.path;
      this.addTag({
        route: {
          name: name,
          query: query,
          params: params,
          meta: meta,
          path: path
        },
        type: 'push'
      });
      this.setBreadCrumb(newRoute);
      this.setTagNavList((0, _util.getNewTagList)(this.tagNavList, newRoute));
    }
  },
  created: function created() {
    this.headMenuNoShow = this.$route.meta.fullScreen;
    this.onLayoutResize();
    window.addEventListener('resize', this.onLayoutResize);
  },
  methods: _objectSpread(_objectSpread({}, (0, _vuex.mapMutations)('menu', ['setBreadCrumb', 'setTagNavList', 'addTag', 'setLocal', 'setHomeRoute', 'closeTag'])), {}, {
    // 窗口大小改变时(适配移动端)
    onLayoutResize: function onLayoutResize() {
      if (!_storage.Local.get('oldLayout')) _storage.Local.set('oldLayout', this.$store.state.themeConfig.themeConfig.layout);
      var clientWidth = document.body.clientWidth;
      if (clientWidth < 1000) {
        this.$store.state.themeConfig.themeConfig.isCollapse = false;
        this.bus.$emit('layoutMobileResize', {
          layout: 'defaults',
          clientWidth: clientWidth
        });
      } else {
        this.bus.$emit('layoutMobileResize', {
          layout: _storage.Local.get('oldLayout') ? _storage.Local.get('oldLayout') : this.$store.state.themeConfig.themeConfig.layout,
          clientWidth: clientWidth
        });
      }
    }
  }),
  distroyed: function distroyed() {
    window.removeEventListener('resize', this.onLayoutResize);
  }
};