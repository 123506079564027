var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.isTagHistory ? "h100" : "h101" },
    [
      _c(
        "transition",
        { attrs: { name: _vm.setTransitionName, mode: "out-in" } },
        [
          _c(
            "keep-alive",
            { attrs: { include: _vm.keepAliveNameList } },
            [_c("router-view", { key: _vm.refreshRouterViewKey })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }