var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          staticClass: "projectInfo",
          attrs: {
            title: "产品审核",
            visible: _vm.dialogVisible,
            "append-to-body": _vm.isAppend,
            width: "540px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formData",
              staticClass: "demo-formData",
              attrs: {
                model: _vm.formData,
                rules: _vm.rules,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "审核状态", prop: "auditStatus" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.formData.auditStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "auditStatus", $$v)
                        },
                        expression: "formData.auditStatus",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "success" } }, [
                        _vm._v("通过"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "fail" } }, [
                        _vm._v("拒绝"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.formData.auditStatus === "fail"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "原因", prop: "reason" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea", placeholder: "请输入原因" },
                        model: {
                          value: _vm.formData.reason,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "reason", $$v)
                          },
                          expression: "formData.reason",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("el-form-item", [
                _c(
                  "div",
                  { staticClass: "dialog-footer-inner" },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            _vm.dialogVisible = false
                          },
                        },
                      },
                      [_vm._v("取消")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.submitForm("formData")
                          },
                        },
                      },
                      [_vm._v("提交")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }