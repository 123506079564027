var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        {
          directives: [
            {
              name: "hasPermi",
              rawName: "v-hasPermi",
              value: ["platform:wechat:mini:url:link:page:list"],
              expression: "['platform:wechat:mini:url:link:page:list']",
            },
          ],
          staticClass: "box-card",
          attrs: {
            shadow: "never",
            bordered: false,
            "body-style": { padding: 0 },
          },
        },
        [
          _c(
            "div",
            { staticClass: "padding-add" },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { inline: "", model: _vm.tableFrom },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "链接名称：" } },
                    [
                      _c("el-input", {
                        staticClass: "selWidth",
                        attrs: {
                          placeholder: "请输入链接名称",
                          size: "small",
                          clearable: "",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.getList(1)
                          },
                        },
                        model: {
                          value: _vm.keywords,
                          callback: function ($$v) {
                            _vm.keywords =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "keywords",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.getList(1)
                            },
                          },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card mt14",
          attrs: {
            "body-style": { padding: "20px" },
            shadow: "never",
            bordered: false,
          },
        },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "hasPermi",
                  rawName: "v-hasPermi",
                  value: ["platform:wechat:mini:generate:url:link"],
                  expression: "['platform:wechat:mini:generate:url:link']",
                },
              ],
              staticClass: "mb20",
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.handlerAdd },
            },
            [_vm._v("新增链接")]
          ),
          _vm._v(" "),
          _c("el-alert", {
            staticClass: "mb20",
            attrs: {
              title:
                "突破低复购率的瓶颈，用户可通过点击短信、邮件、微信外网页等短链接直接跳转进入商城，并最终在商城实现私域用户沉淀和转化。",
              type: "warning",
              closable: false,
              effect: "light",
            },
          }),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { data: _vm.tableData.data, size: "small" },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", "min-width": "90" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "链接名称",
                  "min-width": "150",
                  prop: "name",
                  "show-overflow-tooltip": true,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  "min-width": "250",
                  label: "跳转页面",
                  prop: "originalPath",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  "min-width": "200",
                  label: "生成链接",
                  prop: "urlLink",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  "min-width": "100",
                  label: "有效期(天)",
                  prop: "expireInterval",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "生成时间",
                  "min-width": "150",
                  prop: "createTime",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "address",
                  fixed: "right",
                  width: "190",
                  label: "操作",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "a",
                          {
                            staticClass: "copy copy-data",
                            attrs: { "data-clipboard-text": scope.row.urlLink },
                            on: { click: _vm.handleCopy },
                          },
                          [_vm._v("复制链接")]
                        ),
                        _vm._v(" "),
                        _c("el-divider", { attrs: { direction: "vertical" } }),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: [
                                  "platform:wechat:mini:generate:url:link",
                                ],
                                expression:
                                  "['platform:wechat:mini:generate:url:link']",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.handleRegenerate(scope.row)
                              },
                            },
                          },
                          [_vm._v("再次生成")]
                        ),
                        _vm._v(" "),
                        _c("el-divider", { attrs: { direction: "vertical" } }),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["platform:wechat:mini:delete:url:link"],
                                expression:
                                  "['platform:wechat:mini:delete:url:link']",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v("`\n    "),
          _c(
            "div",
            { staticClass: "block-pagination" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "current-change": _vm.pageChange,
                  "size-change": _vm.handleSizeChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新增链接",
            visible: _vm.dialogVisible,
            width: "700px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formData",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.formData,
                rules: _vm.rules,
                "label-width": "90px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "链接名称：", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "50",
                      placeholder: "请输入链接名称（最多50字）",
                    },
                    model: {
                      value: _vm.formData.name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formData,
                          "name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formData.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "跳转页面：", prop: "originalPath" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: {
                        size: "small",
                        icon: "ios-arrow-forward",
                        readonly: "",
                        placeholder: "请选择链接",
                      },
                      on: { click: _vm.handleGetLink },
                      model: {
                        value: _vm.formData.originalPath,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "originalPath", $$v)
                        },
                        expression: "formData.originalPath",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: { slot: "append", icon: "el-icon-arrow-right" },
                        on: { click: _vm.handleGetLink },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "有效期(天)：", prop: "expireInterval" } },
                [
                  _c("el-input-number", {
                    attrs: {
                      min: 1,
                      step: 1,
                      "step-strictly": "",
                      max: 30,
                      placeholder: "范围在1~30，默认30",
                    },
                    model: {
                      value: _vm.formData.expireInterval,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formData,
                          "expireInterval",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formData.expireInterval",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "from-tips" }, [
                    _vm._v("根据微信接口要求，链接有效期最长为30天"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["platform:express:update"],
                      expression: "['platform:express:update']",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("formData")
                    },
                  },
                },
                [_vm._v("生成链接")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("linkaddress", {
        ref: "linkaddres",
        attrs: { fromType: "linkGenerator" },
        on: { linkUrl: _vm.getLinkUrl },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }