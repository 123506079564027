var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-navbars-breadcrumb-index" },
    [
      _vm.setIsShowLogo ? _c("Logo") : _vm._e(),
      _vm._v(" "),
      _c("Breadcrumb"),
      _vm._v(" "),
      _vm.isLayoutTransverse
        ? _c("Horizontal", { attrs: { menuList: _vm.menuList } })
        : _vm._e(),
      _vm._v(" "),
      _vm.isLayoutClassic ? _c("transverseAside") : _vm._e(),
      _vm._v(" "),
      _c("User"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }