"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _FromList = _interopRequireDefault(require("@/components/FromList"));
var _productAssociationForm = _interopRequireDefault(require("@/components/productAssociationForm"));
var _systemPcConfig = require("@/api/systemPcConfig");
var _vuex = require("vuex");
var _permission = require("@/utils/permission");
var _defaultPcConfig = require("@/views/systemSetting/pcConfig/defaultPcConfig");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var fromData = {
  imageUrl: '',
  name: '',
  playType: 'product',
  sort: 0,
  status: false,
  data: '',
  id: 0,
  linkUrl: '',
  merIds: [],
  proBrandList: [],
  proCategorylist: []
};
var _default = exports.default = {
  name: 'homeSettings',
  components: {
    productAssociationForm: _productAssociationForm.default,
    FromList: _FromList.default
  },
  data: function data() {
    var _this2 = this;
    // 自定义组件校验规则
    var validatePlayTypeAndPlayProducts = function validatePlayTypeAndPlayProducts(rule, value, callback) {
      if (value === '' || _this2.dataForm.playProducts.length === 0) {
        callback(new Error('请选择参与类型和对应规则'));
      } else {
        callback();
      }
    };
    return {
      currentTab: '0',
      tabList: [{
        value: '0',
        title: '首页广告'
      }, {
        value: '1',
        title: '首页banner'
      }, {
        value: '2',
        title: '首页推荐'
      }, {
        value: '3',
        title: '顶部菜单'
      }],
      drawer: false,
      fullscreenLoading: false,
      ruleValidate: {
        name: [{
          required: true,
          message: '请输入名称',
          trigger: 'blur'
        }],
        imageUrl: [{
          required: true,
          message: '请选择图片',
          trigger: 'blur'
        }],
        playType: [{
          required: true,
          message: '请选择产品关联类型',
          trigger: 'blur',
          validator: validatePlayTypeAndPlayProducts
        }]
        // proBrandList: [{ required: true, message: '请选择品牌', trigger: 'change' }],
        // proCategorylist: [{ required: true, message: '请选择分类', trigger: 'change' }],
        // merIds: [{ type: 'array', required: true, message: '请至少选择一个商户', trigger: 'change' }],
      },
      dataForm: Object.assign({}, fromData),
      loadingBtn: false,
      tableData: {
        data: []
      },
      listLoading: false,
      productData: [],
      //选中的产品类型值
      // 首页广告语
      advertisementlistConfig: Object.assign({}, (0, _defaultPcConfig.advertisementDefault)()),
      // banner数据
      bannerListConfig: Object.assign({}, (0, _defaultPcConfig.bannerDefault)()),
      playValues: null,
      // 待添加的产品参与类型
      //顶部菜单
      menuListConfig: Object.assign({}, (0, _defaultPcConfig.menuDefault)())
    };
  },
  mounted: function mounted() {
    // if (this.id > 0) {
    //   this.getRecommendedInfo(this.id);
    // }
    if ((0, _permission.checkPermi)(['platform:pc:shopping:home:banner:get'])) this.getPcHomeBanner();
    if ((0, _permission.checkPermi)(['platform:pc:shopping:home:recommended:list'])) this.getList();
    if ((0, _permission.checkPermi)(['platform:pc:shopping:home:advertisement:get'])) this.getAdvertisement();
    if ((0, _permission.checkPermi)(['platform:pc:shopping:home:navigation:get'])) this.getPcHomeNavigation();
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapGetters)(['adminProductClassify', 'productBrand'])), {}, {
    isEdit: function isEdit() {
      return this.dataForm.id > 0 ? true : false;
    }
  }),
  methods: {
    checkPermi: _permission.checkPermi,
    //顶部菜单保存
    handleMenuSave: function handleMenuSave() {
      var _this3 = this;
      this.menuListConfig.list.map(function (item, index) {
        item.sort = index + 1;
      });
      this.loadingBtn = true;
      (0, _systemPcConfig.pcHomeNavigationSaveApi)(this.menuListConfig.list).then(function (res) {
        _this3.$message.success('保存成功');
        _this3.loadingBtn = false;
        _this3.getPcHomeNavigation();
      }).catch(function () {
        _this3.loadingBtn = false;
      });
    },
    //获取顶部菜单
    getPcHomeNavigation: function getPcHomeNavigation() {
      var _this4 = this;
      (0, _systemPcConfig.pcHomeNavigationGetApi)().then(function (res) {
        _this4.menuListConfig.list = res;
      });
    },
    //首页推荐模板列表
    getList: function getList(num) {
      var _this5 = this;
      this.listLoading = true;
      (0, _systemPcConfig.pcRecommendedListApi)().then(function (res) {
        _this5.tableData.data = res;
        _this5.listLoading = false;
      }).catch(function () {
        _this5.listLoading = false;
      });
    },
    //模板弹窗
    handleClose: function handleClose() {
      //this.$refs['formValidate'].resetFields();
      this.drawer = false;
    },
    //新增模板
    handleAdd: function handleAdd() {
      this.dataForm = Object.assign({}, fromData);
      this.drawer = true;
      this.loadingBtn = false;
    },
    //模板状态
    onchangeIsShow: function onchangeIsShow(row) {
      var _this6 = this;
      (0, _systemPcConfig.pcRecommendedSwitchApi)(row.id).then(function (res) {
        _this6.$message.success('操作成功');
        _this6.getList();
      });
    },
    //编辑首页推荐模板
    handleEdit: function handleEdit(row) {
      this.dataForm.id = row.id;
      Object.assign(this.dataForm, row);
      this.getRecommendedInfo(this.dataForm);
      this.drawer = true;
      this.loadingBtn = false;
    },
    //首页推荐模板数据
    getRecommendedInfo: function getRecommendedInfo() {
      // 以下两个属性的转换为组件和业务之间的，后期优化选择器统一所有业务数据后再配合后台修改统一优化
      this.dataForm.playType = this.dataForm.productAssociationType;
      this.dataForm.playProducts = this.dataForm.data;
      switch (this.dataForm.playType) {
        case 'product':
          this.dataForm.playProducts = this.dataForm.data;
          break;
        case 'brand':
          this.dataForm.proBrandList = Number(this.dataForm.data);
          break;
        case 'category':
          this.dataForm.proCategorylist = Number(this.dataForm.data);
          break;
        case 'merchant':
          this.dataForm.merIds = this.dataForm.data.split(',').map(function (item) {
            return Number(item);
          });
          break;
      }
    },
    // 删除首页推荐
    handleDelete: function handleDelete(id, idx) {
      var _this7 = this;
      this.$modalSure('删除该模块吗？').then(function () {
        (0, _systemPcConfig.pcRecommendedDeleteApi)(id).then(function (res) {
          _this7.$message.success('删除成功');
          _this7.getList();
        });
      });
    },
    //选择产品类型中，产品列表、商户（merchant）选中回调
    getProductAssociationData: function getProductAssociationData(res) {
      this.playValues = res;
      this.dataForm.playProducts = this.playValues;
      this.dataForm.merIds = this.playValues;
      this.dataForm.proBrandList = JSON.parse(JSON.stringify(this.playValues));
    },
    //上传图片
    modalPicTap: function modalPicTap(type, num) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (!img) return;
        _this.dataForm.imageUrl = img[0].sattDir;
      }, type, 'imageUrl');
    },
    //推荐模板保存
    handleRecommendedSave: function handleRecommendedSave(formName) {
      var _this8 = this;
      if (this.playValues) {
        // 如果编辑过数据那么再转换格式
        if (this.dataForm.playType === 'product') {
          this.dataForm.data = this.playValues.map(function (item) {
            return item.id;
          }).join(',');
        } else if (this.dataForm.playType === 'merchant') {
          this.dataForm.data = this.playValues.map(function (item) {
            return item;
          }).join(',');
        } else {
          this.dataForm.data = this.playValues;
        }
        this.dataForm.productAssociationType = this.dataForm.playType;
      }
      if (this.dataForm.style == '') return this.$message.error('请上传氛围图');
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this8.loadingBtn = true;
          _this8.isEdit ? (0, _systemPcConfig.pcRecommendedEditApi)(_this8.dataForm).then(function (res) {
            _this8.$message.success('编辑成功');
            _this8.handleClose();
            _this8.getList();
          }).catch(function () {
            _this8.loadingBtn = false;
          }) : (0, _systemPcConfig.pcRecommendedAddApi)(_this8.dataForm).then(function (res) {
            _this8.$message.success('新增成功');
            _this8.handleClose();
            _this8.getList();
          }).catch(function () {
            _this8.loadingBtn = false;
          });
        } else {
          return false;
        }
      });
    },
    //广告语数据
    getAdvertisement: function getAdvertisement() {
      var _this9 = this;
      (0, _systemPcConfig.pcHomeAdvertisementGetApi)().then(function (res) {
        _this9.advertisementlistConfig.list[0].id = res.id;
        _this9.advertisementlistConfig.list[0].imageUrl = res.imageUrl;
        _this9.advertisementlistConfig.list[0].linkUrl = res.linkUrl;
        _this9.advertisementlistConfig.list[0].status = res.status;
      });
    },
    //广告语
    handleAdvertisementSave: function handleAdvertisementSave() {
      var _this10 = this;
      this.loadingBtn = true;
      (0, _systemPcConfig.pcHomeAdvertisementEditApi)({
        id: this.advertisementlistConfig.list[0].id,
        imageUrl: this.advertisementlistConfig.list[0].imageUrl,
        linkUrl: this.advertisementlistConfig.list[0].linkUrl,
        status: this.advertisementlistConfig.list[0].status
      }).then(function (res) {
        _this10.$message.success('保存成功');
        _this10.loadingBtn = false;
        _this10.getAdvertisement();
      }).catch(function () {
        _this10.loadingBtn = false;
      });
    },
    //banner新增
    handleBannerSave: function handleBannerSave() {
      var _this11 = this;
      this.bannerListConfig.list.map(function (item, index) {
        item.sort = index + 1;
      });
      this.loadingBtn = true;
      (0, _systemPcConfig.pcHomeBannerSaveApi)({
        bannerList: this.bannerListConfig.list
      }).then(function (res) {
        _this11.$message.success('保存成功');
        _this11.loadingBtn = false;
        _this11.getPcHomeBanner();
      }).catch(function () {
        _this11.loadingBtn = false;
      });
    },
    //banner数据
    getPcHomeBanner: function getPcHomeBanner() {
      var _this12 = this;
      (0, _systemPcConfig.pcHomeBannerGetApi)().then(function (res) {
        _this12.bannerListConfig.list = res.bannerList;
      });
    },
    handlePlayTypeChange: function handlePlayTypeChange() {
      this.playValues = '';
      this.dataForm.playProducts = '';
      this.dataForm.data = '';
      this.dataForm.proBrandList = [];
      this.dataForm.proCategorylist = [];
      this.dataForm.merIds = [];
    }
  }
};