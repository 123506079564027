var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.configObj
    ? _c("div", [
        _c(
          "div",
          { staticClass: "mobile-page paddingBox", style: _vm.boxStyle },
          [
            _c(
              "div",
              { staticClass: "home_product" },
              [
                _vm.itemStyle == 0
                  ? [
                      _vm.list.length > 0
                        ? _c(
                            "div",
                            { staticClass: "list-wrapper itemA" },
                            _vm._l(_vm.list, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "item",
                                  style: {
                                    marginBottom: _vm.contentConfig + "px",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "img-box" }, [
                                    item.image
                                      ? _c("img", {
                                          style: _vm.contentStyle,
                                          attrs: { src: item.image, alt: "" },
                                        })
                                      : _c(
                                          "div",
                                          {
                                            staticClass: "empty-box",
                                            style: _vm.contentStyle,
                                          },
                                          [
                                            _c("span", {
                                              staticClass: "iconfont icon-tu",
                                            }),
                                          ]
                                        ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "info" }, [
                                    _c("div", { staticClass: "hd" }, [
                                      _c("div", { staticClass: "text" }, [
                                        _c(
                                          "div",
                                          { staticClass: "title line2" },
                                          [
                                            _vm.titleShow
                                              ? _c(
                                                  "span",
                                                  { style: _vm.titleColor },
                                                  [_vm._v(_vm._s(item.name))]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "price",
                                        style: _vm.priceColor,
                                      },
                                      [
                                        _vm.priceShow
                                          ? _c("div", { staticClass: "num" }, [
                                              _vm._v("\n                  ￥"),
                                              _c("span", [
                                                _vm._v(_vm._s(item.price)),
                                              ]),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.soldShow
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "sold",
                                            style: _vm.soldColor,
                                          },
                                          [
                                            _vm._v(
                                              "\n                已售 " +
                                                _vm._s(
                                                  Math.floor(item.sales) +
                                                    Math.floor(item.ficti) || 0
                                                ) +
                                                " " +
                                                _vm._s(item.unitName) +
                                                "\n              "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]
                              )
                            }),
                            0
                          )
                        : _c("div", { staticClass: "list-wrapper itemA" }, [
                            _c(
                              "div",
                              {
                                staticClass: "item",
                                style: {
                                  marginBottom: _vm.contentConfig + "px",
                                },
                              },
                              [
                                _c("div", { staticClass: "img-box" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "empty-box",
                                      style: _vm.contentStyle,
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "iconfont icon-tu",
                                      }),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "info" }, [
                                  _c("div", { staticClass: "hd" }, [
                                    _c("div", { staticClass: "text" }, [
                                      _c(
                                        "div",
                                        { staticClass: "title line2" },
                                        [
                                          _vm.titleShow
                                            ? _c(
                                                "span",
                                                { style: _vm.titleColor },
                                                [_vm._v("产品名称")]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "price",
                                      style: _vm.priceColor,
                                    },
                                    [
                                      _vm.priceShow
                                        ? _c("div", { staticClass: "num" }, [
                                            _vm._v("¥"),
                                            _c("span", [_vm._v("199")]),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.soldShow
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "sold",
                                          style: _vm.soldColor,
                                        },
                                        [_vm._v("已售 999 件")]
                                      )
                                    : _vm._e(),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "item",
                                style: {
                                  marginBottom: _vm.contentConfig + "px",
                                },
                              },
                              [
                                _c("div", { staticClass: "img-box" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "empty-box",
                                      style: _vm.contentStyle,
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "iconfont icon-tu",
                                      }),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "info" }, [
                                  _c("div", { staticClass: "hd" }, [
                                    _c("div", { staticClass: "text" }, [
                                      _c(
                                        "div",
                                        { staticClass: "title line2" },
                                        [
                                          _vm.titleShow
                                            ? _c(
                                                "span",
                                                { style: _vm.titleColor },
                                                [_vm._v("产品名称")]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "price",
                                      style: _vm.priceColor,
                                    },
                                    [
                                      _vm.priceShow
                                        ? _c("div", { staticClass: "num" }, [
                                            _vm._v("¥"),
                                            _c("span", [_vm._v("199")]),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.soldShow
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "sold",
                                          style: _vm.soldColor,
                                        },
                                        [_vm._v("已售 999 件")]
                                      )
                                    : _vm._e(),
                                ]),
                              ]
                            ),
                          ]),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.itemStyle == 1
                  ? [
                      _vm.list.length > 0
                        ? _c(
                            "div",
                            {
                              staticClass: "list-wrapper itemC listC",
                              style: { gridGap: _vm.contentConfig + "px" },
                            },
                            _vm._l(_vm.list, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "item auto",
                                  staticStyle: { background: "#fff" },
                                },
                                [
                                  _c("div", { staticClass: "img-box" }, [
                                    item.image
                                      ? _c("img", {
                                          style: _vm.contentStyle,
                                          attrs: { src: item.image, alt: "" },
                                        })
                                      : _c(
                                          "div",
                                          {
                                            staticClass: "empty-box",
                                            style: _vm.contentStyle,
                                          },
                                          [
                                            _c("span", {
                                              staticClass: "iconfont icon-tu",
                                            }),
                                          ]
                                        ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "info" }, [
                                    _c("div", { staticClass: "hd line2" }, [
                                      _vm.titleShow
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "title",
                                              style: _vm.titleColor,
                                            },
                                            [_vm._v(_vm._s(item.name))]
                                          )
                                        : _vm._e(),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "price",
                                        style: _vm.priceColor,
                                      },
                                      [
                                        _vm.priceShow
                                          ? _c("div", { staticClass: "num" }, [
                                              _vm._v("\n                  ¥"),
                                              _c("span", [
                                                _vm._v(_vm._s(item.price)),
                                              ]),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.soldShow
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "sold",
                                            style: _vm.soldColor,
                                          },
                                          [
                                            _vm._v(
                                              "\n                已售 " +
                                                _vm._s(
                                                  Math.floor(item.sales) +
                                                    Math.floor(item.ficti) || 0
                                                ) +
                                                " " +
                                                _vm._s(item.unitName) +
                                                "\n              "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]
                              )
                            }),
                            0
                          )
                        : _c(
                            "div",
                            {
                              staticClass: "list-wrapper listC",
                              style: { gridGap: _vm.contentConfig + "px" },
                            },
                            [
                              _c("div", { staticClass: "item auto" }, [
                                _c("div", { staticClass: "img-box" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "empty-box",
                                      style: _vm.contentStyle,
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "iconfont icon-tu",
                                      }),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "info" }, [
                                  _c("div", { staticClass: "hd acea-row" }, [
                                    _vm.titleShow
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "title line2",
                                            style: _vm.titleColor,
                                          },
                                          [_vm._v("产品名称")]
                                        )
                                      : _vm._e(),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "price",
                                      style: _vm.priceColor,
                                    },
                                    [
                                      _vm.priceShow
                                        ? _c("div", { staticClass: "num" }, [
                                            _vm._v("¥"),
                                            _c("span", [_vm._v("66.66")]),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.soldShow
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "sold",
                                          style: _vm.soldColor,
                                        },
                                        [_vm._v("已售 999 件")]
                                      )
                                    : _vm._e(),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "item auto" }, [
                                _c("div", { staticClass: "img-box" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "empty-box",
                                      style: _vm.contentStyle,
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "iconfont icon-tu",
                                      }),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "info" }, [
                                  _c("div", { staticClass: "hd acea-row" }, [
                                    _vm.titleShow
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "title line2",
                                            style: _vm.titleColor,
                                          },
                                          [_vm._v("产品名称")]
                                        )
                                      : _vm._e(),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "price",
                                      style: _vm.priceColor,
                                    },
                                    [
                                      _vm.priceShow
                                        ? _c("div", { staticClass: "num" }, [
                                            _vm._v("¥"),
                                            _c("span", [_vm._v("66.66")]),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.soldShow
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "sold",
                                          style: _vm.soldColor,
                                        },
                                        [_vm._v("已售 999 件")]
                                      )
                                    : _vm._e(),
                                ]),
                              ]),
                            ]
                          ),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.itemStyle == 2
                  ? [
                      _vm.list.length > 0
                        ? _c(
                            "div",
                            {
                              staticClass: "list-wrapper itemB",
                              style: { gridGap: _vm.contentConfig + "px" },
                            },
                            _vm._l(_vm.list, function (item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "item auto" },
                                [
                                  _c("div", { staticClass: "img-box" }, [
                                    item.image
                                      ? _c("img", {
                                          style: _vm.contentStyle,
                                          attrs: { src: item.image, alt: "" },
                                        })
                                      : _c(
                                          "div",
                                          {
                                            staticClass: "empty-box",
                                            style: _vm.contentStyle,
                                          },
                                          [
                                            _c("span", {
                                              staticClass: "iconfont icon-tu",
                                            }),
                                          ]
                                        ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "info" }, [
                                    _c("div", { staticClass: "hd line2" }, [
                                      _vm.titleShow
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "title",
                                              style: _vm.titleColor,
                                            },
                                            [_vm._v(_vm._s(item.name))]
                                          )
                                        : _vm._e(),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "price",
                                        style: _vm.priceColor,
                                      },
                                      [
                                        _vm.priceShow
                                          ? _c("div", { staticClass: "num" }, [
                                              _vm._v("\n                  ¥"),
                                              _c("span", [
                                                _vm._v(_vm._s(item.price)),
                                              ]),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.soldShow
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "sold",
                                            style: _vm.soldColor,
                                          },
                                          [
                                            _vm._v(
                                              "\n                已售 " +
                                                _vm._s(
                                                  Math.floor(item.sales) +
                                                    Math.floor(item.ficti) || 0
                                                ) +
                                                " " +
                                                _vm._s(item.unitName) +
                                                "\n              "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]
                              )
                            }),
                            0
                          )
                        : _c(
                            "div",
                            {
                              staticClass: "list-wrapper itemB",
                              style: { gridGap: _vm.contentConfig + "px" },
                            },
                            [
                              _c("div", { staticClass: "item auto" }, [
                                _c("div", { staticClass: "img-box" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "empty-box",
                                      style: _vm.contentStyle,
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "iconfont icon-tu",
                                      }),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "info" }, [
                                  _c("div", { staticClass: "hd acea-row" }, [
                                    _vm.titleShow
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "title line2",
                                            style: _vm.titleColor,
                                          },
                                          [_vm._v("产品名称")]
                                        )
                                      : _vm._e(),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "price",
                                      style: _vm.priceColor,
                                    },
                                    [
                                      _vm.priceShow
                                        ? _c("div", { staticClass: "num" }, [
                                            _vm._v("¥"),
                                            _c("span", [_vm._v("66.66")]),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.soldShow
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "sold",
                                          style: _vm.soldColor,
                                        },
                                        [_vm._v("已售 999 件")]
                                      )
                                    : _vm._e(),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "item auto" }, [
                                _c("div", { staticClass: "img-box" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "empty-box",
                                      style: _vm.contentStyle,
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "iconfont icon-tu",
                                      }),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "info" }, [
                                  _c("div", { staticClass: "hd acea-row" }, [
                                    _vm.titleShow
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "title line2",
                                            style: _vm.titleColor,
                                          },
                                          [_vm._v("产品名称")]
                                        )
                                      : _vm._e(),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "price",
                                      style: _vm.priceColor,
                                    },
                                    [
                                      _vm.priceShow
                                        ? _c("div", { staticClass: "num" }, [
                                            _vm._v("¥"),
                                            _c("span", [_vm._v("66.66")]),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.soldShow
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "sold",
                                          style: _vm.soldColor,
                                        },
                                        [_vm._v("已售 999 件")]
                                      )
                                    : _vm._e(),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "item auto" }, [
                                _c("div", { staticClass: "img-box" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "empty-box",
                                      style: _vm.contentStyle,
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "iconfont icon-tu",
                                      }),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "info" }, [
                                  _c("div", { staticClass: "hd acea-row" }, [
                                    _vm.titleShow
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "title line2",
                                            style: _vm.titleColor,
                                          },
                                          [_vm._v("产品名称")]
                                        )
                                      : _vm._e(),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "price",
                                      style: _vm.priceColor,
                                    },
                                    [
                                      _vm.priceShow
                                        ? _c("div", { staticClass: "num" }, [
                                            _vm._v("¥"),
                                            _c("span", [_vm._v("66.66")]),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.soldShow
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "sold",
                                          style: _vm.soldColor,
                                        },
                                        [_vm._v("已售 999 件")]
                                      )
                                    : _vm._e(),
                                ]),
                              ]),
                            ]
                          ),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.itemStyle == 3
                  ? [
                      _vm.list.length > 0
                        ? _c(
                            "div",
                            { staticClass: "listBig" },
                            _vm._l(_vm.list, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "itemBig",
                                  style: {
                                    marginBottom: _vm.contentConfig + "px",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "img-box" }, [
                                    item.image
                                      ? _c("img", {
                                          style: _vm.contentStyle,
                                          attrs: { src: item.image, alt: "" },
                                        })
                                      : _c(
                                          "div",
                                          {
                                            staticClass: "empty-box",
                                            style: _vm.contentStyle,
                                          },
                                          [
                                            _c("span", {
                                              staticClass: "iconfont icon-tu",
                                            }),
                                          ]
                                        ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "info" }, [
                                    _c("div", { staticClass: "hd line2" }, [
                                      _vm.titleShow
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "title",
                                              style: _vm.titleColor,
                                            },
                                            [_vm._v(_vm._s(item.name))]
                                          )
                                        : _vm._e(),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "price",
                                        style: _vm.priceColor,
                                      },
                                      [
                                        _vm.priceShow
                                          ? _c("div", { staticClass: "num" }, [
                                              _vm._v("\n                  ¥"),
                                              _c("span", [
                                                _vm._v(_vm._s(item.price)),
                                              ]),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.soldShow
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "sold",
                                            style: _vm.soldColor,
                                          },
                                          [
                                            _vm._v(
                                              "\n                已售 " +
                                                _vm._s(
                                                  Math.floor(item.sales) +
                                                    Math.floor(item.ficti) || 0
                                                ) +
                                                " " +
                                                _vm._s(item.unitName) +
                                                "\n              "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]
                              )
                            }),
                            0
                          )
                        : _c("div", { staticClass: "listBig" }, [
                            _c(
                              "div",
                              {
                                staticClass: "itemBig",
                                style: {
                                  marginBottom: _vm.contentConfig + "px",
                                },
                              },
                              [
                                _c("div", { staticClass: "img-box" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "empty-box",
                                      style: _vm.contentStyle,
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "iconfont icon-tu",
                                      }),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "info" }, [
                                  _c("div", { staticClass: "hd acea-row" }, [
                                    _vm.titleShow
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "title line2",
                                            style: _vm.titleColor,
                                          },
                                          [_vm._v("产品名称")]
                                        )
                                      : _vm._e(),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "price",
                                      style: _vm.priceColor,
                                    },
                                    [
                                      _vm.priceShow
                                        ? _c("div", { staticClass: "num" }, [
                                            _vm._v("¥"),
                                            _c("span", [_vm._v("66.66")]),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.soldShow
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "sold",
                                          style: _vm.soldColor,
                                        },
                                        [_vm._v("已售 999 件")]
                                      )
                                    : _vm._e(),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "itemBig",
                                style: {
                                  marginBottom: _vm.contentConfig + "px",
                                },
                              },
                              [
                                _c("div", { staticClass: "img-box" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "empty-box",
                                      style: _vm.contentStyle,
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "iconfont icon-tu",
                                      }),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "info" }, [
                                  _c("div", { staticClass: "hd acea-row" }, [
                                    _vm.titleShow
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "title line2",
                                            style: _vm.titleColor,
                                          },
                                          [_vm._v("产品名称")]
                                        )
                                      : _vm._e(),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "price",
                                      style: _vm.priceColor,
                                    },
                                    [
                                      _vm.priceShow
                                        ? _c("div", { staticClass: "num" }, [
                                            _vm._v("¥"),
                                            _c("span", [_vm._v("66.66")]),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.soldShow
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "sold",
                                          style: _vm.soldColor,
                                        },
                                        [_vm._v("已售 999 件")]
                                      )
                                    : _vm._e(),
                                ]),
                              ]
                            ),
                          ]),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }