var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.configData
    ? _c(
        "div",
        { staticClass: "c_product borderPadding" },
        [
          _c("div", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.configData.title)),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "list-box" },
            [
              _c(
                "draggable",
                {
                  staticClass: "dragArea list-group",
                  attrs: {
                    list: _vm.configData.list,
                    group: "peoples",
                    handle: ".move-icon",
                  },
                },
                _vm._l(_vm.configData.list, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "item",
                      on: {
                        click: function ($event) {
                          return _vm.activeBtn(index)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "move-icon" }, [
                        _c("span", {
                          staticClass: "iconfont icon-tuozhuaidian",
                          staticStyle: {
                            "font-size": "28px",
                            color: "#dddddd",
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content ml20" }, [
                        _c("div", { staticClass: "con-item" }, [
                          _c("span", [_vm._v("显示文字")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c("el-input", {
                                attrs: {
                                  size: "small",
                                  placeholder: "请输入标签名称，5个字以内",
                                  maxlength: "5",
                                },
                                model: {
                                  value: item.title,
                                  callback: function ($$v) {
                                    _vm.$set(item, "title", $$v)
                                  },
                                  expression: "item.title",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "con-item" },
                          [
                            _c("span", [_vm._v("数据类型")]),
                            _vm._v(" "),
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: item.type,
                                  callback: function ($$v) {
                                    _vm.$set(item, "type", $$v)
                                  },
                                  expression: "item.type",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("微页面"),
                                ]),
                                _vm._v(" "),
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("产品分类"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        item.type === 0
                          ? _c("div", { staticClass: "con-item" }, [
                              _c("span", [_vm._v("微页面")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "input-box" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择" },
                                      model: {
                                        value: item.val,
                                        callback: function ($$v) {
                                          _vm.$set(item, "val", $$v)
                                        },
                                        expression: "item.val",
                                      },
                                    },
                                    _vm._l(_vm.microList, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _c("div", { staticClass: "con-item" }, [
                              _c("span", [_vm._v("产品分类")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "input-box" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择" },
                                      model: {
                                        value: item.val,
                                        callback: function ($$v) {
                                          _vm.$set(item, "val", $$v)
                                        },
                                        expression: "item.val",
                                      },
                                    },
                                    _vm._l(
                                      _vm.adminProductClassify,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.id,
                                          attrs: {
                                            label: item.name,
                                            disabled: !item.isShow,
                                            value: item.id,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                      ]),
                      _vm._v(" "),
                      _vm.configData.list.length > 1
                        ? _c(
                            "div",
                            {
                              staticClass: "delete",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.bindDelete(index)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-error",
                                staticStyle: { "font-size": "26px" },
                              }),
                            ]
                          )
                        : _vm._e(),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.configData.list
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "add-btn", on: { click: _vm.addHotTxt } },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { icon: "el-icon-plus", plain: "" },
                      },
                      [_vm._v("添加模块")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("linkaddress", {
            ref: "linkaddres",
            on: { linkUrl: _vm.linkUrl },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }