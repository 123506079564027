"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _parent = _interopRequireDefault(require("@/layout/routerView/parent.vue"));
var _index = _interopRequireDefault(require("@/layout/footer/index.vue"));
var _link = _interopRequireDefault(require("@/layout/routerView/link.vue"));
var _iframes = _interopRequireDefault(require("@/layout/routerView/iframes.vue"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'layoutMain',
  components: {
    LayoutParentView: _parent.default,
    Footers: _index.default,
    Links: _link.default,
    Iframes: _iframes.default
  },
  data: function data() {
    return {
      headerHeight: '',
      currentRouteMeta: {},
      isShowLink: false
    };
  },
  computed: {
    // 获取布局配置信息
    getThemeConfig: function getThemeConfig() {
      return this.$store.state.themeConfig.themeConfig;
    }
  },
  mounted: function mounted() {
    this.initHeaderHeight();
    this.initCurrentRouteMeta(this.$route.meta);
  },
  methods: {
    // 初始化当前路由 meta 信息
    initCurrentRouteMeta: function initCurrentRouteMeta(meta) {
      var _this = this;
      this.isShowLink = false;
      this.currentRouteMeta = meta;
      setTimeout(function () {
        _this.isShowLink = true;
      }, 100);
    },
    // 设置 main 的高度
    initHeaderHeight: function initHeaderHeight() {
      var isTagsview = this.$store.state.themeConfig.themeConfig.isTagsview;
      if (isTagsview) return this.headerHeight = "84px";else return this.headerHeight = "50px";
    },
    // 子组件触发更新
    onGetCurrentRouteMeta: function onGetCurrentRouteMeta() {
      this.initCurrentRouteMeta(this.$route.meta);
    }
  },
  watch: {
    // 监听 vuex 数据变化
    '$store.state.themeConfig.themeConfig': {
      handler: function handler(val) {
        this.headerHeight = val.isTagsview ? '84px' : '50px';
        if (val.isFixedHeaderChange !== val.isFixedHeader) {
          if (!this.$refs.layoutScrollbarRef) return false;
          this.$refs.layoutScrollbarRef.update();
        }
      },
      deep: true
    },
    // 监听路由的变化
    $route: {
      handler: function handler(to) {
        this.initCurrentRouteMeta(to.meta);
        this.$refs.layoutScrollbarRef.wrap.scrollTop = 0;
      },
      deep: true
    }
  }
};