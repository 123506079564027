import { render, staticRenderFns } from "./home_seckill.vue?vue&type=template&id=57153c18&scoped=true"
import script from "./home_seckill.vue?vue&type=script&lang=js"
export * from "./home_seckill.vue?vue&type=script&lang=js"
import style0 from "./home_seckill.vue?vue&type=style&index=0&id=57153c18&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57153c18",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\code\\商城\\dggf-order-web\\mer_plat_admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('57153c18')) {
      api.createRecord('57153c18', component.options)
    } else {
      api.reload('57153c18', component.options)
    }
    module.hot.accept("./home_seckill.vue?vue&type=template&id=57153c18&scoped=true", function () {
      api.rerender('57153c18', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pagediy/components/mobilePage/home_seckill.vue"
export default component.exports