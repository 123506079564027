var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _c(
        "el-card",
        {
          staticClass: "ivu-mt",
          attrs: {
            bordered: false,
            shadow: "never",
            "body-style": { padding: 0 },
          },
        },
        [
          _c(
            "div",
            { staticClass: "padding-add" },
            [
              _c(
                "el-form",
                {
                  ref: "userFrom",
                  attrs: {
                    model: _vm.userFrom,
                    "label-width": "66px",
                    "label-position": "right",
                    inline: "",
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  !_vm.collapse
                    ? _c(
                        "div",
                        { staticClass: "acea-row search-form" },
                        [
                          _c(
                            "div",
                            { staticClass: "search-form-box" },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "姓名：" } },
                                [
                                  _c("el-input", {
                                    staticClass: "selWidth",
                                    attrs: {
                                      placeholder: "请输入用户昵称",
                                      size: "small",
                                      clearable: "",
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.userSearchs($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.userFrom.nickname,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.userFrom,
                                          "nickname",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "userFrom.nickname",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "联系电话：" } },
                                [
                                  _c("el-input", {
                                    staticClass: "selWidth",
                                    attrs: {
                                      placeholder: "请输入手机号",
                                      size: "small",
                                      clearable: "",
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.userSearchs($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.userFrom.phone,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.userFrom,
                                          "phone",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "userFrom.phone",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { staticClass: "search-form-sub" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "small" },
                                  on: { click: _vm.userSearchs },
                                },
                                [_vm._v("搜索")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "ResetSearch",
                                  attrs: { size: "small" },
                                  on: { click: _vm.reset },
                                },
                                [_vm._v("重置")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card mt14",
          attrs: {
            "body-style": { padding: "20px" },
            shadow: "never",
            bordered: false,
          },
        },
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.userFrom.auditStatus,
                callback: function ($$v) {
                  _vm.$set(_vm.userFrom, "auditStatus", $$v)
                },
                expression: "userFrom.auditStatus",
              },
            },
            _vm._l(_vm.auditStatusList, function (item) {
              return _c("el-tab-pane", {
                key: item.name,
                attrs: { name: item.name, label: item.label },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "acea-row" },
            [
              _vm.userFrom.auditStatus === "passed"
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: !_vm.multipleSelectionAll.length,
                        size: "small",
                      },
                      on: {
                        click: function ($event) {
                          _vm.showAssign = true
                        },
                      },
                    },
                    [_vm._v("批量分配商户\n      ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "table",
              staticClass: "mt20",
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "highlight-current-row": "",
              },
              on: {
                "selection-change": _vm.handleSelectionChange,
                "select-all": _vm.selectAll,
                select: _vm.selectOne,
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "50" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "nickname", label: "用户昵称" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "phone", label: "手机号" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "siteCode", label: "代销证编号" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "orgName", label: "机构名称" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "identNumber", label: "机构编号" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "merchantName", label: "商户" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "auditStatusValue", label: "审核状态" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "130",
                  fixed: "right",
                  "scoped-slot": _vm.renderHeader,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.detail(scope.row, "detail")
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                        _vm._v(" "),
                        _vm.userFrom.auditStatus == "wait_audit"
                          ? _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.detail(scope.row, "apply")
                                  },
                                },
                              },
                              [_vm._v("审核")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.userFrom.auditStatus == "wait_audit"
                          ? _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.detail(scope.row, "edit")
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.userFrom.auditStatus == "passed"
                          ? _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.showMerchantAssign(scope.row)
                                  },
                                },
                              },
                              [_vm._v("分配商户")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "page-sizes": [15, 30, 45, 60],
                  "page-size": _vm.userFrom.limit,
                  "current-page": _vm.userFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("UserDetail", {
        attrs: { visible: _vm.visible, id: _vm.userInfo.id, type: _vm.type },
        on: {
          refresh: _vm.getList,
          "update:visible": function ($event) {
            _vm.visible = $event
          },
          "update:type": function ($event) {
            _vm.type = $event
          },
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "分配商户", visible: _vm.showAssign, width: "500px" },
          on: {
            "update:visible": function ($event) {
              _vm.showAssign = $event
            },
            close: _vm.orgClose,
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "100px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "选择商户", required: "" } },
                [
                  _c("el-cascader", {
                    ref: "cascader",
                    staticStyle: { width: "100%" },
                    attrs: { props: _vm.props },
                    on: { change: _vm.handleChange },
                    model: {
                      value: _vm.orgIds,
                      callback: function ($$v) {
                        _vm.orgIds = $$v
                      },
                      expression: "orgIds",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showAssign = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.merchantAssign },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }