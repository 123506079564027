var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-search-dialog" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.isShowSearch,
            width: "540px",
            "destroy-on-close": "",
            modal: false,
            fullscreen: "",
            "show-close": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isShowSearch = $event
            },
          },
        },
        [
          _c("el-autocomplete", {
            ref: "layoutMenuAutocompleteRef",
            attrs: {
              "fetch-suggestions": _vm.menuSearch,
              placeholder: "菜单搜索：支持中文、路由路径",
              "prefix-icon": "el-icon-search",
            },
            on: { select: _vm.onHandleSelect, blur: _vm.onSearchBlur },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c("div", [
                      _c("i", { staticClass: "mr10", class: item.icon }),
                      _vm._v(_vm._s(item.title)),
                    ]),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.menuQuery,
              callback: function ($$v) {
                _vm.menuQuery = $$v
              },
              expression: "menuQuery",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }