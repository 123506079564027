var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        {
          directives: [
            {
              name: "hasPermi",
              rawName: "v-hasPermi",
              value: ["platform:community:note:page:list"],
              expression: "['platform:community:note:page:list']",
            },
          ],
          staticClass: "ivu-mt",
          attrs: {
            bordered: false,
            shadow: "never",
            "body-style": { padding: 0 },
          },
        },
        [
          _c(
            "div",
            { staticClass: "padding-add" },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    inline: "",
                    size: "small",
                    "label-position": "right",
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _vm.tabActive === "10"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "审核状态：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item selWidth",
                              attrs: { placeholder: "请选择", clearable: "" },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.auditStatus,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "auditStatus", $$v)
                                },
                                expression: "tableFrom.auditStatus",
                              },
                            },
                            _vm._l(_vm.statusList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { display: "inline-block" },
                      attrs: { label: "分类名称：" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "selWidth",
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "请选择分类名称",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.getList(1)
                            },
                          },
                          model: {
                            value: _vm.tableFrom.categoryId,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "categoryId", $$v)
                            },
                            expression: "tableFrom.categoryId",
                          },
                        },
                        _vm._l(_vm.cateSelect, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label: item.name,
                              value: item.id,
                              disabled: item.isShow === 0,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "内容标题：", "label-width": "66px" } },
                    [
                      _c("el-input", {
                        staticClass: "selWidth",
                        attrs: { placeholder: "请输入内容标题" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.getList(1)
                          },
                        },
                        model: {
                          value: _vm.title,
                          callback: function ($$v) {
                            _vm.title = $$v
                          },
                          expression: "title",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { display: "inline-block" },
                      attrs: { label: "话题名称：" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          directives: [
                            {
                              name: "selectLoadMore",
                              rawName: "v-selectLoadMore",
                              value: _vm.selectLoadMore,
                              expression: "selectLoadMore",
                            },
                          ],
                          staticClass: "selWidth",
                          attrs: {
                            clearable: "",
                            filterable: "",
                            loading: _vm.loading,
                            remote: "",
                            multiple: _vm.multiple,
                            "remote-method": _vm.remoteMethod,
                            placeholder: "请选择话题",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.getList(1)
                            },
                          },
                          model: {
                            value: _vm.tableFrom.topicId,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "topicId", $$v)
                            },
                            expression: "tableFrom.topicId",
                          },
                        },
                        _vm._l(_vm.topicSelect, function (user) {
                          return _c("el-option", {
                            key: user.id,
                            attrs: { label: user.name, value: user.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "内容作者：", "label-width": "66px" } },
                    [
                      _c("el-input", {
                        staticClass: "selWidth",
                        attrs: { placeholder: "请输入内容作者" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.getList(1)
                          },
                        },
                        model: {
                          value: _vm.tableFrom.authorName,
                          callback: function ($$v) {
                            _vm.$set(_vm.tableFrom, "authorName", $$v)
                          },
                          expression: "tableFrom.authorName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "图文类型：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item selWidth",
                          attrs: {
                            placeholder: "请选择图文类型",
                            clearable: "",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.getList(1)
                            },
                          },
                          model: {
                            value: _vm.tableFrom.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "type", $$v)
                            },
                            expression: "tableFrom.type",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "图文", value: "1" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "短视频", value: "2" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.getList(1)
                            },
                          },
                        },
                        [_vm._v("查询")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.reset()
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card mt14",
          attrs: {
            "body-style": { padding: "0 20px 20px" },
            shadow: "never",
            bordered: false,
          },
        },
        [
          _c(
            "el-tabs",
            {
              staticClass: "list-tabs",
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.tabActive,
                callback: function ($$v) {
                  _vm.tabActive = $$v
                },
                expression: "tabActive",
              },
            },
            _vm._l(_vm.headeNum, function (item, index) {
              return _c("el-tab-pane", {
                key: index,
                attrs: { name: item.type, label: item.title },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "hasPermi",
                  rawName: "v-hasPermi",
                  value: ["platform:community:note:category:batch:update"],
                  expression:
                    "['platform:community:note:category:batch:update']",
                },
              ],
              staticClass: "mt5",
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.categoryBatch },
            },
            [_vm._v("批量移动")]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "table",
              staticClass: "mt20 tableSelection",
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "highlight-current-row": "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "ID", prop: "id", width: "60" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "内容标题",
                  "min-width": "160",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm._f("filterEmpty")(scope.row.title))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "内容作者",
                  prop: "authorName",
                  "min-width": "140",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "内容类型", "min-width": "70" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(scope.row.type === 1 ? "图文" : "视频")
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "封面", "min-width": "50px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview line-heightOne" },
                          [
                            _c("el-image", {
                              staticClass: "mr5 imgStyle",
                              attrs: {
                                src: scope.row.cover,
                                "preview-src-list": [scope.row.cover],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "推荐级别", "min-width": "130" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-rate", {
                          attrs: { disabled: "" },
                          model: {
                            value: scope.row.star,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "star", $$v)
                            },
                            expression: "scope.row.star",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "likeNum", label: "点赞数", "min-width": "100" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "replyNum",
                  label: "评论数",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "categoryName",
                  label: "分类",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "话题",
                  "min-width": "130",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        !scope.row.topicList
                          ? _c("div", [_vm._v("无")])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(scope.row.topicList, function (item, i) {
                          return _c("div", { key: i }, [
                            _vm._v(_vm._s(item)),
                            _c("br"),
                          ])
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "评论", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm._f("communityReplyStatusFilter")(
                                scope.row.replyStatus
                              )
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _vm.tabActive === "10"
                ? _c("el-table-column", {
                    attrs: { label: "审核状态", "min-width": "100" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.auditStatus === 0
                                ? _c(
                                    "el-tag",
                                    { staticClass: "doingTag tag-background" },
                                    [_vm._v("待审核")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.auditStatus === 1
                                ? _c(
                                    "el-tag",
                                    { staticClass: "endTag tag-background" },
                                    [_vm._v("审核成功")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.auditStatus === 2
                                ? _c(
                                    "el-tag",
                                    {
                                      staticClass: "notStartTag tag-background",
                                    },
                                    [_vm._v("审核失败")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.auditStatus === 3
                                ? _c("el-tag", { attrs: { type: "danger" } }, [
                                    _vm._v("平台关闭"),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      953550513
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.tabActive === "10"
                ? _c("el-table-column", {
                    attrs: {
                      prop: "refusal",
                      label: "拒绝原因",
                      "min-width": "120",
                      "show-overflow-tooltip": true,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              Number(scope.row.auditStatus) > 1
                                ? _c("div", [
                                    _vm._v(
                                      "拒绝原因" + _vm._s(scope.row.refusal)
                                    ),
                                  ])
                                : _c("div", [_vm._v("无")]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      46898180
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "发布时间",
                  "min-width": "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", width: "220", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.auditStatus == 0 &&
                        _vm.checkPermi([
                          "platform:community:note:detail",
                          "platform:community:note:audit",
                        ])
                          ? [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.onAudit(scope.row.id, true)
                                    },
                                  },
                                },
                                [_vm._v("审核")]
                              ),
                              _vm._v(" "),
                              _c("el-divider", {
                                attrs: { direction: "vertical" },
                              }),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.checkPermi([
                          "platform:community:note:repley:force:off:switch",
                        ])
                          ? [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.onReplyOff(scope.row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.replyStatus !== 3
                                        ? "关闭评论"
                                        : "取消关闭评论"
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("el-divider", {
                                attrs: { direction: "vertical" },
                              }),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.auditStatus == 1 &&
                        _vm.checkPermi(["platform:community:note:forced:down"])
                          ? [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.onOff(scope.row.id)
                                    },
                                  },
                                },
                                [_vm._v("强制下架")]
                              ),
                              _vm._v(" "),
                              _c("el-divider", {
                                attrs: { direction: "vertical" },
                              }),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-dropdown",
                          { attrs: { trigger: "click" } },
                          [
                            _c("span", { staticClass: "el-dropdown-link" }, [
                              _vm._v(" 更多"),
                              _c("i", {
                                staticClass:
                                  "el-icon-arrow-down el-icon--right",
                              }),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _vm.checkPermi([
                                  "platform:community:note:detail",
                                ])
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.onAudit(
                                              scope.row.id,
                                              false
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                详情\n              "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkPermi([
                                  "platform:community:note:delete",
                                ])
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.handleDelete(
                                              scope.row.id,
                                              scope.$index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                删除\n              "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.auditStatus == 1 &&
                                _vm.checkPermi([
                                  "platform:community:note:star:update",
                                ])
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.onEdit(scope.row)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                编辑星级\n              "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          ref: "drawer",
          staticClass: "infoBox",
          attrs: {
            visible: _vm.dialogVisible,
            direction: _vm.direction,
            "custom-class": "demo-drawer",
            size: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.onClose,
          },
        },
        [
          _vm.dialogVisible
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                },
                [
                  _c("div", { staticClass: "detailHead" }, [
                    _c(
                      "div",
                      { staticClass: "acea-row row-between headerBox" },
                      [
                        _c("div", { staticClass: "full" }, [
                          _c("div", { staticClass: "order_icon" }, [
                            _c("span", {
                              staticClass: "iconfont icon-shipinico",
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "text" }, [
                            _c("div", { staticClass: "title" }, [
                              _vm._v(
                                _vm._s(_vm.isExamine ? "内容审核" : "内容详情")
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c("span", { staticClass: "mr20" }, [
                                _vm._v("审核状态："),
                                _c("span", { staticClass: "color-warning" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("communityAuditStatusFilter")(
                                        _vm.formValidate.auditStatus
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _vm.isExamine
                          ? _c(
                              "div",
                              { staticClass: "acea-row justify-content" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "hasPermi",
                                        rawName: "v-hasPermi",
                                        value: [
                                          "platform:seckill:product:audit",
                                        ],
                                        expression:
                                          "['platform:seckill:product:audit']",
                                      },
                                      {
                                        name: "debounceClick",
                                        rawName: "v-debounceClick",
                                        value: function () {
                                          _vm.onAuditStatus(2)
                                        },
                                        expression:
                                          "\n                () => {\n                  onAuditStatus(2);\n                }\n              ",
                                      },
                                    ],
                                    staticStyle: { "margin-left": "0" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.loadingBtn
                                          ? "提交中 ..."
                                          : "审核拒绝"
                                      )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "hasPermi",
                                        rawName: "v-hasPermi",
                                        value: [
                                          "platform:seckill:product:audit",
                                        ],
                                        expression:
                                          "['platform:seckill:product:audit']",
                                      },
                                      {
                                        name: "debounceClick",
                                        rawName: "v-debounceClick",
                                        value: function () {
                                          _vm.onAuditStatus(1)
                                        },
                                        expression:
                                          "\n                () => {\n                  onAuditStatus(1);\n                }\n              ",
                                      },
                                    ],
                                    attrs: { type: "primary" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.loadingBtn
                                          ? "提交中 ..."
                                          : "审核通过"
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-tabs",
                    {
                      attrs: { type: "border-card" },
                      model: {
                        value: _vm.currentTab,
                        callback: function ($$v) {
                          _vm.currentTab = $$v
                        },
                        expression: "currentTab",
                      },
                    },
                    [
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "基础信息", name: "0" } },
                        [
                          _c("div", { staticClass: "detailSection" }, [
                            _c("ul", { staticClass: "list mt-16" }, [
                              _c("li", { staticClass: "item" }, [
                                _c("div", { staticClass: "tips" }, [
                                  _vm._v("文章标题："),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "value" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm._f("filterEmpty")(
                                          _vm.formValidate.title
                                        )
                                      ) +
                                      "\n                "
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("li", { staticClass: "item" }, [
                                _c("div", { staticClass: "tips" }, [
                                  _vm._v("作者："),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "value" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm._f("filterEmpty")(
                                          _vm.formValidate.authorName
                                        )
                                      ) +
                                      "\n                "
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("li", { staticClass: "item" }, [
                                _c("div", { staticClass: "tips" }, [
                                  _vm._v("作者ID："),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "value" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm._f("filterEmpty")(
                                          _vm.formValidate.authorId
                                        )
                                      ) +
                                      "\n                "
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("li", { staticClass: "item" }, [
                                _c("div", { staticClass: "tips" }, [
                                  _vm._v("发布时间："),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "value" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.formValidate.createTime) +
                                      "\n                "
                                  ),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "list",
                                staticStyle: { display: "block" },
                              },
                              [
                                _c("div", { staticClass: "item" }, [
                                  _c("div", { staticClass: "tips" }, [
                                    _vm._v("文章内容："),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "value" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm._f("filterEmpty")(
                                            _vm.formValidate.content
                                          )
                                        ) +
                                        "\n                "
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "item row-middle" }, [
                                  _c("div", { staticClass: "tips" }, [
                                    _vm._v("封面图："),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "upLoadPicBox" },
                                    [
                                      _c("el-image", {
                                        staticClass: "pictrue",
                                        attrs: {
                                          src: _vm.formValidate.cover,
                                          "preview-src-list": [
                                            _vm.formValidate.cover,
                                          ],
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm.formValidate.type === 2 &&
                                _vm.formValidate.video
                                  ? _c("div", { staticClass: "item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "acea-row row-middle" },
                                        [
                                          _c("div", { staticClass: "tips" }, [
                                            _vm._v("短视频："),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "upLoadPicBox" },
                                            [
                                              _c(
                                                "video",
                                                {
                                                  staticClass: "pictrue",
                                                  attrs: {
                                                    src: _vm.formValidate.video,
                                                    controls: "controls",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      您的浏览器不支持 video 标签。\n                    "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.formValidate.type === 1 &&
                                _vm.formValidate.image
                                  ? _c(
                                      "div",
                                      { staticClass: "item" },
                                      [
                                        _c("div", { staticClass: "tips" }, [
                                          _vm._v("图片："),
                                        ]),
                                        _vm._v(" "),
                                        _vm._l(
                                          _vm.formValidate.image.split(","),
                                          function (item, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: index,
                                                staticClass: "pictrue",
                                                staticStyle: {
                                                  display: "inline-block",
                                                  margin: "0 10px 10px 0",
                                                },
                                              },
                                              [
                                                _c("el-image", {
                                                  staticClass: "pictrue",
                                                  attrs: {
                                                    src: item || "",
                                                    "preview-src-list": [
                                                      item ? item : "",
                                                    ],
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.formValidate.auditStatus === 2 ||
                                _vm.formValidate.auditStatus === 3
                                  ? _c("div", { staticClass: "item" }, [
                                      _c("div", { staticClass: "tips" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formValidate.auditStatus === 2
                                              ? "拒绝原因："
                                              : "关闭原因："
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "value" }, [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(_vm.formValidate.refusal) +
                                            "\n                "
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.formValidate.operateTime
                                  ? _c("div", { staticClass: "item" }, [
                                      _c("div", { staticClass: "tips" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formValidate.auditStatus === 3
                                              ? "关闭时间："
                                              : "审核时间："
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "value" }, [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.formValidate.operateTime
                                            ) +
                                            "\n                "
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "关联产品", name: "1" } },
                        [
                          _c(
                            "el-table",
                            {
                              ref: "tableList",
                              staticClass: "mt20",
                              staticStyle: { width: "100%" },
                              attrs: {
                                "row-key": "id",
                                data: _vm.formValidate.productList,
                                size: "small",
                                border: "",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "id",
                                  label: "ID",
                                  "min-width": "45",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  "min-width": "180",
                                  label: "产品信息",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "acea-row row-middle",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "demo-image__preview mr10 acea-row",
                                                },
                                                [
                                                  _c("el-image", {
                                                    attrs: {
                                                      src: scope.row
                                                        .productImage,
                                                      "preview-src-list": [
                                                        scope.row.productImage,
                                                      ],
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "row_title line2",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row.productName
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  962950106
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "price",
                                  label: "售价",
                                  width: "120",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "评论列表", name: "2" } },
                        [
                          _c(
                            "el-table",
                            {
                              ref: "tableList",
                              staticClass: "mt20",
                              staticStyle: { width: "100%" },
                              attrs: {
                                "row-key": "id",
                                data: _vm.replyData.data,
                                size: "small",
                                border: "",
                                "default-expand-all": _vm.isExpandAll,
                                "tree-props": {
                                  children: "replyList",
                                  hasChildren: "hasChildren",
                                },
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "用户名/Id",
                                  label: "ID",
                                  "min-width": "100",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.nickname +
                                                  "/" +
                                                  scope.row.id
                                              )
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2660611308
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "content",
                                  label: "评论内容",
                                  width: "150",
                                  "show-overflow-tooltip": true,
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "countReply",
                                  label: "评论数",
                                  "min-width": "100",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "countStart",
                                  label: "点赞数",
                                  "min-width": "100",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "评论时间",
                                  "min-width": "150",
                                  prop: "createTime",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "操作",
                                  width: "120",
                                  fixed: "right",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              directives: [
                                                {
                                                  name: "hasPermi",
                                                  rawName: "v-hasPermi",
                                                  value: [
                                                    "platform:product:reply:delete",
                                                  ],
                                                  expression:
                                                    "['platform:product:reply:delete']",
                                                },
                                              ],
                                              attrs: {
                                                type: "text",
                                                size: "small",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleReplyDelete(
                                                    scope.row.id,
                                                    scope.$index
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("删除")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1491433712
                                ),
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "block" },
                            [
                              _c("el-pagination", {
                                attrs: {
                                  "page-sizes": [20, 40, 60, 80],
                                  "page-size": _vm.tableFromReply.limit,
                                  "current-page": _vm.tableFromReply.page,
                                  layout:
                                    "total, sizes, prev, pager, next, jumper",
                                  total: _vm.replyData.total,
                                },
                                on: {
                                  "size-change": _vm.handleSizeChangeReply,
                                  "current-change": _vm.pageChangeReply,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "demo-drawer__footer" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isExamine,
                    expression: "isExamine",
                  },
                ],
                staticClass: "from-foot-btn drawer_fix",
              },
              [_c("div", { staticClass: "acea-row justify-content" })]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.visible,
            title: "编辑星级",
            "destroy-on-close": "",
            "close-on-click-modal": false,
            width: "540px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c("el-rate", {
            model: {
              value: _vm.star,
              callback: function ($$v) {
                _vm.star = $$v
              },
              expression: "star",
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "div",
                { staticClass: "dialog-btn-top" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.visible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "debounceClick",
                          rawName: "v-debounceClick",
                          value: function () {
                            _vm.submitForm()
                          },
                          expression:
                            "\n            () => {\n              submitForm();\n            }\n          ",
                        },
                      ],
                      attrs: { type: "primary" },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.visibleCategory,
            title: "编辑分类",
            "destroy-on-close": "",
            "close-on-click-modal": false,
            width: "540px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visibleCategory = $event
            },
          },
        },
        [
          _c(
            "el-select",
            {
              staticClass: "selectStyle",
              attrs: { clearable: "", filterable: "", placeholder: "请选择" },
              model: {
                value: _vm.categoryId,
                callback: function ($$v) {
                  _vm.categoryId = $$v
                },
                expression: "categoryId",
              },
            },
            _vm._l(_vm.cateSelect, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: {
                  label: item.name,
                  value: item.id,
                  disabled: item.isShow === 0,
                },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "div",
                { staticClass: "dialog-btn-top" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.visibleCategory = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "debounceClick",
                          rawName: "v-debounceClick",
                          value: function () {
                            _vm.submitFormCategory()
                          },
                          expression:
                            "\n            () => {\n              submitFormCategory();\n            }\n          ",
                        },
                      ],
                      attrs: { type: "primary" },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }