var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        {
          staticClass: "box-card",
          attrs: {
            shadow: "never",
            bordered: false,
            "body-style": { padding: "0 20px" },
          },
        },
        [_c("upload-picture", { attrs: { pictureType: _vm.pictureType } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }