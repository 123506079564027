"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'parent',
  data: function data() {
    return {
      refreshRouterViewKey: null,
      keepAliveNameList: [],
      keepAliveNameNewList: []
    };
  },
  computed: {
    // 设置主界面切换动画
    setTransitionName: function setTransitionName() {
      return this.$store.state.themeConfig.themeConfig.animation;
    },
    isTagHistory: function isTagHistory() {
      return this.$store.state.themeConfig.themeConfig.isTagsview;
    }
  },
  created: function created() {
    var _this = this;
    this.keepAliveNameList = this.getKeepAliveNames();
    this.bus.$on('onTagsViewRefreshRouterView', function (path) {
      if (_this.$route.path !== path) return false;
      _this.keepAliveNameList = _this.getKeepAliveNames().filter(function (name) {
        return _this.$route.name !== name;
      });
      _this.refreshRouterViewKey = _this.$route.path;
      _this.$nextTick(function () {
        _this.refreshRouterViewKey = null;
        _this.keepAliveNameList = _this.getKeepAliveNames();
      });
    });
  },
  methods: {
    // 获取路由缓存列表（name），默认路由全部缓存
    getKeepAliveNames: function getKeepAliveNames() {
      return this.$store.state.menu.keepAliveNames;
    }
  }
};