var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "components-container" },
    [
      _c(
        "el-form",
        {
          ref: "editPram",
          staticClass: "demo-ruleForm",
          attrs: { model: _vm.editPram, "label-width": "70px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "父级:" } },
            [
              _c("el-cascader", {
                staticStyle: { width: "100%" },
                attrs: {
                  options: _vm.allTreeList,
                  props: _vm.categoryProps,
                  disabled: "",
                },
                model: {
                  value: _vm.editPram.pid,
                  callback: function ($$v) {
                    _vm.$set(_vm.editPram, "pid", $$v)
                  },
                  expression: "editPram.pid",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "分类名称:",
                prop: "name",
                rules: [
                  {
                    required: true,
                    message: "请输入分类名称",
                    trigger: ["blur", "change"],
                  },
                ],
              },
            },
            [
              _c("el-input", {
                attrs: { placeholder: "分类名称" },
                model: {
                  value: _vm.editPram.name,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.editPram,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "editPram.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "英文名称:",
                prop: "url",
                rules: [
                  {
                    required: true,
                    message: "英文名称不能为空",
                    trigger: ["blur", "change"],
                  },
                ],
              },
            },
            [
              _c("el-input", {
                attrs: { placeholder: "URL" },
                model: {
                  value: _vm.editPram.url,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.editPram,
                      "url",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "editPram.url",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "排序:" } },
            [
              _c("el-input-number", {
                attrs: { min: 1, max: 10 },
                model: {
                  value: _vm.editPram.sort,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.editPram,
                      "sort",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "editPram.sort",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "状态:" } },
            [
              _c("el-switch", {
                attrs: {
                  "active-text": "显示",
                  "inactive-text": "隐藏",
                  "active-value": true,
                  "inactive-value": false,
                },
                model: {
                  value: _vm.editPram.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.editPram, "status", $$v)
                  },
                  expression: "editPram.status",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer-inner",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.close } },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handlerSubmit("editPram")
                },
              },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }