var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.clientWidth > 1000
    ? _c(
        "el-aside",
        { staticClass: "layout-aside", class: _vm.setCollapseWidth },
        [
          _vm.setShowLogo &&
          _vm.menuList.length &&
          _vm.getThemeConfig.layout !== "columns"
            ? _c("Logo")
            : _vm._e(),
          _vm._v(" "),
          _vm.menuList.length && _vm.getThemeConfig.layout == "columns"
            ? _c("div", { staticClass: "cat-name" }, [
                _vm._v(
                  "\n    " + _vm._s(_vm.adminTitle || _vm.catName) + "\n  "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-scrollbar",
            { ref: "layoutAsideRef", staticClass: "flex-auto" },
            [
              _c("Vertical", {
                class: _vm.setCollapseWidth,
                attrs: { menuList: _vm.menuList },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        "el-drawer",
        {
          attrs: {
            visible: _vm.getThemeConfig.isCollapse,
            "with-header": false,
            direction: "ltr",
            size: "180px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.getThemeConfig, "isCollapse", $event)
            },
          },
        },
        [
          _c(
            "el-aside",
            { staticClass: "layout-aside w100 h100" },
            [
              _vm.setShowLogo && _vm.menuList.length ? _c("Logo") : _vm._e(),
              _vm._v(" "),
              _c(
                "el-scrollbar",
                { ref: "layoutAsideRef", staticClass: "flex-auto" },
                [_c("Vertical", { attrs: { menuList: _vm.menuList } })],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }