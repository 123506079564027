"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _subItem = _interopRequireDefault(require("@/layout/navMenu/subItem.vue"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'navMenuHorizontal',
  components: {
    SubItem: _subItem.default
  },
  props: {
    menuList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  computed: _objectSpread({}, (0, _vuex.mapState)('menu', ['activePath'])),
  data: function data() {
    return {
      defaultActive: null
    };
  },
  mounted: function mounted() {
    this.initElMenuOffsetLeft();
    this.setCurrentRouterHighlight(this.$route.path);
  },
  methods: {
    // 设置横向滚动条可以鼠标滚轮滚动
    onElMenuHorizontalScroll: function onElMenuHorizontalScroll(e) {
      var eventDelta = e.wheelDelta || -e.deltaY * 40;
      this.$refs.elMenuHorizontalScrollRef.$refs.wrap.scrollLeft = this.$refs.elMenuHorizontalScrollRef.$refs.wrap.scrollLeft + eventDelta / 4;
    },
    // 初始化数据，页面刷新时，滚动条滚动到对应位置
    initElMenuOffsetLeft: function initElMenuOffsetLeft() {
      var _this = this;
      this.$nextTick(function () {
        var els = document.querySelector('.el-menu.el-menu--horizontal li.is-active');
        if (!els) return false;
        _this.$refs.elMenuHorizontalScrollRef.$refs.wrap.scrollLeft = els.offsetLeft;
      });
    },
    // 路由过滤递归函数
    filterRoutesFun: function filterRoutesFun(arr) {
      var _this2 = this;
      return arr.filter(function (item) {
        return !item.isHide;
      }).map(function (item) {
        item = Object.assign({}, item);
        if (item.children) item.children = _this2.filterRoutesFun(item.children);
        return item;
      });
    },
    // 传送当前子级数据到菜单中
    setSendClassicChildren: function setSendClassicChildren(path) {
      var currentPathSplit = path.split('/');
      var currentData = {};
      this.filterRoutesFun(this.$store.state.user.menuList).map(function (v, k) {
        if (v.path === "/".concat(currentPathSplit[1])) {
          v['k'] = k;
          currentData['item'] = [_objectSpread({}, v)];
          currentData['children'] = [_objectSpread({}, v)];
          if (v.children) currentData['children'] = v.children;
        }
      });
      return currentData;
    },
    // 菜单激活回调
    onHorizontalSelect: function onHorizontalSelect(path) {
      this.bus.$emit('setSendClassicChildren', this.setSendClassicChildren(path));
    },
    // 设置页面当前路由高亮
    setCurrentRouterHighlight: function setCurrentRouterHighlight(path) {
      var currentPathSplit = path.split('/');
      if (this.$store.state.themeConfig.themeConfig.layout === 'classic') {
        this.defaultActive = "/".concat(currentPathSplit[1]);
      } else {
        this.defaultActive = path;
      }
    }
  },
  watch: {
    // 监听路由的变化
    $route: {
      handler: function handler(to) {
        this.setCurrentRouterHighlight(to.path);
      },
      deep: true
    }
  }
};