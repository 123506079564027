var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.chil, function (val) {
        return [
          val.children && val.children.length > 0
            ? _c(
                "el-submenu",
                { key: val.path, attrs: { index: val.path } },
                [
                  _c("template", { slot: "title" }, [
                    _c("i", {
                      staticClass: "ivu-icon",
                      class: "el-icon-" + val.icon,
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(val.title))]),
                  ]),
                  _vm._v(" "),
                  _c("sub-item", { attrs: { chil: val.children } }),
                ],
                2
              )
            : [
                _c(
                  "el-menu-item",
                  { key: val.path, attrs: { index: val.path } },
                  [
                    [
                      _c("i", {
                        staticClass: "ivu-icon",
                        class: val.icon ? "el-icon-" + val.icon : "",
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(val.title))]),
                    ],
                  ],
                  2
                ),
              ],
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }