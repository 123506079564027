var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.productAssociationType == "product"
        ? _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleAddGoods },
                },
                [_vm._v("添加产品")]
              ),
              _vm._v(" "),
              _vm.isBatchDelete
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        disabled: !_vm.multipleSelection.length,
                      },
                      on: { click: _vm.handleBatchDel },
                    },
                    [_vm._v("批量删除")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.productAssociationType == "product"
        ? _c(
            "el-form-item",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  ref: "tableList",
                  staticStyle: { width: "100%", "margin-top": "5px" },
                  attrs: { data: _vm.tableData.data, size: "small" },
                  on: {
                    "selection-change": _vm.handleSelectionChange,
                    "select-all": _vm.selectAll,
                    select: _vm.selectOne,
                  },
                },
                [
                  _vm.isBatchDelete
                    ? _c("el-table-column", {
                        attrs: { type: "selection", width: "55" },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "id", label: "ID", width: "55" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "产品图", "min-width": "80" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "demo-image__preview line-heightOne",
                                },
                                [
                                  _c("el-image", {
                                    attrs: {
                                      src: scope.row.image,
                                      "preview-src-list": [scope.row.image],
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1508102206
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      prop: "name",
                      label: "产品名称",
                      "min-width": "200",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "price", label: "售价", "min-width": "90" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "stock", label: "库存", "min-width": "70" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      width: "60",
                      fixed: "right",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDelete(
                                        scope.$index,
                                        scope.row
                                      )
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1945863928
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.productAssociationType == "brand"
        ? _c(
            "el-form-item",
            {
              attrs: {
                "label-width": "73px",
                label: "选择品牌：",
                span: 24,
                prop: "proBrandList",
              },
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "selWidth",
                  attrs: {
                    clearable: "",
                    filterable: "",
                    multiple: _vm.multipleBrand,
                    placeholder: "请选择品牌",
                  },
                  on: { change: _vm.handleBrandSelected },
                  model: {
                    value: _vm.formValidate.proBrandList,
                    callback: function ($$v) {
                      _vm.$set(_vm.formValidate, "proBrandList", $$v)
                    },
                    expression: "formValidate.proBrandList",
                  },
                },
                _vm._l(_vm.productBrand, function (v, i) {
                  return _c("el-option", {
                    key: i,
                    attrs: { label: v.name, value: v.id, disabled: !v.isShow },
                  })
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.productAssociationType == "category"
        ? _c(
            "el-form-item",
            {
              attrs: {
                "label-width": "73px",
                label: "选择分类：",
                span: 24,
                prop: "proCategorylist",
              },
            },
            [
              _c("el-cascader", {
                staticClass: "selWidth",
                attrs: {
                  props: _vm.propsCategory,
                  options: _vm.adminProductClassify,
                  filterable: "",
                  placeholder: "",
                  "show-all-levels": false,
                  clearable: "",
                },
                on: { change: _vm.handleCateSelected },
                model: {
                  value: _vm.formValidate.proCategorylist,
                  callback: function ($$v) {
                    _vm.$set(_vm.formValidate, "proCategorylist", $$v)
                  },
                  expression: "formValidate.proCategorylist",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.productAssociationType == "merchant"
        ? _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "hasPermi",
                  rawName: "v-hasPermi",
                  value: ["platform:merchant:page:list"],
                  expression: "['platform:merchant:page:list']",
                },
              ],
              attrs: {
                "label-width": "73px",
                label: "选择商户：",
                span: 24,
                prop: "merIds",
              },
            },
            [
              _c("merchant-name", {
                attrs: {
                  multiple: _vm.multipleMer,
                  merIdChecked: _vm.formValidate.merIds,
                },
                on: { getMerId: _vm.getMerId },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }