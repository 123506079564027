"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toolsValidate = require("@/utils/toolsValidate");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'layoutLinkView',
  props: {
    meta: {
      type: Object,
      default: function _default() {}
    }
  },
  methods: {
    // 立即前往
    onGotoFullPage: function onGotoFullPage() {
      var _window$location = window.location,
        origin = _window$location.origin,
        pathname = _window$location.pathname;
      if ((0, _toolsValidate.verifyUrl)(this.isLink)) window.open(this.isLink);else window.open("".concat(origin).concat(pathname, "#").concat(this.isLink));
    }
  }
};