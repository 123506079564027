import { render, staticRenderFns } from "./org.vue?vue&type=template&id=5002ca79&scoped=true"
import script from "./org.vue?vue&type=script&lang=js"
export * from "./org.vue?vue&type=script&lang=js"
import style0 from "./org.vue?vue&type=style&index=0&id=5002ca79&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5002ca79",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\code\\商城\\dggf-order-web\\mer_plat_admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5002ca79')) {
      api.createRecord('5002ca79', component.options)
    } else {
      api.reload('5002ca79', component.options)
    }
    module.hot.accept("./org.vue?vue&type=template&id=5002ca79&scoped=true", function () {
      api.rerender('5002ca79', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/order/org.vue"
export default component.exports