"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'layoutBreadcrumbUserNews',
  data: function data() {
    return {
      list: [],
      newsList: [],
      newOrderAudioLink: null,
      messageList: []
    };
  },
  props: ['vm'],
  mounted: function mounted() {
    this.getNotict();
    // this.newOrderAudioLink = newOrderAudioLink;
    // adminSocket.then((ws) => {
    //   ws.send({
    //     type: 'login',
    //     data: getCookies('token'),
    //   });
    //   let that = this;
    //   ws.$on('ADMIN_NEW_PUSH', function (data) {
    //     that.getNotict();
    //   });

    //   ws.$on('NEW_ORDER', function (data) {
    //     that.$notify.info({
    //       title: '新订单',
    //       message: '您有一个新的订单,ID为(' + data.order_id + '),请注意查看',
    //     });
    //     if (this.newOrderAudioLink) this.newOrderAudioLink.play();
    //     that.messageList.push({
    //       title: '新订单提醒',
    //       icon: 'md-bulb',
    //       iconColor: '#87d068',
    //       time: 0,
    //       read: 0,
    //     });
    //   });
    //   ws.$on('NEW_REFUND_ORDER', function (data) {
    //     that.$notify.info({
    //       title: '退款订单提醒',
    //       message: '您有一个订单申请退款,ID为(' + data.order_id + '),请注意查看',
    //     });
    //     if (window.newOrderAudioLink) this.newOrderAudioLink.play();
    //     that.messageList.push({
    //       title: '退款订单提醒',
    //       icon: 'md-information',
    //       iconColor: '#fe5c57',
    //       time: 0,
    //       read: 0,
    //     });
    //   });
    //   ws.$on('WITHDRAW', function (data) {
    //     // that.$Notice.warning({
    //     //   title: '提现提醒',
    //     //   duration: 8,
    //     //   desc: '有用户申请提现,编号为(' + data.id + '),请注意查看',
    //     // });
    //     that.$notify.info({
    //       title: '提现提醒',
    //       message: '有用户申请提现,编号为(' + data.id + '),请注意查看',
    //     });
    //     that.messageList.push({
    //       title: '退款订单提醒',
    //       icon: 'md-people',
    //       iconColor: '#f06292',
    //       time: 0,
    //       read: 0,
    //     });
    //   });
    //   ws.$on('STORE_STOCK', function (data) {
    //     that.$notify.info({
    //       title: '库存预警',
    //       message: '产品ID为(' + data.id + ')的库存不足啦,请注意查看~',
    //     });
    //     that.messageList.push({
    //       title: '库存预警',
    //       icon: 'md-information',
    //       iconColor: '#fe5c57',
    //       time: 0,
    //       read: 0,
    //     });
    //   });
    //   ws.$on('PAY_SMS_SUCCESS', function (data) {
    //     that.$notify.info({
    //       title: '短信充值成功',
    //       message: '恭喜您充值' + data.price + '元，获得' + data.number + '条短信',
    //     });
    //     that.messageList.push({
    //       title: '短信充值成功',
    //       icon: 'md-bulb',
    //       iconColor: '#87d068',
    //       time: 0,
    //       read: 0,
    //     });
    //   });
    // });
  },
  filters: {
    // 1 待发货 2 库存报警  3评论回复  4提现申请
    msgType: function msgType(type) {
      var typeName;
      switch (type) {
        case 1:
          typeName = '待发货订单提醒';
          break;
        case 2:
          typeName = '库存报警';
          break;
        case 3:
          typeName = '评论回复';
          break;
        case 4:
          typeName = '提现申请';
          break;
        default:
          typeName = '其它';
      }
      return typeName;
    }
  },
  methods: {
    // 全部已读点击
    onAllReadClick: function onAllReadClick() {
      this.list = [];
      this.$emit('haveNews', !!this.list.length);
    },
    // 前往通知中心点击
    onGoToGiteeClick: function onGoToGiteeClick() {},
    getNotict: function getNotict() {
      var _this = this;
      (0, _system.needDealtList)().then(function (res) {
        _this.list = res.data || [];
        _this.newsList = res.data.length > 3 ? res.data.slice(0, 3) : res.data;
        _this.$emit('haveNews', !!_this.list.length);
      }).catch(function () {});
    },
    jumpUrl: function jumpUrl(path) {
      this.vm.$router.push({
        path: path
      });
    },
    icon: function icon(type) {
      return require("@/assets/images/news-".concat(type, ".png"));
    }
  }
};