var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "components-container" },
    [
      _c(
        "el-form",
        {
          ref: "editPram",
          attrs: { model: _vm.editPram, "label-width": "90px" },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "数据组名称：",
                prop: "name",
                rules: [
                  {
                    required: true,
                    message: "填写数据组名称",
                    trigger: ["blur", "change"],
                  },
                ],
              },
            },
            [
              _c("el-input", {
                attrs: { placeholder: "数据组名称", clearable: "" },
                model: {
                  value: _vm.editPram.name,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.editPram,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "editPram.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "数据简介：",
                prop: "info",
                rules: [
                  {
                    required: true,
                    message: "填写数据简介",
                    trigger: ["blur", "change"],
                  },
                ],
              },
            },
            [
              _c("el-input", {
                attrs: { placeholder: "数据简介", clearable: "" },
                model: {
                  value: _vm.editPram.info,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.editPram,
                      "info",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "editPram.info",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "表单数据ID：",
                prop: "formId",
                rules: [
                  {
                    required: true,
                    message: "请选择表单数据",
                    trigger: ["change"],
                  },
                ],
              },
            },
            [
              _c("span", [_vm._v(_vm._s(_vm.editPram.formId))]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.selectFormDialogConfig.visible = true
                    },
                  },
                },
                [_vm._v("选择模板数据")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer-inner",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取消")]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handlerSubmit("editPram")
                },
              },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticStyle: { width: "100%" },
          attrs: {
            title: "选择表单模板",
            visible: _vm.selectFormDialogConfig.visible,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.selectFormDialogConfig, "visible", $event)
            },
          },
        },
        [
          _c("form-config-list", {
            attrs: { "select-model": "" },
            on: { selectedRowData: _vm.handlerSelectedRowData },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }