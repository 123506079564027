"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AsyncDownFile = AsyncDownFile;
var _store = _interopRequireDefault(require("@/store"));
var _elementUi = require("element-ui");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function AsyncDownFile(name, params) {
  return new Promise(function (resolve, reject) {
    var xhr = new XMLHttpRequest();
    var token = !_store.default.getters.token ? sessionStorage.getItem('token') : _store.default.getters.token;
    var urls = "".concat(process.env.VUE_APP_BASE_API, "/api/down/execute?name=").concat(name, "&param=").concat(encodeURIComponent(JSON.stringify(params)));
    // console.log(urls)
    xhr.open('GET', urls, true);
    xhr.setRequestHeader('Content-Type', "application/json");
    if (token) {
      console.log(token);
      xhr.setRequestHeader('Authori-zation', token);
    }
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhr.setRequestHeader('Access-Control-Allow-Methods', '*');
    xhr.setRequestHeader('Access-Control-Allow-Headers', '*');
    xhr.responseType = 'blob';
    xhr.onload = function (res) {
      if (xhr.status === 200) {
        var getfileName = xhr.getResponseHeader('content-disposition') ? decodeURIComponent(xhr.getResponseHeader('content-disposition').split('filename=')[1]) : '';
        var NewgetfileName = xhr.getResponseHeader('content-disposition') ? decodeURIComponent(xhr.getResponseHeader('content-disposition').split('fileName=')[1]) : '';
        var Name = NewgetfileName == 'undefined' ? getfileName : NewgetfileName;
        var body = document.querySelector('body');
        if (false) {
          // if (xhr.getResponseHeader('content-type').includes('application/json')) {
          //   const reader = new FileReader() // 创建一个FileReader实例
          //   reader.readAsText(xhr.response, 'utf-8') // 读取文件,结果用字符串形式表示
          //   reader.onload = () => { // 读取完成后,**获取reader.result**
          //     const result = JSON.parse(reader.result)
          //     if (result.success != 1) {
          //       Message({
          //         message: result.msg,
          //         type: 'error',
          //         duration: 2 * 1000
          //       })
          //       reject(new Error(result.msg))
          //     } else {
          //       Message({
          //         message: '操作成功，后台下载中，请到【导出列表】中查看进度或下载文件',
          //         type: 'success',
          //         duration: 2 * 1000
          //       })
          //       resolve({data: '', msg: '操作成功！', state: xhr.statusText})
          //     }
          //   }
          //   return
        } else {
          var blobObj = new Blob([xhr.response], {
            type: 'application/octet-stream'
          });
          var imgurl = window.URL.createObjectURL(xhr.response);
          var link = document.createElement('a');
          link.download = Name;
          link.href = imgurl;
          link.style.display = 'none';
          body.appendChild(link);
          link.click();
          body.removeChild(link);
          window.URL.revokeObjectURL(link.href);
          (0, _elementUi.Message)({
            message: '操作成功！',
            type: 'success',
            duration: 5 * 1000
          });
        }
        resolve({
          data: '',
          msg: '操作成功！',
          state: xhr.statusText
        });
      } else {
        (0, _elementUi.Message)({
          message: xhr.statusText,
          type: 'error',
          duration: 5 * 1000
        });
        reject(new Error(xhr.statusText));
      }
    };
    xhr.send();
  });
}