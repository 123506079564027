"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _aside = _interopRequireDefault(require("@/layout/component/aside.vue"));
var _header = _interopRequireDefault(require("@/layout/component/header.vue"));
var _main = _interopRequireDefault(require("@/layout/component/main.vue"));
var _tagsView = _interopRequireDefault(require("@/layout/navBars/tagsView/tagsView.vue"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'layoutClassic',
  components: {
    Asides: _aside.default,
    Headers: _header.default,
    Mains: _main.default,
    TagsView: _tagsView.default
  },
  computed: {
    // 获取布局配置信息
    getThemeConfig: function getThemeConfig() {
      return this.$store.state.themeConfig.themeConfig;
    }
  }
};