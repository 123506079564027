var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-card",
        {
          staticClass: "box-card",
          attrs: { bordered: false, shadow: "never" },
        },
        [
          _c(
            "el-form",
            { attrs: { inline: "" } },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      nativeOn: {
                        click: function ($event) {
                          _vm.submitBrandAudit.visible = true
                        },
                      },
                    },
                    [_vm._v("添加品牌")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "row-key": "value",
                "tree-props": {
                  children: "children",
                  hasChildren: "hasChildren",
                },
              },
            },
            [
              _c("el-table-column", { attrs: { prop: "id", label: "ID" } }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "brandId",
                  "min-width": "70px",
                  label: "品牌ID",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "auditId",
                  "min-width": "200px",
                  label: "审核单ID",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "license",
                  "min-width": "150px",
                  label: "营业执照或组织机构代码证",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(
                        scope.row.license.split(","),
                        function (imgSrc, key) {
                          return _c("img", {
                            key: key,
                            attrs: { src: imgSrc, height: "80px" },
                          })
                        }
                      )
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "brandAuditType",
                  "min-width": "150px",
                  label: "认证审核类型",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "trademarkType",
                  "min-width": "150px",
                  label: "商标分类",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "brandManagementType",
                  "min-width": "150px",
                  label: "品牌经营类型",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "commodityOriginType",
                  "min-width": "150px",
                  label: "产品产地是否进口",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "brandWording",
                  "min-width": "150px",
                  label: "商标/品牌词",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "saleAuthorization",
                  "min-width": "150px",
                  label: "销售授权书",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "trademarkRegistrationCertificate",
                  "min-width": "150px",
                  label: "商标注册证书",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "trademarkChangeCertificate",
                  "min-width": "150px",
                  label: "商标变更证明",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "trademarkRegistrant",
                  "min-width": "150px",
                  label: "商标注册人姓名",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "trademarkRegistrantNu",
                  "min-width": "150px",
                  label: "商标注册号/申请号",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "trademarkAuthorizationPeriod",
                  "min-width": "150px",
                  label: "商标有效期",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "trademarkRegistrationApplication",
                  "min-width": "150px",
                  label: "商标注册申请受理通知书",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "trademarkApplicant",
                  "min-width": "150px",
                  label: "商标申请人姓名",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "trademarkApplicationTime",
                  "min-width": "150px",
                  label: "商标申请时间",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "importedGoodsForm", label: "报关单" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "status", fixed: "right", label: "审核状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status === 0
                          ? _c(
                              "el-tag",
                              {
                                staticClass: "doingTag tag-background",
                                attrs: { type: "warning" },
                              },
                              [_vm._v("审核中")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status === 1
                          ? _c(
                              "el-tag",
                              {
                                staticClass: "endTag tag-background",
                                attrs: { type: "success" },
                              },
                              [_vm._v("审核成功")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "top-start",
                              title: "拒绝原因",
                              width: "200",
                              trigger: "hover",
                              content: scope.row.rejectReason,
                            },
                          },
                          [
                            scope.row.status === 9
                              ? _c(
                                  "el-tag",
                                  {
                                    staticClass: "notStartTag tag-background",
                                    attrs: {
                                      slot: "reference",
                                      type: "danger",
                                    },
                                    slot: "reference",
                                  },
                                  [_vm._v("审核拒绝")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  fixed: "right",
                  label: "创建时间",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", fixed: "right", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status === 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.getCatAndBrandResult(
                                      scope.row.auditId
                                    )
                                  },
                                },
                              },
                              [_vm._v("手动更新")]
                            )
                          : _c("span", [_vm._v(" - ")]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block mb20" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableData.data.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.submitBrandAudit.title,
            visible: _vm.submitBrandAudit.visible,
            "destroy-on-close": "",
            "close-on-click-modal": false,
          },
          on: {
            close: function ($event) {
              _vm.submitBrandAudit.visible = false
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "brandSubmitFromRules",
              attrs: {
                model: _vm.submitBrandAudit,
                inline: "",
                rules: _vm.brandSubmitFromRules,
                "label-width": "200px",
                size: "mini",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "商标分类",
                    prop: "pramData.auditReq.brandInfo.trademarkType",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value:
                          _vm.submitBrandAudit.pramData.auditReq.brandInfo
                            .trademarkType,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.submitBrandAudit.pramData.auditReq.brandInfo,
                            "trademarkType",
                            $$v
                          )
                        },
                        expression:
                          "submitBrandAudit.pramData.auditReq.brandInfo.trademarkType",
                      },
                    },
                    _vm._l(45, function (item) {
                      return _c(
                        "el-option",
                        {
                          key: item,
                          attrs: { value: item, label: "第 " + item + " 类" },
                        },
                        [_vm._v('第 "' + _vm._s(item) + '" 类')]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "选择品牌经营类型" } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value:
                          _vm.submitBrandAudit.pramData.auditReq.brandInfo
                            .brandManagementType,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.submitBrandAudit.pramData.auditReq.brandInfo,
                            "brandManagementType",
                            $$v
                          )
                        },
                        expression:
                          "submitBrandAudit.pramData.auditReq.brandInfo.brandManagementType",
                      },
                    },
                    [
                      _c(
                        "el-option",
                        { attrs: { value: "1", label: "自有品牌" } },
                        [_vm._v("自有品牌")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-option",
                        { attrs: { value: "2", label: "代理品牌" } },
                        [_vm._v("代理品牌")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-option",
                        { attrs: { value: "3", label: "无品牌" } },
                        [_vm._v("无品牌")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "产品产地是否进口" } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value:
                          _vm.submitBrandAudit.pramData.auditReq.brandInfo
                            .commodityOriginType,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.submitBrandAudit.pramData.auditReq.brandInfo,
                            "commodityOriginType",
                            $$v
                          )
                        },
                        expression:
                          "submitBrandAudit.pramData.auditReq.brandInfo.commodityOriginType",
                      },
                    },
                    [
                      _c("el-option", { attrs: { value: "1", label: "是" } }, [
                        _vm._v("是"),
                      ]),
                      _vm._v(" "),
                      _c("el-option", { attrs: { value: "2", label: "否" } }, [
                        _vm._v("否"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商标有效期" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      placeholder: "选择 商标有效期",
                    },
                    model: {
                      value:
                        _vm.submitBrandAudit.pramData.auditReq.brandInfo
                          .trademarkAuthorizationPeriod,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.submitBrandAudit.pramData.auditReq.brandInfo,
                          "trademarkAuthorizationPeriod",
                          $$v
                        )
                      },
                      expression:
                        "submitBrandAudit.pramData.auditReq.brandInfo.trademarkAuthorizationPeriod",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商标/品牌词" } },
                [
                  _c("el-input", {
                    model: {
                      value:
                        _vm.submitBrandAudit.pramData.auditReq.brandInfo
                          .brandWording,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.submitBrandAudit.pramData.auditReq.brandInfo,
                          "brandWording",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression:
                        "submitBrandAudit.pramData.auditReq.brandInfo.brandWording",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商标申请时间" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      placeholder: "选择 商标申请时间",
                    },
                    model: {
                      value:
                        _vm.submitBrandAudit.pramData.auditReq.brandInfo
                          .trademarkApplicationTime,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.submitBrandAudit.pramData.auditReq.brandInfo,
                          "trademarkApplicationTime",
                          $$v
                        )
                      },
                      expression:
                        "submitBrandAudit.pramData.auditReq.brandInfo.trademarkApplicationTime",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商标注册人姓名" } },
                [
                  _c("el-input", {
                    model: {
                      value:
                        _vm.submitBrandAudit.pramData.auditReq.brandInfo
                          .trademarkRegistrant,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.submitBrandAudit.pramData.auditReq.brandInfo,
                          "trademarkRegistrant",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression:
                        "submitBrandAudit.pramData.auditReq.brandInfo.trademarkRegistrant",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商标注册号/申请号" } },
                [
                  _c("el-input", {
                    model: {
                      value:
                        _vm.submitBrandAudit.pramData.auditReq.brandInfo
                          .trademarkRegistrantNu,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.submitBrandAudit.pramData.auditReq.brandInfo,
                          "trademarkRegistrantNu",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression:
                        "submitBrandAudit.pramData.auditReq.brandInfo.trademarkRegistrantNu",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "认证审核类型" } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value:
                          _vm.submitBrandAudit.pramData.auditReq.brandInfo
                            .brandAuditType,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.submitBrandAudit.pramData.auditReq.brandInfo,
                            "brandAuditType",
                            $$v
                          )
                        },
                        expression:
                          "submitBrandAudit.pramData.auditReq.brandInfo.brandAuditType",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { value: "1", label: "国内品牌申请 -R 标" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { value: "2", label: "国内品牌申请 -TM 标" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { value: "3", label: "海外品牌申请 -R 标" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { value: "4", label: "海外品牌申请 -TM 标" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商标申请人姓名" } },
                [
                  _c("el-input", {
                    model: {
                      value:
                        _vm.submitBrandAudit.pramData.auditReq.brandInfo
                          .trademarkApplicant,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.submitBrandAudit.pramData.auditReq.brandInfo,
                          "trademarkApplicant",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression:
                        "submitBrandAudit.pramData.auditReq.brandInfo.trademarkApplicant",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "营业执照或组织机构代码证",
                    prop: "pramData.auditReq.license",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upLoadPicBox",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap("1")
                        },
                      },
                    },
                    [
                      _vm.submitBrandAudit.pramData.auditReq.license
                        ? _c("div", { staticClass: "pictrue" }, [
                            _c("img", {
                              attrs: {
                                src: _vm.submitBrandAudit.pramData.auditReq
                                  .license,
                              },
                            }),
                          ])
                        : _c("div", { staticClass: "upLoad" }, [
                            _c("i", {
                              staticClass: "el-icon-camera cameraIconfont",
                            }),
                          ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label:
                      "销售授权书（如商持人为自然人，还需提供有其签名的身份证正反面扫描件)",
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "upLoadPicBox",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap("2")
                        },
                      },
                    },
                    [
                      _vm.submitBrandAudit.pramData.auditReq.brandInfo
                        .saleAuthorizationArr.length > 0
                        ? _c(
                            "div",
                            { staticClass: "pictrue" },
                            _vm._l(
                              _vm.submitBrandAudit.pramData.auditReq.brandInfo
                                .saleAuthorizationArr,
                              function (imgSrc) {
                                return _c("img", {
                                  key: imgSrc,
                                  attrs: { src: imgSrc },
                                })
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.submitBrandAudit.pramData.auditReq.brandInfo
                        .saleAuthorizationArr.length <= 10
                        ? _c("div", { staticClass: "upLoad" }, [
                            _c("i", {
                              staticClass: "el-icon-camera cameraIconfont",
                            }),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "商标注册证书" } }, [
                _c(
                  "div",
                  {
                    staticClass: "upLoadPicBox",
                    on: {
                      click: function ($event) {
                        return _vm.modalPicTap("3")
                      },
                    },
                  },
                  [
                    _vm.submitBrandAudit.pramData.auditReq.brandInfo
                      .trademarkRegistrationCertificateArr.length > 0
                      ? _c(
                          "div",
                          { staticClass: "pictrue" },
                          _vm._l(
                            _vm.submitBrandAudit.pramData.auditReq.brandInfo
                              .trademarkRegistrationCertificateArr,
                            function (imgSrc) {
                              return _c("img", {
                                key: imgSrc,
                                attrs: { src: imgSrc },
                              })
                            }
                          ),
                          0
                        )
                      : _c("div", { staticClass: "upLoad" }, [
                          _c("i", {
                            staticClass: "el-icon-camera cameraIconfont",
                          }),
                        ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "商标变更证明" } }, [
                _c(
                  "div",
                  {
                    staticClass: "upLoadPicBox",
                    on: {
                      click: function ($event) {
                        return _vm.modalPicTap("4")
                      },
                    },
                  },
                  [
                    _vm.submitBrandAudit.pramData.auditReq.brandInfo
                      .trademarkChangeCertificateArr.length > 0
                      ? _c(
                          "div",
                          { staticClass: "pictrue" },
                          _vm._l(
                            _vm.submitBrandAudit.pramData.auditReq.brandInfo
                              .trademarkChangeCertificateArr,
                            function (imgSrc) {
                              return _c("img", {
                                key: imgSrc,
                                attrs: { src: imgSrc },
                              })
                            }
                          ),
                          0
                        )
                      : _c("div", { staticClass: "upLoad" }, [
                          _c("i", {
                            staticClass: "el-icon-camera cameraIconfont",
                          }),
                        ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商标注册申请受理通知书" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upLoadPicBox",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap("5")
                        },
                      },
                    },
                    [
                      _vm.submitBrandAudit.pramData.auditReq.brandInfo
                        .trademarkRegistrationApplicationArr.length > 0
                        ? _c(
                            "div",
                            { staticClass: "pictrue" },
                            _vm._l(
                              _vm.submitBrandAudit.pramData.auditReq.brandInfo
                                .trademarkRegistrationApplicationArr,
                              function (imgSrc) {
                                return _c("img", {
                                  key: imgSrc,
                                  attrs: { src: imgSrc },
                                })
                              }
                            ),
                            0
                          )
                        : _c("div", { staticClass: "upLoad" }, [
                            _c("i", {
                              staticClass: "el-icon-camera cameraIconfont",
                            }),
                          ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "中华人民共和国海关进口货物报关单" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upLoadPicBox",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap("6")
                        },
                      },
                    },
                    [
                      _vm.submitBrandAudit.pramData.auditReq.brandInfo
                        .importedGoodsFormArr.length > 0
                        ? _c(
                            "div",
                            { staticClass: "pictrue" },
                            _vm._l(
                              _vm.submitBrandAudit.pramData.auditReq.brandInfo
                                .importedGoodsFormArr,
                              function (imgSrc) {
                                return _c("img", {
                                  key: imgSrc,
                                  attrs: { src: imgSrc },
                                })
                              }
                            ),
                            0
                          )
                        : _c("div", { staticClass: "upLoad" }, [
                            _c("i", {
                              staticClass: "el-icon-camera cameraIconfont",
                            }),
                          ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { align: "right" } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["platform:pay:component:shop:brand:audit"],
                          expression:
                            "['platform:pay:component:shop:brand:audit']",
                        },
                      ],
                      attrs: { type: "primary" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.handleSubmitBrandAudit(
                            "brandSubmitFromRules"
                          )
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      nativeOn: {
                        click: function ($event) {
                          _vm.submitBrandAudit.visible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }