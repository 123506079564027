var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        {
          directives: [
            {
              name: "hasPermi",
              rawName: "v-hasPermi",
              value: ["platform:menu:list"],
              expression: "['platform:menu:list']",
            },
          ],
          staticClass: "box-card",
          attrs: {
            bordered: false,
            shadow: "never",
            "body-style": { padding: 0 },
          },
        },
        [
          _c(
            "div",
            { ref: "tableheader", staticClass: "padding-add" },
            [
              _c(
                "el-form",
                {
                  attrs: { model: _vm.queryParams, inline: true },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "菜单名称：" } },
                    [
                      _c("el-input", {
                        staticClass: "selWidth",
                        attrs: {
                          placeholder: "请输入菜单名称",
                          clearable: "",
                          size: "small",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleQuery($event)
                          },
                        },
                        model: {
                          value: _vm.name,
                          callback: function ($$v) {
                            _vm.name =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "菜单状态：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "selWidth",
                          attrs: {
                            placeholder: "请选择菜单状态",
                            clearable: "",
                            size: "small",
                          },
                          model: {
                            value: _vm.queryParams.menuType,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "menuType", $$v)
                            },
                            expression: "queryParams.menuType",
                          },
                        },
                        _vm._l(_vm.statusOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            icon: "el-icon-search",
                            size: "mini",
                          },
                          on: { click: _vm.handleQuery },
                        },
                        [_vm._v("搜索")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-refresh", size: "mini" },
                          on: { click: _vm.resetQuery },
                        },
                        [_vm._v("重置")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card mt14",
          attrs: {
            "body-style": { padding: "20px" },
            shadow: "never",
            bordered: false,
          },
        },
        [
          _c(
            "el-row",
            { staticClass: "mb20", attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 1.5 } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["platform:menu:add"],
                          expression: "['platform:menu:add']",
                        },
                      ],
                      attrs: {
                        type: "primary",
                        plain: "",
                        icon: "el-icon-plus",
                        size: "mini",
                      },
                      on: { click: _vm.handleAdd },
                    },
                    [_vm._v("新增")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 1.5 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "info",
                        plain: "",
                        icon: "el-icon-sort",
                        size: "mini",
                      },
                      on: { click: _vm.toggleExpandAll },
                    },
                    [_vm._v("展开/折叠")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.refreshTable
            ? _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  ref: "table",
                  attrs: {
                    data: _vm.menuList,
                    "row-key": "id",
                    size: "small",
                    height: _vm.tableHeight,
                    "default-expand-all": _vm.isExpandAll,
                    "tree-props": {
                      children: "children",
                      hasChildren: "hasChildren",
                    },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "name",
                      label: "菜单名称",
                      "show-overflow-tooltip": true,
                      "min-width": "160",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "icon", label: "图标", width: "100" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("i", {
                                class: "el-icon-" + scope.row.icon,
                                staticStyle: { "font-size": "20px" },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      244404100
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "sort", label: "排序", width: "60" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "perms",
                      label: "权限标识",
                      "show-overflow-tooltip": true,
                      "min-width": "160",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "component",
                      label: "组件路径",
                      "show-overflow-tooltip": true,
                      "min-width": "160",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "isShow", label: "状态", width: "80" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-tag",
                                {
                                  attrs: {
                                    type: scope.row.isShow ? "" : "danger",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(scope.row.isShow ? "显示" : "隐藏")
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2038216616
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "menuType", label: "类型", width: "80" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.menuType == "M"
                                ? _c("span", { staticClass: "type_tag one" }, [
                                    _vm._v("目录"),
                                  ])
                                : scope.row.menuType == "C"
                                ? _c("span", { staticClass: "type_tag two" }, [
                                    _vm._v("菜单"),
                                  ])
                                : _c(
                                    "span",
                                    {
                                      staticClass: "type_tag three",
                                      attrs: { type: "info" },
                                    },
                                    [_vm._v("按钮")]
                                  ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4965977
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "160", fixed: "right" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "hasPermi",
                                      rawName: "v-hasPermi",
                                      value: [
                                        "platform:menu:update",
                                        "platform:admin:role:info",
                                      ],
                                      expression:
                                        "['platform:menu:update', 'platform:admin:role:info']",
                                    },
                                  ],
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleUpdate(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("修改")]
                              ),
                              _vm._v(" "),
                              _c("el-divider", {
                                attrs: { direction: "vertical" },
                              }),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "hasPermi",
                                      rawName: "v-hasPermi",
                                      value: ["platform:menu:add"],
                                      expression: "['platform:menu:add']",
                                    },
                                  ],
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleAdd(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("新增")]
                              ),
                              _vm._v(" "),
                              _c("el-divider", {
                                attrs: { direction: "vertical" },
                              }),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "hasPermi",
                                      rawName: "v-hasPermi",
                                      value: ["platform:menu:delete"],
                                      expression: "['platform:menu:delete']",
                                    },
                                  ],
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDelete(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3438096106
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.title,
                visible: _vm.open,
                width: "680px",
                "append-to-body": "",
                "close-on-click-modal": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.open = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "90px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "上级菜单：" } },
                    [
                      _c("treeselect", {
                        attrs: {
                          options: _vm.menuOptions,
                          normalizer: _vm.normalizer,
                          "show-count": true,
                          placeholder: "选择上级菜单",
                        },
                        model: {
                          value: _vm.form.pid,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "pid", $$v)
                          },
                          expression: "form.pid",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "菜单类型：", prop: "menuType" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.form.menuType,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "menuType", $$v)
                            },
                            expression: "form.menuType",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "M" } }, [
                            _vm._v("目录"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: "C" } }, [
                            _vm._v("菜单"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: "A" } }, [
                            _vm._v("按钮"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.form.menuType != "A"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "菜单图标：" } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: { placeholder: "请选择菜单图标" },
                                  model: {
                                    value: _vm.form.icon,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "icon",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "form.icon",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      slot: "append",
                                      icon: "el-icon-circle-plus-outline",
                                    },
                                    on: { click: _vm.addIcon },
                                    slot: "append",
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "菜单名称：", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入菜单名称" },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "name",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "显示排序：", prop: "sort" } },
                    [
                      _c("el-input-number", {
                        attrs: { "controls-position": "right", min: 0 },
                        model: {
                          value: _vm.form.sort,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "sort",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.sort",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "component" } },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "label" }, slot: "label" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content:
                                  "访问的组件路径，如：`system/user/index`，默认在`views`目录下",
                                placement: "top",
                              },
                            },
                            [_c("i", { staticClass: "el-icon-question" })]
                          ),
                          _vm._v("\n            组件路径：\n          "),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-input", {
                        attrs: { placeholder: "请输入组件路径" },
                        model: {
                          value: _vm.form.component,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "component",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.component",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.form.menuType != "M"
                    ? _c(
                        "el-form-item",
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入权限标识",
                              maxlength: "100",
                            },
                            model: {
                              value: _vm.form.perms,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "perms",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.perms",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { attrs: { slot: "label" }, slot: "label" },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    content:
                                      "控制器中定义的权限字符，如：@PreAuthorize(`@ss.hasPermi('system:user:list')`)",
                                    placement: "top",
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-question" })]
                              ),
                              _vm._v("\n            权限字符：\n          "),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "span",
                        { attrs: { slot: "label" }, slot: "label" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content:
                                  "选择隐藏则路由将不会出现在侧边栏，但仍然可以访问",
                                placement: "top",
                              },
                            },
                            [_c("i", { staticClass: "el-icon-question" })]
                          ),
                          _vm._v("\n            显示状态：\n          "),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio-group",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: ["platform:menu:show:status"],
                              expression: "['platform:menu:show:status']",
                            },
                          ],
                          model: {
                            value: _vm.form.isShow,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "isShow", $$v)
                            },
                            expression: "form.isShow",
                          },
                        },
                        _vm._l(_vm.showStatus, function (item) {
                          return _c(
                            "el-radio",
                            { key: item.value, attrs: { label: item.value } },
                            [_vm._v(_vm._s(item.label))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("el-button", { on: { click: _vm.cancel } }, [
                    _vm._v("取 消"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["platform:menu:update"],
                          expression: "['platform:menu:update']",
                        },
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }