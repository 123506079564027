"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.orderApprove = orderApprove;
exports.orderList = orderList;
exports.orderNum = orderNum;
exports.orgOrderExcel = orgOrderExcel;
exports.organizationDelete = organizationDelete;
exports.organizationInfo = organizationInfo;
exports.organizationList = organizationList;
exports.organizationListLower = organizationListLower;
exports.organizationSave = organizationSave;
exports.organizationTree = organizationTree;
exports.organizationUpdate = organizationUpdate;
exports.ruleList = ruleList;
exports.ruleUpdate = ruleUpdate;
exports.statisticsDetailsList = statisticsDetailsList;
exports.statisticsList = statisticsList;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * 机构 列表
 * @param prams
 */
function organizationList(params) {
  return (0, _request.default)({
    url: '/admin/platform/organization/list',
    method: 'get',
    params: params
  });
}
/**
 * 机构 详情
 * @param prams
 */
function organizationInfo(params) {
  return (0, _request.default)({
    url: '/admin/platform/organization/info',
    method: 'get',
    params: params
  });
}
/**
 * 机构 新增
 * @param prams
 */
function organizationSave(data) {
  return (0, _request.default)({
    url: '/admin/platform/organization/save',
    method: 'post',
    data: data
  });
}
/**
 * 机构 编辑
 * @param prams
 */
function organizationUpdate(data) {
  return (0, _request.default)({
    url: '/admin/platform/organization/update',
    method: 'post',
    data: data
  });
}
/**
 * 机构 删除
 * @param prams
 */
function organizationDelete(data) {
  return (0, _request.default)({
    url: '/admin/platform/organization/delete',
    method: 'post',
    data: data
  });
}

/**
 * 规则列表
 * @param prams
 */
function ruleList() {
  return (0, _request.default)({
    url: '/admin/platform/order/approve/rule/list',
    method: 'get'
  });
}

/**
 * 规则修改
 * @param prams
 */
function ruleUpdate(data) {
  return (0, _request.default)({
    url: '/admin/platform/order/approve/rule/update',
    method: 'post',
    data: data
  });
}
/**
 * 机构树
 * @param prams
 */
function organizationTree(params) {
  return (0, _request.default)({
    url: '/admin/platform/organization/tree',
    method: 'get',
    params: params
  });
}

/**
 * 机构订单
 * @param prams
 */
function orderList(params) {
  return (0, _request.default)({
    url: '/admin/platform/order/org/list',
    method: 'get',
    params: params
  });
}
/**
 * 机构订单各状态数量
 * @param prams
 */
function orderNum(params) {
  return (0, _request.default)({
    url: '/admin/platform/order/org/status/num',
    method: 'get',
    params: params
  });
}

/**
 * 订单审核
 * @param prams
 */
function orderApprove(data) {
  return (0, _request.default)({
    url: '/admin/platform/order/approve',
    method: 'post',
    data: data
  });
}
/**
 * 订单汇总列表
 * @param prams
 */
function statisticsList(params) {
  return (0, _request.default)({
    url: '/admin/platform/order/statistics/list',
    method: 'get',
    params: params
  });
}

/**
 * 订单汇总详情
 * @param prams
 */
function statisticsDetailsList(params) {
  return (0, _request.default)({
    url: '/admin/platform/order/statistics/details/list',
    method: 'get',
    params: params
  });
}
function orgOrderExcel(params) {
  return (0, _request.default)({
    url: '/admin/platform/export/org/order/excel',
    method: 'get',
    params: params
  });
}

/**
 *
 */
function organizationListLower(params) {
  return (0, _request.default)({
    url: '/admin/platform/organization/page/list/lower',
    method: 'get',
    params: params
  });
}