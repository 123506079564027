var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$store.state.themeConfig.themeConfig.layout !== "columns" &&
    !_vm.$store.state.themeConfig.themeConfig.isCollapse
    ? _c(
        "div",
        { staticClass: "layout-logo", on: { click: _vm.onThemeConfigChange } },
        [
          _vm.platMerLoginInfo.leftSquareLogo
            ? _c("img", {
                staticClass: "layout-logo-medium-img",
                attrs: { src: _vm.platMerLoginInfo.leftSquareLogo },
              })
            : _vm._e(),
        ]
      )
    : _c(
        "div",
        {
          staticClass: "layout-logo-size",
          on: { click: _vm.onThemeConfigChange },
        },
        [
          _vm.platMerLoginInfo.leftTopLogo
            ? _c("img", {
                staticClass: "layout-logo-size-img",
                attrs: { src: _vm.platMerLoginInfo.leftTopLogo },
              })
            : _vm._e(),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }