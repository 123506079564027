var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "infoBox" },
    [
      _c(
        "el-drawer",
        {
          ref: "drawer",
          staticClass: "infoBox",
          attrs: {
            visible: _vm.dialogVisible,
            title: _vm.isAtud ? "产品审核" : "产品详情",
            direction: _vm.direction,
            "custom-class": "demo-drawer",
            size: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.onClose,
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
            },
            [
              _c("div", { staticClass: "detailHead" }, [
                _c("div", { staticClass: "acea-row row-between headerBox" }, [
                  _c("div", { staticClass: "full" }, [
                    _c("img", {
                      staticClass: "order_icon",
                      attrs: { src: _vm.formValidate.image, alt: "" },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.formValidate.name)),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", { staticClass: "mr20" }, [
                          _vm._v("产品ID：" + _vm._s(_vm.formValidate.id)),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.isAtud
                    ? _c(
                        "div",
                        { staticClass: "acea-row justify-content" },
                        [
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "debounceClick",
                                  rawName: "v-debounceClick",
                                  value: function () {
                                    _vm.onSubmit("fail")
                                  },
                                  expression:
                                    "\n                () => {\n                  onSubmit('fail');\n                }\n              ",
                                },
                              ],
                              staticStyle: { "margin-left": "0" },
                              attrs: { size: "small" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.loadingBtn ? "提交中 ..." : "审核拒绝"
                                )
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "debounceClick",
                                  rawName: "v-debounceClick",
                                  value: function () {
                                    _vm.onSubmit("success")
                                  },
                                  expression:
                                    "\n                () => {\n                  onSubmit('success');\n                }\n              ",
                                },
                              ],
                              attrs: { size: "small", type: "primary" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.loadingBtn ? "提交中 ..." : "审核通过"
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("ul", { staticClass: "list" }, [
                  _c("li", { staticClass: "item" }, [
                    _c("div", { staticClass: "title" }, [_vm._v("产品类型")]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          _vm._f("productTpyeFilter")(_vm.formValidate.type)
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "item" }, [
                    _c("div", { staticClass: "title" }, [_vm._v("产品状态")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "color-warning" }, [
                      _vm._v(_vm._s(_vm.formValidate.isShow ? "上架" : "下架")),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "item" }, [
                    _c("div", { staticClass: "title" }, [_vm._v("产品售价")]),
                    _vm._v(" "),
                    _c("div", [_vm._v(_vm._s(_vm.formValidate.price) + "元")]),
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "item" }, [
                    _c("div", { staticClass: "title" }, [_vm._v("销量")]),
                    _vm._v(" "),
                    _c("div", [_vm._v(_vm._s(_vm.formValidate.sales))]),
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "item" }, [
                    _c("div", { staticClass: "title" }, [_vm._v("库存")]),
                    _vm._v(" "),
                    _c("div", [_vm._v(_vm._s(_vm.formValidate.stock))]),
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "item" }, [
                    _c("div", { staticClass: "title" }, [_vm._v("创建时间")]),
                    _vm._v(" "),
                    _c("div"),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm.formValidate && _vm.isShow
                ? _c(
                    "el-tabs",
                    {
                      attrs: { type: "border-card" },
                      model: {
                        value: _vm.currentTab,
                        callback: function ($$v) {
                          _vm.currentTab = $$v
                        },
                        expression: "currentTab",
                      },
                    },
                    [
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "基本信息", name: "0" } },
                        [
                          _c("div", { staticClass: "detailSection divBox" }, [
                            _c("ul", { staticClass: "list mt-16" }, [
                              _c("li", { staticClass: "item" }, [
                                _c("div", { staticClass: "lang" }, [
                                  _vm._v("平台产品分类："),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "value" },
                                  [
                                    _c("el-cascader", {
                                      attrs: {
                                        options: _vm.adminProductClassify,
                                        props: _vm.props1,
                                        "show-all-levels": false,
                                        disabled: _vm.isDisabled,
                                      },
                                      model: {
                                        value: _vm.formValidate.categoryId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "categoryId",
                                            $$v
                                          )
                                        },
                                        expression: "formValidate.categoryId",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c("li", { staticClass: "item" }, [
                                _c("div", { staticClass: "tips" }, [
                                  _vm._v("品牌："),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "value" },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          filterable: "",
                                          remote: "",
                                          disabled: _vm.isDisabled,
                                        },
                                        model: {
                                          value: _vm.formValidate.brandId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formValidate,
                                              "brandId",
                                              $$v
                                            )
                                          },
                                          expression: "formValidate.brandId",
                                        },
                                      },
                                      _vm._l(_vm.brandList, function (user) {
                                        return _c("el-option", {
                                          key: user.id,
                                          attrs: {
                                            label: user.name,
                                            value: user.id,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c("li", { staticClass: "item" }, [
                                _c("div", { staticClass: "tips" }, [
                                  _vm._v("产品单位："),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "value" }, [
                                  _vm._v(_vm._s(_vm.formValidate.unitName)),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("li", { staticClass: "item" }, [
                                _c("div", { staticClass: "lang" }, [
                                  _vm._v("排序："),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "value" }, [
                                  _vm._v(_vm._s(_vm.formValidate.sort)),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("li", { staticClass: "item" }, [
                                _c("div", { staticClass: "lang" }, [
                                  _vm._v("ERP物料号："),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "value" }, [
                                  _vm._v(_vm._s(_vm.formValidate.materialCode)),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("li", { staticClass: "item" }, [
                                _c("div", { staticClass: "lang" }, [
                                  _vm._v("ERP版本号："),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "value" }, [
                                  _vm._v(_vm._s(_vm.formValidate.versionCode)),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "list",
                                staticStyle: { display: "block" },
                              },
                              [
                                _c("div", { staticClass: "item" }, [
                                  _c("div", { staticClass: "lang" }, [
                                    _vm._v("关键字："),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "value" }, [
                                    _vm._v(_vm._s(_vm.formValidate.keyword)),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "lang line-heightOne" },
                                    [_vm._v("保障服务：")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "value acea-row" },
                                    _vm._l(
                                      _vm.formValidate.guaranteeList,
                                      function (item) {
                                        return _c(
                                          "div",
                                          { key: item.id, staticClass: "mr20" },
                                          [
                                            _c("span", {
                                              staticClass:
                                                "iconfont icon-ic-complete1 mr5 font14",
                                            }),
                                            _vm._v(
                                              _vm._s(item.name) +
                                                "\n                  "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "item" }, [
                                  _c("div", { staticClass: "lang" }, [
                                    _vm._v("产品简介："),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "value" }, [
                                    _vm._v(_vm._s(_vm.formValidate.intro)),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "acea-row row-middle" },
                                    [
                                      _c("div", { staticClass: "lang" }, [
                                        _vm._v("封面图："),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "upLoadPicBox" },
                                        [
                                          _c("el-image", {
                                            staticClass: "pictrue",
                                            attrs: {
                                              src: _vm.formValidate.image,
                                              "preview-src-list": [
                                                _vm.formValidate.image,
                                              ],
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.videoLink,
                                        expression: "videoLink",
                                      },
                                    ],
                                    staticClass: "item",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "acea-row row-middle" },
                                      [
                                        _c("div", { staticClass: "lang" }, [
                                          _vm._v("主图视频："),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "upLoadPicBox" },
                                          [
                                            _c(
                                              "video",
                                              {
                                                staticClass: "pictrue",
                                                attrs: {
                                                  src: _vm.videoLink,
                                                  controls: "controls",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "您的浏览器不支持 video 标签。"
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "acea-row row-middle" },
                                    [
                                      _c("div", { staticClass: "lang" }, [
                                        _vm._v("轮播图："),
                                      ]),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.formValidate.sliderImages,
                                        function (item, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass: "pictrue",
                                            },
                                            [
                                              _c("el-image", {
                                                staticClass: "pictrue",
                                                attrs: {
                                                  src: item,
                                                  "preview-src-list":
                                                    _vm.formValidate
                                                      .sliderImages,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "acea-row row-middle" },
                                    [
                                      _c("div", { staticClass: "lang" }, [
                                        _vm._v("购买回馈券："),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "acea-row",
                                          staticStyle: {
                                            "margin-top": "-10px",
                                          },
                                        },
                                        [
                                          _vm._l(
                                            _vm.formValidate.couponList,
                                            function (tag, index) {
                                              return _c(
                                                "el-tag",
                                                {
                                                  key: index,
                                                  staticClass: "mr10 mt10",
                                                  attrs: {
                                                    closable: !_vm.isDisabled,
                                                    "disable-transitions": false,
                                                    effect: "plain",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(tag.name) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          _vm._v(" "),
                                          _vm.formValidate.couponList.length ===
                                          0
                                            ? _c(
                                                "span",
                                                { staticClass: "mt10" },
                                                [_vm._v("无")]
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "规格库存", name: "1" } },
                        [
                          _c("div", { staticClass: "detailSection divBox" }, [
                            _c("ul", { staticClass: "list mt-16" }, [
                              _c("li", { staticClass: "item" }, [
                                _c("div", { staticClass: "tips tipsWidth" }, [
                                  _vm._v("产品规格："),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "value" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formValidate.specType
                                        ? "多规格"
                                        : "单规格"
                                    )
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("li", { staticClass: "item" }, [
                                _c("div", { staticClass: "tips tipsWidth" }, [
                                  _vm._v("佣金设置："),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "value" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formValidate.isSub
                                        ? "单独设置"
                                        : "默认设置"
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticStyle: { "margin-top": "16px" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "tips tipsWidth mb10" },
                                  [_vm._v("产品属性：")]
                                ),
                                _vm._v(" "),
                                _vm.formValidate.specType === false
                                  ? [
                                      _c(
                                        "el-table",
                                        {
                                          staticClass: "tabNumWidth",
                                          attrs: {
                                            data: _vm.OneattrValue,
                                            size: "small",
                                          },
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "图片",
                                              width: "60",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "upLoadPicBox",
                                                        },
                                                        [
                                                          _vm.formValidate.image
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "pictrue tabPic",
                                                                },
                                                                [
                                                                  _c("img", {
                                                                    attrs: {
                                                                      src: scope
                                                                        .row
                                                                        .image,
                                                                    },
                                                                  }),
                                                                ]
                                                              )
                                                            : _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "upLoad tabPic",
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "el-icon-camera cameraIconfont",
                                                                  }),
                                                                ]
                                                              ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              1903352531
                                            ),
                                          }),
                                          _vm._v(" "),
                                          _vm.formValidate.isSub
                                            ? _vm._l(
                                                _vm.attrValue,
                                                function (item, iii) {
                                                  return _c("el-table-column", {
                                                    key: iii,
                                                    attrs: {
                                                      label:
                                                        _vm.formThead[iii]
                                                          .title,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function (scope) {
                                                            return [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    scope.row[
                                                                      iii
                                                                    ]
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  })
                                                }
                                              )
                                            : _vm._l(
                                                _vm.attrValue,
                                                function (item, iii) {
                                                  return _c("el-table-column", {
                                                    key: iii,
                                                    attrs: {
                                                      label:
                                                        _vm.formThead[iii]
                                                          .title,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function (scope) {
                                                            return [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    scope.row[
                                                                      iii
                                                                    ]
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  })
                                                }
                                              ),
                                        ],
                                        2
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.formValidate.attr.length > 0 &&
                                _vm.formValidate.specType
                                  ? [
                                      _c(
                                        "el-table",
                                        {
                                          staticClass: "tabNumWidth",
                                          attrs: {
                                            data: _vm.ManyAttrValue,
                                            size: "small",
                                          },
                                        },
                                        [
                                          _vm.manyTabDate
                                            ? _vm._l(
                                                _vm.manyTabDate,
                                                function (item, iii) {
                                                  return _c("el-table-column", {
                                                    key: iii,
                                                    attrs: {
                                                      label:
                                                        _vm.manyTabTit[iii]
                                                          .title,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function (scope) {
                                                            return [
                                                              _c("span", {
                                                                staticClass:
                                                                  "priceBox",
                                                                domProps: {
                                                                  textContent:
                                                                    _vm._s(
                                                                      scope.row[
                                                                        iii
                                                                      ]
                                                                    ),
                                                                },
                                                              }),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  })
                                                }
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "图片",
                                              width: "60",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "upLoadPicBox",
                                                        },
                                                        [
                                                          scope.row.image
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "pictrue tabPic",
                                                                },
                                                                [
                                                                  _c("img", {
                                                                    attrs: {
                                                                      src: scope
                                                                        .row
                                                                        .image,
                                                                    },
                                                                  }),
                                                                ]
                                                              )
                                                            : _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "upLoad tabPic",
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "el-icon-camera cameraIconfont",
                                                                  }),
                                                                ]
                                                              ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2631442157
                                            ),
                                          }),
                                          _vm._v(" "),
                                          _vm._l(
                                            _vm.attrValue,
                                            function (item, iii) {
                                              return _c("el-table-column", {
                                                key: iii,
                                                attrs: {
                                                  label:
                                                    _vm.formThead[iii].title,
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                scope.row[iii]
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              })
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "产品详情", name: "2" } },
                        [
                          _c("div", { staticClass: "detailSection divBox" }, [
                            _c("div", {
                              staticClass: "contentPic",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.formValidate.content || "无"
                                ),
                              },
                            }),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }