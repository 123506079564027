"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/components/FromList/index.vue"));
var _permission = require("@/utils/permission");
var _systemPcConfig = require("@/api/systemPcConfig");
var _defaultPcConfig = require("@/views/systemSetting/pcConfig/defaultPcConfig");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
// 权限判断函数

var fromData = {
  address: '',
  authInfo: '',
  filingNum: '',
  phone: ''
};
var _default = exports.default = {
  name: 'empower',
  components: {
    FromList: _index.default
  },
  data: function data() {
    var validatePhone = function validatePhone(rule, value, callback) {
      if (!value) {
        return callback(new Error('请填写联系电话'));
      } else {
        callback();
      }
    };
    return {
      currentTab: '1',
      tabList: [{
        value: '1',
        title: '经营理念'
      }, {
        value: '2',
        title: '授权备案'
      }, {
        value: '3',
        title: '模块链接'
      }, {
        value: '4',
        title: '二维码配置'
      }, {
        value: '5',
        title: '友情链接'
      }],
      //经营理念
      philosophyConfig: Object.assign({}, (0, _defaultPcConfig.philosophyDefault)()),
      fullscreenLoading: false,
      ruleValidate: {
        authInfo: [{
          required: true,
          message: '请输入授权信息',
          trigger: 'blur'
        }],
        filingNum: [{
          required: true,
          message: '请输入备案号',
          trigger: 'blur'
        }]
      },
      formValidate: Object.assign({}, fromData),
      // 友情链接
      friendlyLinkConfig: Object.assign({}, (0, _defaultPcConfig.friendlyLinkDefault)()),
      loadingBtn: false,
      // 二维码
      bottomQrcodeConfig: Object.assign({}, (0, _defaultPcConfig.bottomQrcodeDefault)()),
      //PC商城快捷入口配置
      shoppingQuickEntryConfig: Object.assign({}, (0, _defaultPcConfig.shoppingQuickEntryDefault)())
    };
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['platform:pc:shopping:philosophy:get'])) this.getPhilosophy();
    if ((0, _permission.checkPermi)(['platform:pc:shopping:bottom:authorize:get'])) this.getPcAuthorize();
    if ((0, _permission.checkPermi)(['platform:pc:shopping:friendly:link:get'])) this.getFriendly();
    if ((0, _permission.checkPermi)(['platform:pc:shopping:bottom:qrcode:get'])) this.getBottomQrcode();
    if ((0, _permission.checkPermi)(['platform:pc:shopping:quick:entry:get'])) this.getShoppingQuickEntry();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    //经验理念新增
    handlePhilosophySave: function handlePhilosophySave() {
      var _this = this;
      var imageUrl = '';
      var name = '';
      this.philosophyConfig.list.map(function (item, index) {
        imageUrl = item.imageUrl;
        name = item.name;
        item.sort = index + 1;
      });
      if (!imageUrl) return this.$message.warning('图片地址不能为空');
      if (!name) return this.$message.warning('理念描述不能为空');
      this.loadingBtn = true;
      (0, _systemPcConfig.pcPhilosophySaveApi)({
        philosophyVoList: this.philosophyConfig.list
      }).then(function (res) {
        _this.$message.success('保存成功');
        _this.loadingBtn = false;
        _this.getPhilosophy();
      }).catch(function () {
        _this.loadingBtn = false;
      });
    },
    //经验理念
    getPhilosophy: function getPhilosophy() {
      var _this2 = this;
      (0, _systemPcConfig.pcPhilosophyGetApi)().then(function (res) {
        var philosophyVoList = res.philosophyVoList;
        if (philosophyVoList.length) _this2.philosophyConfig.list = res.philosophyVoList;
      });
    },
    //底部授权数据
    getPcAuthorize: function getPcAuthorize() {
      var _this3 = this;
      (0, _systemPcConfig.pcBottomAuthorizGetApi)().then(function (res) {
        Object.assign(_this3.formValidate, res);
      });
    },
    //底部授权保存
    handleAuthorizeSave: function handleAuthorizeSave(formName) {
      var _this4 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this4.loadingBtn = true;
          (0, _systemPcConfig.pcBottomAuthorizeSaveApi)(_this4.formValidate).then(function (res) {
            _this4.$message.success('保存成功');
            _this4.loadingBtn = false;
            _this4.getPcAuthorize();
          }).catch(function () {
            _this4.loadingBtn = false;
          });
        } else {
          return false;
        }
      });
    },
    //友情链接保存
    handleFriendlySave: function handleFriendlySave() {
      var _this5 = this;
      this.loadingBtn = true;
      this.friendlyLinkConfig.list.map(function (item, index) {
        item.sort = index + 1;
      });
      (0, _systemPcConfig.pcFriendlyLinkSaveApi)(this.friendlyLinkConfig.list).then(function (res) {
        _this5.$message.success('保存成功');
        _this5.loadingBtn = false;
        _this5.getFriendly();
      }).catch(function () {
        _this5.loadingBtn = false;
      });
    },
    //获取友情链接
    getFriendly: function getFriendly() {
      var _this6 = this;
      (0, _systemPcConfig.pcFriendlyLinkGetApi)().then(function (res) {
        _this6.friendlyLinkConfig.list = res;
      });
    },
    //底部二维码
    handleBottomQrcodeSave: function handleBottomQrcodeSave() {
      var _this7 = this;
      this.loadingBtn = true;
      this.bottomQrcodeConfig.list.map(function (item, index) {
        item.sort = index + 1;
      });
      (0, _systemPcConfig.bottomQrcodeSaveApi)(this.bottomQrcodeConfig.list).then(function (res) {
        _this7.$message.success('保存成功');
        _this7.loadingBtn = false;
        _this7.getBottomQrcode();
      }).catch(function () {
        _this7.loadingBtn = false;
      });
    },
    //获取底部二维码
    getBottomQrcode: function getBottomQrcode() {
      var _this8 = this;
      (0, _systemPcConfig.bottomQrcodeGetApi)().then(function (res) {
        if (res.length > 0) _this8.bottomQrcodeConfig.list = res;
      });
    },
    //保存模块链接
    handleShoppingQuickEntrySave: function handleShoppingQuickEntrySave() {
      var _this9 = this;
      this.loadingBtn = true;
      this.shoppingQuickEntryConfig.list.map(function (item, index) {
        item.sort = index + 1;
      });
      (0, _systemPcConfig.shoppingQuickEntrySaveApi)(this.shoppingQuickEntryConfig.list).then(function (res) {
        _this9.$message.success('保存成功');
        _this9.loadingBtn = false;
        _this9.getShoppingQuickEntry();
      }).catch(function () {
        _this9.loadingBtn = false;
      });
    },
    //获取模块链接
    getShoppingQuickEntry: function getShoppingQuickEntry() {
      var _this10 = this;
      (0, _systemPcConfig.shoppingQuickEntryGetApi)().then(function (res) {
        _this10.shoppingQuickEntryConfig.list = res && res.length ? res : [];
      });
    }
  }
};