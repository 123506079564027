var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("pages-header", {
        ref: "pageHeader",
        attrs: {
          title: _vm.$route.params.id ? "编辑文章" : "添加文章",
          backUrl: "/marketing/content/articleManager",
        },
      }),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card mt14",
          attrs: {
            shadow: "never",
            bordered: false,
            "body-style": { padding: "40px 50px" },
          },
        },
        [
          _c(
            "div",
            { staticClass: "components-container" },
            [
              _c(
                "el-form",
                {
                  ref: "pram",
                  attrs: {
                    "label-width": "81px",
                    model: _vm.pram,
                    size: "small",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "标题：",
                        prop: "title",
                        rules: [
                          {
                            required: true,
                            message: "请填写标题",
                            trigger: ["blur", "change"],
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        staticClass: "from-ipt-width",
                        attrs: { placeholder: "标题", maxlength: "100" },
                        model: {
                          value: _vm.pram.title,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.pram,
                              "title",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "pram.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "作者：",
                        prop: "author",
                        rules: [
                          {
                            required: true,
                            message: "请填作者",
                            trigger: ["blur", "change"],
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        staticClass: "from-ipt-width",
                        attrs: { placeholder: "作者", maxlength: "20" },
                        model: {
                          value: _vm.pram.author,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.pram,
                              "author",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "pram.author",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "文章分类：",
                        rules: [
                          {
                            required: true,
                            message: "请选择分类",
                            trigger: ["blur", "change"],
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "from-ipt-width",
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.pram.cid,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.pram,
                                "cid",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "pram.cid",
                          },
                        },
                        _vm._l(_vm.categoryTreeData, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "图文封面：",
                        prop: "cover",
                        rules: [
                          {
                            required: true,
                            message: "请上传图文封面",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "upLoadPicBox",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap("1")
                            },
                          },
                        },
                        [
                          _vm.pram.cover
                            ? _c("div", { staticClass: "pictrue" }, [
                                _c("img", { attrs: { src: _vm.pram.cover } }),
                              ])
                            : _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "文章简介：",
                        prop: "synopsis",
                        rules: [
                          {
                            required: true,
                            message: "请填写文章简介",
                            trigger: ["blur", "change"],
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        staticClass: "from-ipt-width",
                        attrs: {
                          maxlength: "100",
                          type: "textarea",
                          rows: 2,
                          resize: "none",
                          placeholder: "文章简介",
                        },
                        model: {
                          value: _vm.pram.synopsis,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.pram,
                              "synopsis",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "pram.synopsis",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "文章内容：",
                        prop: "content",
                        rules: [
                          {
                            required: true,
                            message: "请填写文章内容",
                            trigger: ["blur", "change"],
                          },
                        ],
                      },
                    },
                    [
                      _c("Tinymce", {
                        model: {
                          value: _vm.pram.content,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.pram,
                              "content",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "pram.content",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "排序：" } },
                    [
                      _c("el-input-number", {
                        attrs: { min: 0, max: 10, label: "排序" },
                        model: {
                          value: _vm.pram.sort,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.pram,
                              "sort",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "pram.sort",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否Banner：" } },
                    [
                      _c("el-switch", {
                        attrs: { "active-text": "是", "inactive-text": "否" },
                        model: {
                          value: _vm.pram.isBanner,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.pram,
                              "isBanner",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "pram.isBanner",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否热门：" } },
                    [
                      _c("el-switch", {
                        attrs: { "active-text": "是", "inactive-text": "否" },
                        model: {
                          value: _vm.pram.isHot,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.pram,
                              "isHot",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "pram.isHot",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: [
                                "platform:article:update",
                                "platform:article:save",
                              ],
                              expression:
                                "['platform:article:update', 'platform:article:save']",
                            },
                          ],
                          attrs: { type: "primary", loading: _vm.loading },
                          on: {
                            click: function ($event) {
                              return _vm.handerSubmit("pram")
                            },
                          },
                        },
                        [_vm._v("保存")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }