"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _permission = require("@/utils/permission");
var _org = require("@/api/org");
var _down = require("@/utils/down");
var _excluded = ["orgIds"],
  _excluded2 = ["orgIds"]; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } } return target; }
var _default = exports.default = {
  name: 'statistic',
  props: {
    queryParams: {
      type: Object
    },
    uid: {
      type: Number
    }
  },
  data: function data() {
    return {
      showDetails: false,
      // 显示详情
      selected: null,
      orderNo: '',
      tableData: {
        data: [],
        total: 0
      },
      pager: {
        page: 1,
        limit: 20
      },
      listLoading: true
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    back: function back() {
      this.reset();
      this.$emit('back');
    },
    reset: function reset() {
      this.pager = {
        page: 1,
        limit: 20
      };
      this.tableData = {
        data: [],
        total: 0
      };
    },
    checkPermi: _permission.checkPermi,
    handleSearchList: function handleSearchList() {
      this.pager.page = 1;
      this.getList();
    },
    // 列表
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      var _this$queryParams = this.queryParams,
        orgIds = _this$queryParams.orgIds,
        rest = _objectWithoutProperties(_this$queryParams, _excluded);
      (0, _org.statisticsDetailsList)(_objectSpread(_objectSpread(_objectSpread({}, rest), this.pager), {}, {
        uid: this.uid,
        orgId: orgIds ? orgIds[orgIds.length - 1] : null
      })).then(function (res) {
        _this.tableData.data = res.list || [];
        _this.tableData.total = res.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.pager.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.pager.limit = val;
      this.getList();
    },
    exports: function exports() {
      var _this$queryParams2 = this.queryParams,
        orgIds = _this$queryParams2.orgIds,
        rest = _objectWithoutProperties(_this$queryParams2, _excluded2);
      (0, _down.AsyncDownFile)('orderStatisticsDetail', _objectSpread(_objectSpread({}, rest), {}, {
        uid: this.uid,
        orgId: orgIds ? orgIds[orgIds.length - 1] : null
      }));
    }
  }
};