var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    {
      staticClass: "box-card mt14",
      attrs: {
        "body-style": { padding: "0 20px 20px" },
        shadow: "never",
        bordered: false,
      },
    },
    [
      _c(
        "div",
        { staticClass: "mt20" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.back },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "mt20",
          attrs: {
            data: _vm.tableData.data,
            size: "small",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { type: "expand" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _c(
                      "el-table",
                      { attrs: { data: props.row.logistics } },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "packageNo",
                            "show-overflow-tooltip": "",
                            label: "箱号码",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "startNo",
                            "show-overflow-tooltip": "",
                            label: "产品起号",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "endNo",
                            "show-overflow-tooltip": "",
                            label: "产品止号",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "序号",
              width: "60px",
              align: "center",
              type: "index",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "createTime",
              "show-overflow-tooltip": "",
              width: "180px",
              align: "center",
              label: "订购时间",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "payNum",
              "show-overflow-tooltip": "",
              label: "数量",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "productName",
              "show-overflow-tooltip": "",
              label: "订购产品",
            },
          }),
          _vm._v(".\n    "),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.pager.limit,
              "current-page": _vm.pager.page,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }