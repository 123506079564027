import { render, staticRenderFns } from "./c_wechat_live.vue?vue&type=template&id=8f9b0c7a&scoped=true"
import script from "./c_wechat_live.vue?vue&type=script&lang=js"
export * from "./c_wechat_live.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f9b0c7a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\code\\商城\\dggf-order-web\\mer_plat_admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8f9b0c7a')) {
      api.createRecord('8f9b0c7a', component.options)
    } else {
      api.reload('8f9b0c7a', component.options)
    }
    module.hot.accept("./c_wechat_live.vue?vue&type=template&id=8f9b0c7a&scoped=true", function () {
      api.rerender('8f9b0c7a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pagediy/components/mobileConfig/c_wechat_live.vue"
export default component.exports